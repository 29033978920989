// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import ChamQrcode from './index.vue'

registerRendererByType(ChamQrcode, {
  type: 'cham-qrcode',
  usage: 'formitem',
  weight: 99,
  framework: 'vue',
  detectProps: ['cham-qrcode_info']
})
