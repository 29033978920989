<template>
  <div class="main user-layout-register">
    模式页面
  </div>
</template>

<script>
import { getDev, controlPublic, devDetail } from '@/api/device'
export default {
  name: 'Register',
  // components: { videoPlayer },
  data () {
    return {
      devId: '',
      info: {},
      immGetPub01: {},
      immGetPub02: {},
      devInfo: {},
    }
  },
  computed: {},
  created () {
    let index = window.location.href.indexOf('id=')
    // console.log(window.location.href.substring(index + 3), '大大大大大')
    this.devId = window.location.href.substring(index + 3)
    // 查询详情
    this.getDetail()
    this.getDevDetail()
  },
  beforeDestroy () {
    client.end()
  },
  methods: {
    getDetail () {
      getDev({ devId: this.devId }).then(res => {
        console.log(res, '滴滴滴滴滴滴1111');
        res = res.data
        this.info = res
      })
    },
    getDevDetail () {
      devDetail({ id: this.devId }).then(res => {
        res = res.data
        this.devInfo = res
        // this.subscribe = res.devCode + '/immGetSub01'
        this.subscribe = [res.devCode + '/web/pub/01', res.devCode + '/web/pub/02']
        console.log(this.subscribe, 'this.subscribethis.subscribethis.subscribe');
        this.connectMqtt()
      })
    },
    /**
 * @desc mqtt连接
 * @author 刘
 * @time 2023年06月02日 18:31:19 星期五
 */
    connectMqtt () {
      const that = this
      var mqtt = require('mqtt/dist/mqtt.js')
      // client = mqtt.connect('ws://emqx.zzcyi.cn:8083/bao/bao-dev')
      // const mqttUrl = 'ws://139.196.79.66:7084/bao/bao-dev'
      const mqttUrl = 'ws://139.196.79.66:7084/mqtt'
      // client = mqtt.connect('ws://47.104.102.88:8083/bao/bao-dev')
      client = mqtt.connect(mqttUrl, {
        protocolVersion: 4,
        reconnectPeriod: 100,
        connectTimeout: 30 * 1000,
        resubscribe: true,
        keepalive: 3,
        clientId:
          'mqttjs_' +
          Math.random()
            .toString(16)
            .substr(2, 8)
      })
      client.on('connect', function () {
        console.log('on connect', new Date().getTime())
        console.log('主题>>>>', that.subscribe)
        client.subscribe(that.subscribe, function (err, granted) {
          console.log('订阅主题>>>>>err>>>>', err, that.subscribe)
          console.log('订阅主题>>>>>granted>>>>', granted)
          if (!err) {
            console.log('订阅成功>>>>')
          }
        })
      }).on('reconnect', function () {
        // uni.showLoading({
        // 	title:'加载中...',
        // 	mask:true
        // })
        // setTimeout(function() {
        // 	uni.hideLoading()
        // }, 3000);
        console.log('重新连接', new Date().getTime())
      }).on('close', function (err) {
        // that.setIsMqttConnect(false)
        console.log('链接断开>>>>', err)
      }).on('error', function (err) {
        console.log('on error', err)
      }).on('end', function () {
        // that.setIsMqttConnect(false)
        console.log('on end')
      }).on('message', function (topic, message) {
        console.log('mqtt消息', message.toString())
        // alert(message.toString())
        // that.getDetail()
        let num = JSON.parse(message.toString());
        console.log(num, 'numnumnumnum====');
        if (num.topic.indexOf('/web/pub/01') !== -1) {
          that.immGetPub01 = num
          that.$forceUpdate()
          console.log(that.immGetPub01, 'that.immGetPub01that.immGetPub01');
        } else if (num.topic.indexOf('/web/pub/02') !== -1) {
          that.immGetPub02 = num
          that.$forceUpdate()
          console.log(that.immGetPub02, 'that.immGetimmGetPub02immGetPub02immGetPub02Pub01that.immGetPub01');
        }
        // try {
        //   const messageObj = JSON.parse(message)
        //   console.log('接受到的消息>>>', messageObj)
        //   // logType为"sendToDev"--向设备发送信息
        //   // logType为"devReply"--设备回复
        //   // logType为"devLogin"--设备上线
        //   that.setData(messageObj)
        // } catch (e) {
        //   // TODO handle the exception
        // }
      })
    },
    // 点击控制
    handleControl (e, url) {
      console.log(e, '滴滴滴滴滴滴');

      let that = this
      console.log(that.devId, ' that.devId that.devId');
      this.$confirm({
        title: '提示',
        content: e,
        onOk () {
          controlPublic(url, { devId: that.devId }).then(res => {

          })
        },
        onCancel () { },
      });
    },
    // 点击长按事件
    handleDown (e, url) {
      // 设置一个定时器，如果用户持续按住，则执行长按操作
      if (!this.open) return this.$message.error('请检查现场情况在打开锁')
      if (this.longPressTimer) {
        clearTimeout(this.longPressTimer);
        this.longPressTimer = null
      }
      this.longPressTimer = setTimeout(() => {
        // 执行长按后的操作
        // console.log('Long press triggered');
        this[e] = true
        if (e == 's3' || e == 's4' || e == 's5' || e == 's6') {
          // s3 向前 后 正 反
          controlPublics(url, { id: this.devId, open: true }).then(res => {

          })
        } else if (e == 's1') {
          // s1 升 
          controlPublic(url, { devId: this.devId, YCMSS: true }).then(res => {

          })
        } else {
          // s2 降
          controlPublic(url, { devId: this.devId, YCMXJ: true }).then(res => {

          })
        }
        // if (e == 1) {
        //   console.log(this.s1, '对对对的多多多急急急吗');
        // }
        // clearTimeout(this.longPressTimer);

      }, 800); // 长按1秒
    },
    // 结束长按
    handleUp (e, url) {
      console.log('handleUp>>>>');
      if (!this.open) return
      // 清除定时器
      clearTimeout(this.longPressTimer);
      // if (e == 1) {
      //   this.s1 = false
      // }
      if (!this[e]) return
      this[e] = false
      if (e == 's3' || e == 's4' || e == 's5' || e == 's6') {
        // s3 向前 后 正 反
        controlPublics(url, { id: this.devId, open: false }).then(res => {
        })
      } else if (e == 's1') {
        // s1 升 
        controlPublic(url, { devId: this.devId, YCMSS: false }).then(res => {

        })
      } else {
        // s2 降
        controlPublic(url, { devId: this.devId, YCMXJ: false }).then(res => {

        })
      }
      this.longPressActive = false
    },
    getDetail () {
      getDev({ devId: this.devId }).then(res => {
        console.log(res, '滴滴滴滴滴滴1111');
        res = res.data
        this.one = res.currentStep.indexOf(1)
        this.two = res.currentStep.indexOf(2)
        this.three = res.currentStep.indexOf(3)
        this.four = res.currentStep.indexOf(4)
        this.five = res.currentStep.indexOf(5)
        this.six = res.currentStep.indexOf(6)
        this.sevent = res.currentStep.indexOf(7)
        console.log(this.one, 'dddd嘟嘟嘟嘟')
        // this.currentStep = .split(',')
        this.info = res
        this.immGetPub01 = res.immGetPub01
        this.immGetPub02 = res.immGetPub02
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
