/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-iframe';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamiframe';
  order = 0; // 排序
  description = '自定义iframe';
  tags = ['A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = {
  'type': 'container',
  'body': [
    {
      'type': 'iframe',
      'src': 'http://192.168.1.239:8080/#/pages/xtsz/yonghuzhucexieyi/yonghuzhucexieyi',
      'id': 'u:7ebb647edd72',
      'width': '268px',
      'height': '95%',
      'wrapperCustomStyle': {},
      'themeCss': {
        'baseControlClassName': {
          'padding-and-margin:default': {
            'marginTop': '5%'
          },
          'radius:default': {
            'top-left-border-radius': '26px',
            'top-right-border-radius': '26px',
            'bottom-left-border-radius': '26px',
            'bottom-right-border-radius': '26px'
          }
        }
      }
    }
  ],
  'size': 'xs',
  'style': {
    'position': 'static',
    'display': 'block',
    'flex': '0 0 150px',
    'flexBasis': '310px',
    'overflowX': 'hidden',
    'overflowY': 'hidden',
    'height': '620px'
  },
  'wrapperBody': false,
  'isFixedHeight': true,
  'isFixedWidth': false,
  'id': 'u:2d6a3ebddd4e',
  'wrapperCustomStyle': {
    'root': {
      'background': "url('http://47.92.37.224:7076/pic/mobile-bg.png') left top/310px auto no-repeat",
      'text-align': 'center'
    }
  }
};
  previewSchema = {
  'type': 'container',
  'body': [
    {
      'type': 'iframe',
      'src': 'http://192.168.1.239:8080/#/pages/xtsz/yonghuzhucexieyi/yonghuzhucexieyi',
      'id': 'u:7ebb647edd72',
      'width': '268px',
      'height': '95%',
      'wrapperCustomStyle': {},
      'themeCss': {
        'baseControlClassName': {
          'padding-and-margin:default': {
            'marginTop': '5%'
          },
          'radius:default': {
            'top-left-border-radius': '26px',
            'top-right-border-radius': '26px',
            'bottom-left-border-radius': '26px',
            'bottom-right-border-radius': '26px'
          }
        }
      }
    }
  ],
  'size': 'xs',
  'style': {
    'position': 'static',
    'display': 'block',
    'flex': '0 0 150px',
    'flexBasis': '310px',
    'overflowX': 'hidden',
    'overflowY': 'hidden',
    'height': '620px'
  },
  'wrapperBody': false,
  'isFixedHeight': true,
  'isFixedWidth': false,
  'id': 'u:2d6a3ebddd4e',
  'wrapperCustomStyle': {
    'root': {
      'background': "url('http://47.92.37.224:7076/pic/mobile-bg.png') left top/310px auto no-repeat",
      'text-align': 'center'
    }
  }
};

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
