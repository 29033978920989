<template>
  <div class="topBtn">
    <div v-if="defaultSettings.isShowScreen" class="topBtnItem rightBorder" style="padding-left: 16px;" @click="toNext('/ScreenList')">
      <img style="height: 23px;" src="@/assets/topBtn/6.png" alt="" srcset="">
      <span class="font20B">数据大屏</span>
    </div>
    <div v-if="defaultSettings.isShowIotCard" class="topBtnItem rightBorder" @click="toNext('not')">
      <a-icon :component="allIcon['wulianwangkaIcon']" :style="{ fontSize: '26px', marginRight: '10px' }"/>

      <!-- <img style="height: 26px;" src="@/assets/topBtn/7.png" alt="" srcset=""> -->
      <span class="font20B">物联网卡</span>
    </div>
    <div v-if="defaultSettings.isShowStore" class="topBtnItem" @click="toNext('not')">
      <img style="height: 24px;" src="@/assets/topBtn/8.png" alt="" srcset="">
      <span class="font20B">商城</span>
    </div>
  </div>
</template>
<script>
import defaultSettings from '@/config/defaultSettings'

export default {
  props: {
  },
  data() {
    return {
      defaultSettings
    }
  },
  methods: {
    toNext(path) {
      if(path == 'not') {
        this.$message.error('目前暂未开放')
      }else{
        this.$router.push({ path: path }).catch(() => { })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.topBtn {
  display: flex;
  height: 100%;
  width: auto;

  .topBtnItem {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    // border-right: 2px solid #2878FF;;
    padding:0 32px;

    &.rightBorder::after {
      content: "";
      position: absolute;
      right: 0;
      width: 0px;
      height: 21px;
      opacity: 1;
      border: 2px solid #2878FF;
      border-radius: 10px;

    }

    img {
      // width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      color: #2878FF;
    }

  }

}
</style>
