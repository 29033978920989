/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'info-grid';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '展示格';
  order = 0; // 排序
  description = '详情页面展示信息';
  tags = ['布局容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'info-grid',
    label: 'info-grid',
    'source_data': 'deviceData',
    'grid_name': 'deviceName',
    'grid_child_data': 'data',
    'grid_child_name': 'attrName',
    'grid_child_value': 'value',
    'grid_child_unit': 'attrUnit',
    'grid_child_icon': 'icon',
    'grid_num': 5,
    'grid_margin_bottom': 5,
    'grid_padding_top': 10,
    'grid_padding_left': 0,
    'grid_padding_bottom': 10,
    'grid_padding_right': 0,
    'grid_background': 'rgba(255, 255, 255, 0)',
    'grid_name_color': 'rgb(87, 93, 108, 1)',
    'grid_value_color': 'rgb(28, 31, 39, 1)',
    'grid_alarm_data': 'threshold',
    'grid_alarm_max': 'max',
    'grid_alarm_min': 'min',
    'grid_alarm_color': 'color',
    'grid_title_height': 48,
    'grid_title_color': 'rgba(255, 255, 255, 1)',
    'grid_title_background': 'rgba(0, 123, 255, 1)',
    'grid_title_padding_top': 0,
    'grid_title_padding_bottom': 0
    // body: {
    //   grid_name: '名称',
    //   grid_value: '10'
    // }

    // body: [ // 容器类字段
    //  {
    //    'type': 'info-grid',
    //    'tpl': '自定义容器区',
    //    'inline': false
    //  }
    // ]
  };
  previewSchema = { // 组件面板预览时需要
    type: 'info-grid',
    label: 'info-grid',
    grid_icon: 'icon',
    grid_name: 'name',
    grid_value: 'value',
    grid_num: 1,
    'source_data': 'records',
    'grid_margin_bottom': 5,
    'grid_padding_top': 10,
    'grid_padding_left': 0,
    'grid_padding_bottom': 10,
    'grid_padding_right': 0,
    'grid_unit': 'unit',
    'grid_background': 'rgba(255, 255, 255, 0)',
    'grid_name_color': 'rgb(87, 93, 108, 1)',
    'grid_value_color': 'rgb(28, 31, 39, 1)',
    'grid_alarm_max_num': 'depth',
    'grid_alarm_max_color': 'rgba(240, 53, 8, 1)',
    'grid_alarm_min_num': 'status',
    'grid_alarm_min_color': 'rgba(189, 16, 224, 1)',
    'grid_title_height': 48,
    'grid_title_color': 'rgba(255, 255, 255, 1)',
    'grid_title_background': 'rgba(0, 123, 255, 1)',
    'grid_title_padding_top': 0,
    'grid_title_padding_bottom': 0
    // grid_name: '名称',
    // grid_value: '10'
    // body: '自定义容器区'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title
  //   buildEditorContextMenu(
  //     { id, schema, region, selections }: ContextMenuEventContext,
  //     menus: Array<ContextMenuItem>
  //   ) {
  //     menus.push({
  //       label: '重复一份',
  //       icon: 'copy-icon',
  //       disabled: selections.some(item => !item.node.duplicatable),
  //       onSelect: () => manager.duplicate(selections.map(item => item.id))
  //     })
  // }
  // panelBodyCreator = context => {
  //   console.log(context)
  //   return [
  //     {
  //       type: 'tabs',
  //       tabsMode: 'line',
  //       className: 'm-t-n-xs',
  //       contentClassName: 'no-border p-l-none p-r-none',
  //       tabs: [
  //         {
  //           title: '常规',
  //           controls: [
  //             {
  //               type: 'text',
  //               name: 'source_data',
  //               label: '数据源',
  //               value: 'records'
  //             },
  //             {
  //               type: 'text',
  //               name: 'grid_icon',
  //               label: '图标Key',
  //               value: 'icon'
  //             },
  //             {
  //               type: 'text',
  //               name: 'grid_name',
  //               label: '名称Key',
  //               value: 'projectName'
  //             },
  //             {
  //               type: 'text',
  //               name: 'grid_value',
  //               label: '数量Key',
  //               value: 'value'
  //             },
  //             {
  //               type: 'text',
  //               name: 'grid_unit',
  //               label: '单位Key',
  //               value: 'unit'
  //             }

  //           ]
  //         },
  //         {
  //           title: '外观',
  //           controls: [
  //             {
  //               'type': 'collapse-group',
  //               'activeKey': [
  //                 '1'
  //               ],
  //               'body': [
  //                 {
  //                   'type': 'collapse',
  //                   'key': '1',
  //                   'header': '标题1',
  //                   'body': '这里是内容1'
  //                 },
  //                 {
  //                   'type': 'collapse',
  //                   'key': '2',
  //                   'header': '标题2',
  //                   'body': '这里是内容2'
  //                 },
  //                 {
  //                   'type': 'collapse',
  //                   'key': '3',
  //                   'header': '标题3',
  //                   'body': '这里是内容3'
  //                 }
  //               ]
  //             }
  //             // {
  //             //   'type': 'collapse-group',
  //             //   body: [
  //             //     {
  //             //       'type': 'collapse',
  //             //       'key': '1',
  //             //       'header': '基本',
  //             //       'body': [
  //             //         {
  //             //           type: 'input-number',
  //             //           name: 'grid_num',
  //             //           label: '一行显示数量',
  //             //           value: 5
  //             //         }
  //             //       ]
  //             //     },
  //             //     {
  //             //       'type': 'collapse',
  //             //       'key': '2',
  //             //       'header': '间距设置',
  //             //       'body': [
  //             //         {
  //             //           type: 'input-number',
  //             //           name: 'grid_margin_bottom',
  //             //           label: '行间距',
  //             //           value: 5
  //             //         },
  //             //         {
  //             //           type: 'input-number',
  //             //           name: 'grid_padding_top',
  //             //           label: '上边距',
  //             //           value: 10
  //             //         },
  //             //         {
  //             //           type: 'input-number',
  //             //           name: 'grid_padding_left',
  //             //           label: '左边距',
  //             //           value: 0
  //             //         },
  //             //         {
  //             //           type: 'input-number',
  //             //           name: 'grid_padding_bottom',
  //             //           label: '下边距',
  //             //           value: 10
  //             //         },
  //             //         {
  //             //           type: 'input-number',
  //             //           name: 'grid_padding_right',
  //             //           label: '右边距',
  //             //           value: 0
  //             //         }
  //             //       ]
  //             //     }

  //             //   ]
  //             // }

  //           ]
  //         }

  //         // {
  //         //   title: '事件',
  //         //   controls: []
  //         // }
  //       ]
  //     }

  //   ]
  // };
  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '常规',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'grid_11', 'grid_12'
              ],
              body: [
                {
                  'type': 'collapse',
                  'key': 'grid_11',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'source_data',
                      label: '数据源',
                      value: 'deviceData',
                      // 'mode': 'inline',
                      'required': true

                    },
                    {
                      type: 'input-text',
                      name: 'grid_name',
                      label: '子设备名称',
                      value: 'deviceName',
                      // 'mode': 'inline',
                      'required': true
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_12',
                  'header': '采集项',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'grid_child_data',
                      label: '采集项数据',
                      value: 'data',
                      'required': true

                    },
                    {
                      type: 'input-text',
                      name: 'grid_child_name',
                      label: '名称',
                      value: 'attrName',
                      // 'mode': 'inline',
                      'required': true
                    },
                    {
                      type: 'input-text',
                      name: 'grid_child_value',
                      label: '数值',
                      value: 'value',
                      // 'mode': 'inline',
                      'required': true
                    },
                    {
                      type: 'input-text',
                      name: 'grid_child_unit',
                      label: '单位',
                      value: 'attrUnit'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'grid_child_icon',
                      label: '图标',
                      value: 'icon'
                      // 'mode': 'inline'
                    },

                    {
                      'type': 'switch',
                      'label': '是否可以写入操作',
                      'option': '',
                      'name': 'grid_edit_value',
                      'falseValue': false,
                      'trueValue': true,
                      'value': true,
                      'onText': '是',
                      'offText': '否'
                    }
                  ]
                }
              ]
            }

          ]
        },
        {
          title: '外观',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'grid_21', 'grid_22', 'grid_23', 'grid_24'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'grid_24',
                  'header': '标题样式',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-number',
                      name: 'grid_title_height',
                      label: '高度',
                      value: 48
                      // 'mode': 'inline'
                    },
                    {
                      'type': 'input-color',
                      'label': '标题颜色',
                      'name': 'grid_title_color',
                      'format': 'rgba',
                      'value': 'rgb(87, 93, 108, 1)',
                      'labelRemark': {
                        'icon': 'fa fa-question-circle',
                        'trigger': [
                          'hover'
                        ],
                        'className': 'Remark--warning',
                        'placement': 'top',
                        'content': '设置标题颜色'
                      },
                      'resetValue': 'rgb(87, 93, 108, 1)',
                      'allowCustomColor': true
                      // 'mode': 'inline'
                    },
                    {
                      'type': 'input-color',
                      'label': '标题背景色',
                      'name': 'grid_title_background',
                      'format': 'rgba',
                      'value': 'rgba(0, 82, 199, 1)',
                      'labelRemark': {
                        'icon': 'fa fa-question-circle',
                        'trigger': [
                          'hover'
                        ],
                        'className': 'Remark--warning',
                        'placement': 'top',
                        'content': '设置标题背景色'
                      },
                      'allowCustomColor': true
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'grid_title_padding_top',
                      label: '上边距',
                      value: 0
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'grid_title_padding_bottom',
                      label: '下边距',
                      value: 0
                      // 'mode': 'inline'
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_21',
                  'header': '行数量',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-number',
                      name: 'grid_num',
                      label: '一行显示数量',
                      value: 5
                      // 'mode': 'inline'
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_22',
                  'header': '显隐/排序设置',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      'type': 'switch',
                      'label': '状态',
                      'option': '',
                      'name': 'grid_edit',
                      'falseValue': false,
                      'trueValue': true,
                      'value': true,
                      'onText': '启用',
                      'offText': '禁用'
                    },
                    {
                      'type': 'input-text',
                      'label': '显隐key',
                      'name': 'grid_check_key',
                      'value': 'attrId',
                      'hiddenOn': '${AND(!grid_edit)}'
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_23',
                  'header': '间距',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-number',
                      name: 'grid_margin_bottom',
                      label: '行间距',
                      value: 5
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'grid_padding_top',
                      label: '上边距',
                      value: 10
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'grid_padding_left',
                      label: '左边距',
                      value: 0
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'grid_padding_bottom',
                      label: '下边距',
                      value: 10
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'grid_padding_right',
                      label: '右边距',
                      value: 0
                      // 'mode': 'inline'
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_24',
                  'header': '颜色',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      'type': 'input-color',
                      'label': '背景色',
                      'name': 'grid_background',
                      'format': 'rgba',
                      'value': 'rgba(255, 255, 255, 0)',
                      'labelRemark': {
                        'icon': 'fa fa-question-circle',
                        'trigger': [
                          'hover'
                        ],
                        'className': 'Remark--warning',
                        'placement': 'top',
                        'content': '设置整个区域的背景色'
                      },
                      'allowCustomColor': true
                      // 'mode': 'inline'
                    },
                    {
                      'type': 'input-color',
                      'label': '名称颜色',
                      'name': 'grid_name_color',
                      'format': 'rgba',
                      'value': 'rgb(87, 93, 108, 1)',
                      'labelRemark': {
                        'icon': 'fa fa-question-circle',
                        'trigger': [
                          'hover'
                        ],
                        'className': 'Remark--warning',
                        'placement': 'top',
                        'content': '设置名称颜色'
                      },
                      'resetValue': 'rgb(87, 93, 108, 1)',
                      'allowCustomColor': true
                      // 'mode': 'inline'
                    },
                    {
                      'type': 'input-color',
                      'label': '数值颜色',
                      'name': 'grid_value_color',
                      'format': 'rgba',
                      'value': 'rgb(28, 31, 39, 1)',
                      'labelRemark': {
                        'icon': 'fa fa-question-circle',
                        'trigger': [
                          'hover'
                        ],
                        'className': 'Remark--warning',
                        'placement': 'top',
                        'content': '设置数值颜色'
                      },
                      'resetValue': 'rgb(28, 31, 39, 1)',
                      'allowCustomColor': true
                      // 'mode': 'inline'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          title: '阈值',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'grid_31', 'grid_32'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'grid_31',
                  'header': '阈值数据设置',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'grid_alarm_data',
                      label: '阈值数据',
                      value: 'threshold'
                      // 'mode': 'inline'
                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_32',
                  'header': '阈值显示设置',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'grid_alarm_max',
                      label: '对应最大值',
                      value: 'max'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'grid_alarm_min',
                      label: '对应最小值',
                      value: 'min'
                      // 'mode': 'inline'
                    },
                    {
                      'type': 'input-text',
                      'label': '对应显示颜色',
                      'name': 'grid_alarm_color',
                      value: 'color'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(InfoGridPlugin)
