/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { getSchemaTpl } from '@/utils/amis-widget/index.esm';
import { BasePlugin, registerEditorPlugin } from 'amis-editor'
import { tipedLabel } from 'amis-editor-core';
export class YsCameraPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'ys-camera';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '萤石摄像头';
  order = 0; // 排序
  description = '萤石摄像头展示信息';
  tags = ['摄像头', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'ys-camera',
    isValidateSave: true,
    deviceNo: 'BC5309558',
    accessToken: 'at.8l6nn720b5e1syula2h4h7cp6krko6z4-3k7ushfjmt-0hyspt0-exhdzu9j6',
    cameraId: 'video-container',
    template: 'pcLive',
    height: 400,
    isIntercom: true
  };
  previewSchema = { // 组件面板预览时需要
    type: 'ys-camera',
    label: '萤石摄像头'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  // 事件定义


  panelTitle = '配置'; // 右侧属性面板Title
  // 右侧属性面板配置项

  panelControls = getSchemaTpl('tabs', [
    {
      title: '属性',
      body: getSchemaTpl('collapseGroup', [
        {
          title: '数据',
          body: [
            {
              name: 'height',
              label: '初始化播放器高度',
              type: 'input-number',
              value: 400
            },
            {
              name: 'cameraId',
              label: tipedLabel(
                '容器ID',
                '如有多个请修改ID'
              ),
              type: 'input-text',
              value: 'video-container'
            },
            {
              name: 'deviceNo',
              label: '设备编号',
              type: 'input-text',
              value: 'BC5309558'
            },
            {
              name: 'accessToken',
              label: 'accessToken',
              type: 'input-text',
              value: 'at.8l6nn720b5e1syula2h4h7cp6krko6z4-3k7ushfjmt-0hyspt0-exhdzu9j6'
            },
            {
              name: 'template',
              label: tipedLabel(
                '使用模版',
                '如自定义模板请到萤石云控制台复制输入'
              ),
              type: 'input-text',
              description: "simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;",
              value: 'pcLive'
            },
            getSchemaTpl('expression', {
              name: 'isIntercom',
              label: '表达式',
            })
          ]
        }
      ]),

    },
    getSchemaTpl('status')
  ])
}

registerEditorPlugin(YsCameraPlugin)
