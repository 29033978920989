<template>
  <div>
    <a-tooltip placement="bottom">
      <template slot="title">
        刷新页面
      </template>
      <!-- <a-icon :type="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="click" :style="{ fontSize: '20px' }"/> -->
      <img
        style="height: 28px;cursor: pointer;"
        src="@/assets/topBtn/2.png"
        @click="refreshPage"
      >

      <!-- <a-icon :type="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="click" :style="{ fontSize: '20px' }"/> -->
    </a-tooltip>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import message from 'ant-design-vue/es/message'
export default {
  data () {
    return {
      isFullscreen: false
    }
  },
  computed: {

    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.menus,
      excludePage: state => state.app.excludePage,
      pageHide: state => state.app.pageHide
    })
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    refreshPage () {
      const hideMessage = message.loading('正在刷新页面！', 0)

      // this.$router.go(0) // 刷新当前页面
      // this.reload()
      console.log('>>>>', this.$route)
      let fullPath = this.$route.fullPath.split('#')[0]
      this.$store.dispatch('setPageHide', true)
      this.$store.dispatch('setExcludePage', [fullPath])
      this.$store.dispatch('setRouterKey', new Date().getTime())

      setTimeout(() => {
        this.$store.dispatch('setPageHide', false)

        setTimeout(() => {
          // this.$store.dispatch('setExcludePage', [])
          hideMessage()
        }, 100)
      }, 100)
    }
  }
}
</script>
