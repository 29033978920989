<template>
  <div class="amap-wrapper">
    <div
      :id="'press' + press_create_time"
      class="press_btn"
      :style="{ width: press_width, height: press_height, paddingLeft: press_padding_left, 
        paddingRight: press_padding_right, paddingTop: press_padding_top, 
        paddingBottom: press_padding_bottom,marginLeft: press_margin_left, 
        marginRight: press_margin_right, marginTop: press_margin_top, 
        marginBottom: press_margin_bottom,background:longPressActive?press_active_bg:press_bg}"
      @mousedown="handleDown"
      @mouseup="handleUp"
    >
    {{ press_text||'按钮' }}
    </div>
  </div>
</template>
<script>
import { replaceStrAmis } from '@/utils/util'
import request from '@/utils/requestAmis'
export default {
  props: {
    width: {
      type: String || Number,
      default: '100px'
    },
    height: {
      type: String || Number,
      default: '50px'
    },
    isSearch: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      longPressTimer: null,
      longPressActive: false
    }
  },
  beforeMount () {
    // console.log('byMapScript>>>>>', byMapScript)

    // byMapScript.src = 'https://www.bing.com/api/maps/mapcontrol?key=' + this.amap_key + '&mkt=zh-cn'
  },
  mounted () {
  },
  computed: {
    // 更新数据源
    comData() {
      return this.$data.data
    },
    dataForm() {
      let formObj = {}
      for (const key in this.press_data) {
        formObj[key] = replaceStrAmis(this.press_data[key],this.comData)
      }
      return formObj
    }
  },
  created () {
    
  },
  updated () {
    if (this.renderChild && this.body) {
      this.renderChild('body', this.body, 'ReactChildBody')
    }
    // console.log('markersList>>>>>', this.markersList)
  },
  methods: {
    init () {
    },
    handleUp () {
      console.log('handleUp>>>>');
      
      // 清除定时器
      clearTimeout(this.longPressTimer);
      this.fetcher()

      this.longPressActive = false
    },
    handleDown () {
      console.log('handleDown>>>>',this.press_data);
      console.log('handleDown>>>>dataForm>>>>>>',this.dataForm);
      
      this.longPressActive = true;
     
      // 设置一个定时器，如果用户持续按住，则执行长按操作
      this.longPressTimer = setTimeout(() => {
        // 执行长按后的操作
        console.log('Long press triggered');
        this.fetcher()
      }, 800); // 长按1秒
    },
    // 接口请求
    fetcher(){
      const requestObj = {
        url: this.press_api_url,
        method: this.press_method,
        data:this.dataForm
      }
      return request(requestObj)
    }
    

  }
}
</script>
<style lang="less" scoped>
.press_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.press_u {
  background: #e6f0ff;
}

.press_d {
  background: #2878ff;
}
</style>
