<template>
  <div class="bg">
    <img
      src="./img/1.png"
      alt=""
      class="nav"
      @click="toRoute"
    >
    <div class="main">
      <div class="left_main">
        <div class="statistics">
          <span>数据统计</span>
          <span class="tip_en">data statistics</span>
        </div>
        <div class="fildNum">
          <div class="tip">场地总数</div>
          <div class="num">{{ fildOrdeviceInfo.siteNum }}</div>
        </div>
        <div class="statistics_info">
          <div class="device">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">设备总数</div>
              <div class="device_info_num">{{ fildOrdeviceInfo.deviceNum }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/8.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
          <div class="prot">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">充电端口数</div>
              <div class="device_info_num">{{ fildOrdeviceInfo.childDeviceNum }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/9.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
        </div>
        <div class="statistics">
          <span>收益统计</span>
          <span class="tip_en">Revenue Statistics</span>
        </div>
        <div class="income">
          <div class="day_income">
            <img
              src="./img/10.png"
              alt=""
              class="day_income_icon"
            >
            <div class="day_income_info">
              <div class="day_income_info_tip">今日收益</div>
              <div class="day_income_num">{{ incomeInfo.todayProfit }}</div>
            </div>
          </div>
          <div class="Monthly_income">
            <img
              src="./img/10.png"
              alt=""
              class="day_income_icon"
            >
            <div class="day_income_info">
              <div class="day_income_info_tip">本月收益</div>
              <div class="day_income_num">{{ incomeInfo.monthProfit }}</div>
            </div>
          </div>

        </div>
        <!-- 折线图 -->
        <div class="line-chart">
          <line-chart :chart-data="lineChartData1" />
        </div>
      </div>
      <!-- 地图 -->
      <div class="center_main">
        <pie-chart :chart-data="lineChartData3" />
      </div>
      <div class="right_main">
        <div class="statistics">
          <span>用户统计</span>
          <span class="tip_en">User Statistics</span>
        </div>
        <div class="fildNum">
          <div class="tip">用户总数</div>
          <div class="num">{{ userInfo.totalCount	 }}</div>
        </div>
        <div class="statistics_info">
          <div class="device">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">今日新增</div>
              <div class="device_info_num">{{ userInfo.todayCount }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/8.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
          <div class="prot">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">本月新增</div>
              <div class="device_info_num">{{ userInfo.monthCount }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/9.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
        </div>
        <div class="statistics">
          <span>充电桩分布</span>
          <span class="tip_en">Distribution Of Charging Stations</span>
        </div>
        <div>
          <!-- 饼状图 -->
          <PancakeChart :chart-data="lineChartData" />
        </div>
        <div class="statistics">
          <span>充电数据</span>
          <span class="tip_en">Charging Data</span>
        </div>
        <div>
          <orderNumChart :chart-data="lineChartData2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from './components/LineChart'
import PieChart from './components/PieChart'
import orderNumChart from './components/orderNumChart'
import PancakeChart from './components/PancakeChart'
import { qryFieldInfo, getFixationData, getChangeData, adminUserStatistics, pie, pmapie } from './api/home'
// 高德地图
const lineChartData = {
  goodsSaleInfo: {
    timeData: [],
    totalMoneyData: []
  },
  UserConsume: {
    timeData: [],
    totalMoneyData: []
  },
  TrendInfo: {
    timeData: [],
    orderNumData: [],
    parkOrderNumData: []
  },
  mapsInfo: {
    name: [],
    deviceNum: [],
    siteNum: [],
    list: []
  }
}
export default {
  name: 'DashboardAdmin',
  components: {
    LineChart,
    PieChart,
    PancakeChart,
    orderNumChart
  },

  data () {
    return {
      lineChartData: lineChartData.goodsSaleInfo,
      lineChartData1: lineChartData.UserConsume,
      lineChartData2: lineChartData.TrendInfo,
      lineChartData3: lineChartData.mapsInfo,
      FelidList: [],
      AengtList: [],
      roleNo: '',
      // center: [116.396304, 39.909847],
      markers: [],
      fildOrdeviceInfo: { //场地数
        childDeviceNum: 0,
        deviceNum: 0,
        siteNum: 0,
      },
      incomeInfo: {
        todayProfit: 0,
        monthProfit: 0,
      },
      userInfo: {
        monthCount: 0,
        todayCount: 0,
        totalCount: 0
      },
      deviceList: [],
    }
  },
  created () {
    this.getSiteNum()
    this.getChartData()
    this.getPie()
    this.getMaps()
    this.getDeviceNum()
  },
  mounted () {

  },
  methods: {
    //场地数据
    getSiteNum () {
      getFixationData().then(res => {
        console.log(res, 'changdi')
        this.fildOrdeviceInfo = res.data
      })
    },
    handleSetLineChartData (type) {
      this.lineChartData = lineChartData[type]
    },
    // 地图数据
    getMapList () {
      qryFieldInfo().then(res => {
        console.log(res, '地图')
        this.deviceList = res
      })
    },
    //用户统计
    getDeviceNum () {
      adminUserStatistics().then(res => {
        for (const key in this.userInfo) {
          this.userInfo[key] = res.data[key]
        }
        console.log(res.data)
        console.log(this.userInfo, '用户数')
        // this.lineChartData.timeData = []
        // this.lineChartData.totalMoneyData = []
        // console.log(this.fildOrdeviceInfo)
        // var obj = {
        //   value: '',
        //   name: ''
        // }
        // if (res.fieldDistrs && res.fieldDistrs.length > 0) {
        //   res.fieldDistrs.forEach(v => {
        //     this.lineChartData.timeData.push(v.prov)
        //     obj.value = v.ratioNum
        //     obj.name = v.prov
        //     this.lineChartData.totalMoneyData.push(obj)
        //   })
        // }
        console.log(this.lineChartData)
      })
    },
    getPie () {
      pie().then(res => {
        console.log(res.data, '饼图')
        this.lineChartData.timeData = []
        this.lineChartData.totalMoneyData = []
        if (res.data && res.data.length > 0) {
          res.data.forEach(v => {
            this.lineChartData.timeData.push(v.name)
            this.lineChartData.totalMoneyData.push(v)
          })
        }
        console.log(this.lineChartData)
      })
    },
    getMaps () {
      // let mapName1 = ['内丘县', '临城县', '柏乡县', '宁晋县', '新河县', '南宫市', '清河县', '临西县', '威县', '广宗县', '巨鹿县', '平乡县', '隆尧县', '任泽区', '南和区', '襄都区', '沙河市']
      let mapName1 = ['北京市', '河北省', '山西省', '辽宁省', '吉林省', '黑龙江省', '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖南省', '湖北省', '四川省', '广东省', '海南省', '贵州省', '云南省', '陕西省', '甘肃省', '青海省', '台湾省', '内蒙古自治区', '广西壮族自治区', '西藏自治区', '宁夏回族自治区', '新疆维吾尔自治区', '上海市', '天津市', '重庆市', '香港特别行政区', '澳门特别行政区']
      pmapie({ mapName: mapName1 }).then(res => {
        this.lineChartData3.list = []
        console.log(res.data, '地图')
        res.data.forEach(v => {
          v.name = v.areaName
          v.value = v.siteNum
          this.lineChartData3.list.push(v)
        })
        console.log(this.lineChartData3, '地图')
      })
    },
    // 收益统计
    getChartData () {
      getChangeData().then(res => {
        console.log(res.data)
        for (const key in this.incomeInfo) {
          this.incomeInfo[key] = res.data[key]
        }
        this.lineChartData1.timeData = []
        this.lineChartData1.totalMoneyData = []
        this.lineChartData2.timeData = []
        this.lineChartData2.orderNumData = []
        if (res.data.countList && res.data.countList.length > 0) {
          res.data.countList.forEach(v => {
            this.lineChartData1.timeData.push(v.orderTime)
            this.lineChartData1.totalMoneyData.push(v.orderMoney)
            this.lineChartData2.timeData.push(v.orderTime)
            this.lineChartData2.orderNumData.push(v.orderNum)
          })
        }
      })
    },
    toRoute () {
      console.log(454545)
      this.$router.push({ path: this.$store.getters.defaultRoutePath }).catch(() => { })
    }
  }
}
</script>
<style lang="less" scoped>
.bg {
  background-image: url('./img/2.png');
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .nav {
    width: 100vw;
    height: 9.62vh;
    cursor: pointer;
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 88vh;
    .left_main {
      width: 26.8vw;
      height: 100%;
      padding: 0 0.78125vw 0 1.04vw;
      .statistics {
        background: url('./img/3.png') no-repeat;
        width: 25vw;
        height: 5vh;
        padding-left: 1.3vw;
        span {
          color: #f8f8f9;
          line-height: 5vh;
          font-size: 1vw;
          font-weight: bold;
        }
        .tip_en {
          color: rgba(183, 207, 252, 0.4);
          font-weight: bold;
          font-size: 0.5vw;
          margin-right: 0.36vw;
        }
      }
      .fildNum {
        background: url('./img/5.png') no-repeat;
        width: 24vw;
        height: 6.5vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-size: 100% 100%;
        padding: 0 0.78vw 0 0.4vw;
        margin-top: 0.78vw;
        .num {
          font-size: 1.4vw;
          font-family: DIN-Bold-, DIN-Bold;
          font-weight: normal;
          color: #ffffff;
          line-height: 6.5vh;
        }
        .tip {
          font-size: 1.1vw;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: bold;
          color: #ffffff;
          line-height: 6.5vh;
        }
      }
      .statistics_info {
        display: flex;
        align-content: center;
        margin-top: 2vh;
        margin-bottom: 3.3vh;
        .device {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.8vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #edc910;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #edc910;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
        .prot {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          margin-left: 1.8vw;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.8vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #00ff84;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #00ff84;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
      }
      .income {
        margin-top: 4.2vh;
        display: flex;
        align-items: center;
        margin-bottom: 5.3vh;
        .day_income {
          display: flex;
          align-items: flex-start;
          .day_income_icon {
            width: 0.9375vw;
            height: 1.7vh;
          }
          .day_income_info {
            margin-left: 0.46875vw;
            .day_income_info_tip {
              font-size: 0.9vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: bold;
              color: #ffffff;
            }
            .day_income_num {
              margin-top: 1.4vh;
              background: url('./img/4.png') no-repeat;
              background-size: 100% 100%;
              width: 8.8vw;
              height: 3.5vh;
              font-size: 1vw;
              font-family: PangMenZhengDao-, PangMenZhengDao;
              font-weight: normal;
              color: #43e7ff;
              text-align: center;
              padding: 0.6vh 0 0.9vh;
            }
          }
        }
        .Monthly_income {
          display: flex;
          align-items: flex-start;
          margin-left: 2.6vw;
          .day_income_icon {
            width: 0.9375vw;
            height: 1.7vh;
          }
          .day_income_info {
            margin-left: 0.46875vw;
            .day_income_info_tip {
              font-size: 0.9vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: bold;
              color: #ffffff;
            }
            .day_income_num {
              margin-top: 1.4vh;
              background: url('./img/4.png') no-repeat;
              background-size: 100% 100%;
              width: 8.8vw;
              height: 3.5vh;
              font-size: 1vw;
              font-family: PangMenZhengDao-, PangMenZhengDao;
              font-weight: normal;
              color: #43e7ff;
              text-align: center;
              padding: 0.6vh 0 0.9vh;
            }
          }
        }
      }
      .line-chart {
      }
    }
    .center_main {
      width: 46.4vw;
      height: 100%;
    }
    .right_main {
      width: 26.8vw;
      height: 100%;
      padding: 0 0.78125vw 0 1.04vw;
      .statistics {
        background: url('./img/3.png') no-repeat;
        width: 25vw;
        height: 5vh;
        padding-left: 1.3vw;
        span {
          color: #f8f8f9;
          line-height: 5vh;
          font-size: 1vw;
          font-weight: bold;
        }
        .tip_en {
          color: rgba(183, 207, 252, 0.4);
          font-weight: bold;
          font-size: 0.5vw;
          margin-right: 0.36vw;
        }
      }
      .fildNum {
        background: url('./img/5.png') no-repeat;
        width: 24vw;
        height: 6.5vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-size: 100% 100%;
        padding: 0 0.78vw 0 0.4vw;
        margin-top: 0.78vw;
        .num {
          font-size: 1.4vw;
          font-family: DIN-Bold-, DIN-Bold;
          font-weight: normal;
          color: #ffffff;
          line-height: 6.5vh;
        }
        .tip {
          font-size: 1.1vw;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: bold;
          color: #ffffff;
          line-height: 6.5vh;
        }
      }
      .statistics_info {
        display: flex;
        align-content: center;
        margin-top: 2vh;
        margin-bottom: 3.3vh;
        .device {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.8vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #edc910;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #edc910;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
        .prot {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          margin-left: 1.8vw;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.8vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #00ff84;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #00ff84;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
      }
    }
  }
}
#container {
  width: 46.4vw;
  height: 100%;
}
</style>
