/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '删除按钮';
  order=0; // 排序
  description = '删除按钮信息';
  tags = ['布局容器', '表格', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'button',
    'label': '删除',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'outputVar': 'responseResult',
            'actionType': 'ajax',
            'options': {
              'silent': false
            },
            'api': {
              'url': '/gather/device/info/delete?id=${id}&objectId=${objectId}',
              'method': 'post',
              'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
              'adaptor': '',
              'messages': {
              },
              'dataType': 'json',
              'replaceData': false,
              'data': {
                '&': '$$'
              }
            }
          },
          {
            'componentId': 'DeviceList:table',
            'ignoreError': false,
            'actionType': 'reload',
            'args': {
              'resetPage': true
            }
          }
        ]
      }
    },
    'confirmText': '数据删除后无法恢复，请确认!',
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:delete')}",
    'id': 'u:0f8420382125',
    'themeCss': {
      'className': {
        'background:default': '#FF2121',
        'font:default': {
          'color': 'white'
        },
        'background:hover': '#FF2121',
        'font:hover': {
          'color': 'white'
        },
        'border:hover': {
          'border': '0'
        },
        'border:default': {
          'border': '0'
        },
        'background:active': '#FF2121',
        'font:active': {
          'color': 'white'
        },
        'border:active': {
          'border': '0'
        },
        'padding:default': '10px',
        'margin': '0 !important',
        'font-family': '思源-Medium'
      }
    },
    'className': 'className-0f8420382125',
    'icon': 'a-zu57163'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'button',
    'label': '删除',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'outputVar': 'responseResult',
            'actionType': 'ajax',
            'options': {
              'silent': false
            },
            'api': {
              'url': '/gather/device/info/delete?id=${id}&objectId=${objectId}',
              'method': 'post',
              'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
              'adaptor': '',
              'messages': {
              },
              'dataType': 'json',
              'replaceData': false,
              'data': {
                '&': '$$'
              }
            }
          },
          {
            'componentId': 'DeviceList:table',
            'ignoreError': false,
            'actionType': 'reload',
            'args': {
              'resetPage': true
            }
          }
        ]
      }
    },
    'confirmText': '数据删除后无法恢复，请确认!',
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:delete')}",
    'id': 'u:0f8420382125',
    'themeCss': {
      'className': {
        'background:default': 'rgba(238, 238, 238, 0.64)',
        'font:default': {
          'color': '#707070'
        },
        'border:default': {
          'border': '0'
        },
        'background:hover': 'rgba(238, 238, 238, 0.64)',
        'font:hover': {
          'color': '#707070'
        },
        'border:hover': {
          'border': '0'
        },
        'background:active': 'rgba(238, 238, 238, 0.64)',
        'font:active': {
          'color': '#707070'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'className': 'className-0f8420382125',
    'icon': 'fas fa-trash-alt'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
