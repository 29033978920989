/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoComponentPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'info-component';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '子组件';
  order=0; // 排序
  description = '详情页面展示信息';
  tags = ['组件', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'info-component',
    label: 'info-component',
    'info_com_menuId': ''
  };
  previewSchema = { // 组件面板预览时需要
    type: 'info-component',
    label: 'info-component',
    'info_com_menuId': ''
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '常规',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'grid_11', 'grid_12'
              ],
              body: [
                {
                  'type': 'collapse',
                  'key': 'grid_11',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'info_com_menuId',
                      label: '菜单id',
                      value: '',
                      'required': true

                    }
                  ]
                },
                {
                  'type': 'collapse',
                  'key': 'grid_12',
                  'header': '传参',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      'type': 'combo',
                      'label': '组合输入',
                      'name': 'comData',
                      'multiple': true,
                      'addable': true,
                      'removable': true,
                      'removableMode': 'icon',
                      'addBtn': {
                        'label': '新增',
                        'icon': 'fa fa-plus',
                        'level': 'primary',
                        'size': 'sm',
                        'id': 'u:1002b9e91d91'
                      },
                      'items': [
                        {
                          'type': 'input-text',
                          'name': 'comKey',
                          'placeholder': '请输入子页面查询条件对应的key',
                          'id': 'u:36dab3938943'
                        },
                        {
                          'type': 'input-text',
                          'name': 'comValue',
                          'placeholder': '请输入要传值对应的key',
                          'id': 'u:a2d6117b7a38'
                        }
                      ],
                      'id': 'u:2e32662b5323',
                      'strictMode': true,
                      'syncFields': [],
                      'canAccessSuperData': true
                    }
                  ]
                }
              ]
            }

          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(InfoComponentPlugin)
