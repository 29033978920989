/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-china'
  $schema = '/schemas/UnkownSchema.json'
  notRenderFormZone = true
  // 组件名称（组件面板显示的Title）
  name = 'chamchina'
  order = 0 // 排序
  description = '自定义鸡状地图'
  tags = ['展示', 'A自定义']
  icon = 'fa fa-file-code-o'
  scaffold = {
    // 插入到页面时需要
    type: 'chart',
    mapName: 'china',
    'mapURL': '/share/statistics/platform/chinaMap',
    config: {
      tooltip: {},
      series: [
        {
          type: 'map',
          map: 'china',
          geoIndex: 1,
          aspectScale: 0.75, // 长宽比
          showLegendSymbol: true, // 存在legend时显示
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false,
              textStyle: {
                color: '#fff'
              }
            }
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: '#031525',
              borderColor: '#3B5077',
              borderWidth: 1
            },
            emphasis: {
              areaColor: '#0f2c70'
            }
          },
          data: [{ name: '北京', value: 21300 }]
        }

      ]
    },
    replaceChartOption: true,
    name: '设备接入类型',
    label: '',
    height: '300px',
    dataFilter:
      '',
    themeCss: {
      baseControlClassName: {
        'boxShadow:default': ' 0px 0px 0px 0px transparent'
      }
    },
    id: 'u:fce707935df3'
  }
  previewSchema = {

  }

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置' // 右侧属性面板Title

  panelControls = [
    // 右侧属性面板配置项
  ]
}

registerEditorPlugin(InfoGridPlugin)
