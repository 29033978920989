/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-card';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamcard';
  order = 0; // 排序
  description = '自定义card';
  tags = ['布局容器', '表格', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '500',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:d6346f630d78',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'lineHeight': '1',
                          'fontSize': '24px',
                          'color': '#343c6a',
                          'font-family': '思源-Bold'
                        }
                      }
                    }
                  },
                  {
                    'type': 'tpl',
                    'tpl': '总场地数',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:8790f060748c',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'lineHeight': '1',
                          'fontSize': '14px',
                          'color': '#999999',
                          'font-family': '思源-Regular'
                        },
                        'padding-and-margin:default': {
                          'marginTop': '14px'
                        }
                      }
                    }
                  }
                ],
                'size': 'none',
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '1 1 auto',
                  'flexGrow': 1,
                  'flexWrap': 'nowrap',
                  'flexDirection': 'column',
                  'justifyContent': 'center'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:01f47c0e73b4',
                'themeCss': {
                  'baseControlClassName': {
                    'padding-and-margin:default': {
                      'marginTop': '0px',
                      'marginRight': '0px',
                      'marginBottom': '0px',
                      'marginLeft': '0px'
                    }
                  }
                }
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '1 1 auto',
              'flexGrow': 1,
              'flexBasis': 'auto'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:3904a4a3d827'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'image',
                'id': 'u:9de6ae723902',
                'enlargeAble': false,
                'maxScale': '',
                'minScale': '',
                'style': {
                  'display': 'block'
                },
                'src': 'http://47.92.37.224:7076/pic/1.png',
                'imageMode': 'original',
                'themeCss': {
                  'imageControlClassName': {
                    'size:default': {
                      'width': '50px',
                      'height': '50px',
                      'minWidth': ''
                    },
                    'border:default': {
                      'top-border-width': '0',
                      'left-border-width': '0',
                      'right-border-width': '0',
                      'bottom-border-width': '0'
                    },
                    'radius:default': {
                      'top-left-border-radius': '1000px',
                      'top-right-border-radius': '1000px',
                      'bottom-left-border-radius': '1000px',
                      'bottom-right-border-radius': '1000px',
                      'overflow': 'hidden'
                    }
                  }
                }
              }
            ],
            'size': 'none',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 1,
              'flexWrap': 'nowrap',
              'justifyContent': 'flex-end',
              'alignItems': 'center'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:34dde64310da'
          }
        ],
        'style': {
          'position': 'relative'
        },
        'label': '',
        'id': 'u:7af45bee7d9d'
      }
    ],
    'actions': [],
    'id': 'u:c8bd193911f2',
    'bodyClassName': '',
    'className': 'zzcy-card m-none'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '500',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:d6346f630d78',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'lineHeight': '1',
                          'fontSize': '24px',
                          'color': '#343c6a',
                          'font-family': '思源-Bold'
                        }
                      }
                    }
                  },
                  {
                    'type': 'tpl',
                    'tpl': '总场地数',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:8790f060748c',
                    'themeCss': {
                      'baseControlClassName': {
                        'font:default': {
                          'lineHeight': '1',
                          'fontSize': '14px',
                          'color': '#999999',
                          'font-family': '思源-Regular'
                        },
                        'padding-and-margin:default': {
                          'marginTop': '14px'
                        }
                      }
                    }
                  }
                ],
                'size': 'none',
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '1 1 auto',
                  'flexGrow': 1,
                  'flexWrap': 'nowrap',
                  'flexDirection': 'column',
                  'justifyContent': 'center'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:01f47c0e73b4',
                'themeCss': {
                  'baseControlClassName': {
                    'padding-and-margin:default': {
                      'marginTop': '0px',
                      'marginRight': '0px',
                      'marginBottom': '0px',
                      'marginLeft': '0px'
                    }
                  }
                }
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '1 1 auto',
              'flexGrow': 1,
              'flexBasis': 'auto'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:3904a4a3d827'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'image',
                'id': 'u:9de6ae723902',
                'enlargeAble': false,
                'maxScale': '',
                'minScale': '',
                'style': {
                  'display': 'block'
                },
                'src': 'http://47.92.37.224:7076/pic/1.png',
                'imageMode': 'original',
                'themeCss': {
                  'imageControlClassName': {
                    'size:default': {
                      'width': '50px',
                      'height': '50px',
                      'minWidth': ''
                    },
                    'border:default': {
                      'top-border-width': '0',
                      'left-border-width': '0',
                      'right-border-width': '0',
                      'bottom-border-width': '0'
                    },
                    'radius:default': {
                      'top-left-border-radius': '1000px',
                      'top-right-border-radius': '1000px',
                      'bottom-left-border-radius': '1000px',
                      'bottom-right-border-radius': '1000px',
                      'overflow': 'hidden'
                    }
                  }
                }
              }
            ],
            'size': 'none',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 1,
              'flexWrap': 'nowrap',
              'justifyContent': 'flex-end',
              'alignItems': 'center'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:34dde64310da'
          }
        ],
        'style': {
          'position': 'relative'
        },
        'label': '',
        'id': 'u:7af45bee7d9d'
      }
    ],
    'actions': [],
    'id': 'u:c8bd193911f2',
    'bodyClassName': '',
    'className': 'zzcy-card m-none'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
