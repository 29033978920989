// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import press from './press.vue'

registerRendererByType(press, {
  type: 'press',
  usage: 'formitem',
  weight: 99,
  framework: 'vue',
  detectProps: ['press_info']
})
