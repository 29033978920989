/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '统计卡片';
  order=0; // 排序
  description = '统计卡片展示信息';
  tags = ['布局容器', '驾驶舱', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': '',
      'avatar': ''
    },
    'body': [
      {
        'type': 'tpl',
        'tpl': '总项目数量',
        'wrapperComponent': '',
        'inline': false,
        'id': 'u:fbee365e2074',
        'style': {
          'boxShadow': ' 0px 0px 0px 0px transparent',
          'marginTop': '0',
          'marginRight': '0',
          'marginBottom': '4px',
          'marginLeft': '0',
          'fontWeight': '400',
          'font-family': 'PingFang SC-Regular, PingFang SC',
          'font-size': '1.8vh',
          'color': '#000000'
        }
      },
      {
        'type': 'tpl',
        'tpl': '${homeStatistic.projectCount}',
        'wrapperComponent': '',
        'inline': false,
        'id': 'u:600e214b1c32',
        'style': {
          'boxShadow': ' 0px 0px 0px 0px transparent',
          'fontWeight': '700',
          'fontSize': '3.5vh',
          'font-family': 'Source Han Sans CN-Bold, Source Han Sans CN'
        }
      }
    ],
    'actions': [
    ],
    'id': 'u:6b88835a8a5d',
    'className': 'm-b-none r-2x no-border',
    'descClassName': ''
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': '',
      'avatar': ''
    },
    'body': [
      {
        'type': 'tpl',
        'tpl': '总项目数量',
        'wrapperComponent': '',
        'inline': false,
        'id': 'u:fbee365e2074',
        'style': {
          'boxShadow': ' 0px 0px 0px 0px transparent',
          'marginTop': '0',
          'marginRight': '0',
          'marginBottom': '4px',
          'marginLeft': '0',
          'fontWeight': '400',
          'font-family': 'PingFang SC-Regular, PingFang SC',
          'font-size': '1.8vh',
          'color': '#000000'
        }
      },
      {
        'type': 'tpl',
        'tpl': '${homeStatistic.projectCount}',
        'wrapperComponent': '',
        'inline': false,
        'id': 'u:600e214b1c32',
        'style': {
          'boxShadow': ' 0px 0px 0px 0px transparent',
          'fontWeight': '700',
          'fontSize': '3.5vh',
          'font-family': 'Source Han Sans CN-Bold, Source Han Sans CN'
        }
      }
    ],
    'actions': [
    ],
    'id': 'u:6b88835a8a5d',
    'className': 'm-b-none r-2x no-border',
    'descClassName': '',
    'media': {
      'type': 'image',
      'className': 'absolute inset-0 m-0 w-full h-full',
      'url': 'http://47.92.37.224:7080/picture/1.png',
      'position': 'left'
    }
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
