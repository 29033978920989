<template>
  <div class="camera-wrapper">
    <div
      :id="cameraId"
      :style="{height: height+'px'}"
    ></div>
  </div>
</template>

<script>
// 萤石摄像头
import EZUIKit from "@/utils/ezuikit";
import { replaceStrAmis } from '@/utils/util'
var player = null;
export default {
  props: {
  },
  data () {
    return {
    }
  },
  watch: {
    width: {
      handler (value) {

        console.log(value, '21>>>>>>>>');
      },
      immediate: true
    }
  },
  beforeMount () {
    if (player) {
      this.stop()
      this.destroy();
    }
    // document.addEventListener('visibilitychange', this.visibilitychangeFun)
  },

  created () {
  },
  mounted () {
    console.log(this, '29>>>>>>>>>>');
    this.$nextTick(() => {
      this.init()
    })

  },
  beforeDestroy () {
    console.log('摄像头销毁。》》》》》》》》》》》');
    this.stop()
    this.destroy()
    // document.removeEventListener('visibilitychange', this.visibilitychangeFun)
  },
  updated () {
    if (this.renderChild && this.body) {
      this.renderChild('body', this.body, 'ReactChildBody')
    }
    // console.log('updated>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
  },
  methods: {
    setValidData (key, value) {
      if (this.store.storeType === 'ServiceStore') { // 上级作用域为顶层作用域或者Service
        this.store.reInitData({ [key]: value })
      } else if (this.store.storeType === 'FormStore') { // 上级作用域为表单
        this.store.setValueByName(key, value)
      }
    },
    visibilitychangeFun () {
      if (document.visibilityState === 'visible') {
        // 页面可见时执行的代码
        console.log('页面可见');
      } else if (document.visibilityState === 'hidden') {
        // 页面隐藏时执行的代码
        console.log('页面隐藏');
        this.stop()
        this.destroy()
      }
    },
    init () {
      if (player) {
        this.destroy();
      }
      let isIntercom = false
      if (this.isIntercom) {
        isIntercom = eval(this.isIntercom)
      }
      const deviceNo = replaceStrAmis(this.deviceNo, this.store.data)
      const accessToken = replaceStrAmis(this.accessToken, this.store.data)
      console.log(deviceNo, accessToken, isIntercom, '75>>>>>>>>>>>');
      player = new EZUIKit.EZUIKitPlayer({
        id: this.cameraId, // 视频容器ID
        accessToken: accessToken || this.accessToken,
        url: `ezopen://open.ys7.com/${deviceNo || this.deviceNo}/1.live`,
        // simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;
        template: "pcLive",
        plugin: isIntercom ? ["talk"] : [], // 加载插件，talk-对讲
        width: this.$el.clientWidth,
        height: this.height
      });
      window.player = player;
    },
    stop () {
      var stopPromise = player.stop();
      stopPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    destroy () {
      var destroyPromise = player.destroy();
      destroyPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
      window.player = player = null;
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
    doAction (action, args) {
      const actionType = action ? action.actionType : ''
      if (actionType === 'toast') {
        // 接收外部组件的事件动作'message'
        console.log(args)
        alert('您触发了自定义组件的事件动作[message]')
      } else {
        console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.camera-wrapper {
  & > div {
    width: 100%;
  }
}
</style>
