// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import GoogleMap from './googleMap.vue'

registerRendererByType(GoogleMap, {
  type: 'googlemap',
  usage: 'formitem', 
  weight: 99,
  framework: 'vue',
  detectProps: ['googleMap_info']
})
