<template>
  <page-header-wrapper>
    <AMisRendererDiv v-if="schema" :schema="schema" />
  </page-header-wrapper>
  <!-- <AMisRendererDiv v-if="schema" :schema="schema" /> -->
</template>

<script>
import AMisRendererDiv from '@/components/amis/AMisRenderer.vue'

import { baseMixin } from '@/store/app-mixin'
import { getMenuContent } from '@/api/menu'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
// import { mapGetters } from 'vuex'

export default {
  name: 'AMisRenderer',
  props: {
    menuId: {
      type: [String, Number],
      default: ''
    },
    permissionsProp: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    AMisRendererDiv
  },
  mixins: [baseMixin],
  data() {
    return {
      // horizontal  inline
      mode: 'inline',
      currentKey: ['base'],
      schema: null,
      token: encodeURIComponent('Bearer ' + storage.get(ACCESS_TOKEN))

    }
  },
  computed: {
    // ...mapGetters(['permissions'])
  },
  mounted() {
  },
  beforeCreate() {

  },
  created() {
    console.log('route>>>>', this.$route)
    console.log('menuId>>>>', this.menuId)

    if (this.menuId) {
      this.getMenuContent(this.menuId)
    } else {
      this.getMenuContent(this.$route.meta.menuId)
    }
  },
  methods: {
    // 查询菜单内容
    getMenuContent(menuId) {
      const that = this
      // console.log('当前amis菜单内容>>>>permissions>>>>', that.permissionsProp || [])
      getMenuContent({ menuId }).then(response => {
        console.log('查询页面内容>>>', menuId)

        // console.log('查询菜单内容>>>>', response)
        // debugger

        const schema = response.data ? JSON.parse(response.data) : {}
        // const permissions = []
        // if (that.menuId) {
        //   permissions = that.permissionsProp || []
        // } else {
        //   permissions = this.permissions
        // }
        // if (!schema['data']) {
        //   schema['data'] = {
        //     permissions: permissions,
        //     token: storage.get(ACCESS_TOKEN),
        //     locationUrl: window.location.origin,
        //     baseURL: process.env.VUE_APP_BASE_API
        //   }
        // } else {
        //   schema.data['permissions'] = permissions

        //   schema.data['token'] = storage.get(ACCESS_TOKEN)

        //   schema.data['locationUrl'] = window.location.origin

        //   schema.data['baseURL'] = process.env.VUE_APP_BASE_API
        // }

        this.schema = schema.body[0]
        console.log('页面JSON内容>>>>', that.schema)
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
.account-settings-info-main {
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;

  &.mobile {
    display: block;

    .account-settings-info-left {
      border-right: unset;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
      height: 50px;
      overflow-x: auto;
      overflow-y: scroll;
    }

    .account-settings-info-right {
      padding: 20px 40px;
    }
  }

  .account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width: 224px;
  }

  .account-settings-info-right {
    flex: 1 1;
    padding: 8px 40px;

    .account-settings-info-title {
      color: rgba(0, 0, 0, .85);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 12px;
    }

    .account-settings-info-view {
      padding-top: 12px;
    }
  }
}
</style>
