<template>
  <a-spin :spinning="spinning">
    <div class="bg">
      <iframe :src="'/datascreen.html?baseURL='+baseURL+'&tenantName='+tenantName+'&echartsObj='+JSON.stringify(echartsObj)" frameborder="0" style="width:100vw;height:100vh;"></iframe>
    </div>
  </a-spin>
</template>
<script>

import * as echartsObj from 'echarts';
// let echarts = require('echarts') // echarts theme

require('echarts/theme/macarons') // echarts theme
import { mapGetters } from 'vuex'
export default {
  name: 'DashboardAdmin',

  data () {
    return {
      baseURL:process.env.VUE_APP_BASE_API,
      spinning:true,
      echartsObj
    }
  },
  
  computed: {
    ...mapGetters(['userInfo']),
    tenantName() {
      return this.userInfo?.tenantInfo?.tenantName||''
    }
  },
  created () {
  },
  mounted () {
    window.addEventListener('message',this.getiframeMsg)
  },
  methods: {
    // vue获取iframe传递过来的信息
    getiframeMsg(event){
      const res = event.data;
      // console.log(event)
      if(res.cmd == 'toRoute'){
        console.log(res)
        this.toRoute(res.params.path)
      } else if(res.cmd=='loading') {
        this.spinning = false
      }
    },
    toRoute(path) {
      console.log('跳转>>>>>>', this.$router);
      
      this.$router.push({
        path: path||this.$store.getters.defaultRoutePath
      }).catch(() => {})
      setTimeout(() => {
        this.spinning = true
      },300)
    }
  }
}
</script>
<style lang="less" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
