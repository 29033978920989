<template>
  <div class="main user-layout-register">
    <div class="main_page">
      <div class="out_head">
        PLC03
      </div>
      <div class="con">
        <div :class="immGetPub03.x0_TCXZMC ? 'active' : ''">天车行走脉冲</div>
        <div :class="immGetPub03.x1_CPSBKGSR ? 'active' : ''">车牌识别开关输入</div>
        <div :class="immGetPub03.x2_GYBGZ ? 'active' : ''">高压泵过载</div>
        <div :class="immGetPub03.x3_JT ? 'active' : ''">急停</div>
        <div :class="immGetPub03.X4_CCGD ? 'active' : ''">超出光电</div>
        <div :class="immGetPub03.x5_DWGD ? 'active' : ''">到位光电</div>
      </div>
      <div class="con">
        <div :class="immGetPub03.x6_RKGD ? 'active' : ''">入口光电</div>
        <div :class="immGetPub03.x7_XXBH ? 'active' : ''">相序保护</div>
        <div :class="immGetPub03.x12_BJ_XZBPQ ? 'active' : ''">报警(旋转变频器)</div>
        <div :class="immGetPub03.x13_BJ_QHBPQ ? 'active' : ''">报警(前后变频器)</div>
        <div :class="immGetPub03.x14_PGYD0DXH ? 'active' : ''">喷杆原点0°信号</div>
        <div :class="immGetPub03.x15_PG180DXH ? 'active' : ''">喷杆180°信号</div>
      </div>
      <div class="con">
        <div :class="immGetPub03.x16_QHJXXH ? 'active' : ''">前后极限信号</div>
        <div :class="immGetPub03.x17_TCYDXH ? 'active' : ''">天车原点信号</div>
        <div :class="immGetPub03.x21_CCBXH ? 'active' : ''">超声波信号</div>
        <div :class="immGetPub03.x26_JLMSSJJ ? 'active' : ''">卷帘门上升接近</div>
        <div :class="immGetPub03.x27_JLMXJJJ ? 'active' : ''">卷帘门下降接近</div>
        <div :class="immGetPub03.x31_GPYWBJ ? 'active' : ''">高泡液位报警</div>
      </div>
      <div class="con">
        <div :class="immGetPub03.x32_SLYWBJ ? 'active' : ''">水蜡液位报警</div>
        <div :class="immGetPub03.x33_SXYWBJ ? 'active' : ''">水箱液位报警</div>
        <div :class="immGetPub03.x34_YKQD ? 'active' : ''">遥控启动</div>
        <div :class="immGetPub03.x35_YKJT ? 'active' : ''">遥控急停</div>
        <div :class="immGetPub03.x36_YKMS1 ? 'active' : ''">遥控模式1</div>
        <div :class="immGetPub03.x37_YKMS2 ? 'active' : ''">遥控模式2</div>
      </div>
    </div>
    <div class="out_head">
      PLC04
    </div>
    <div class="con">
      <div :class="immGetPub04.y1_GYB ? 'active' : ''">高压泵</div>
      <div :class="immGetPub04.y2_QF_SL ? 'active' : ''">气阀(水蜡)</div>
      <div :class="immGetPub04.y3_DYQSB ? 'active' : ''">低压清水泵</div>
      <div :class="immGetPub04.y4_JLB_DP ? 'active' : ''">计量泵(低泡)</div>
      <div :class="immGetPub04.y5_LJB_GPQF ? 'active' : ''">计量泵/气阀(高泡)</div>
      <div :class="immGetPub04.y6_WZPDBD1 ? 'active' : ''">文字屏点播点1</div>
    </div>
    <div class="con">
      <div :class="immGetPub04.y7_JLB_SL ? 'active' : ''">计量泵(水蜡)</div>
      <div :class="immGetPub04.y10_WZPDBD2 ? 'active' : ''">文字屏点播点2</div>
      <div :class="immGetPub04.y11_FJQD ? 'active' : ''">风机启动</div>
      <div :class="immGetPub04.y12_WZPDBD3 ? 'active' : ''">文字屏点播点3</div>
      <div :class="immGetPub04.y14LSZSD ? 'active' : ''">绿色指示灯↑</div>
      <div :class="immGetPub04.y15_HSZSD ? 'active' : ''">黄色指示灯↓</div>
    </div>
    <div class="con">
      <div :class="immGetPub04.y16_HSZSD ? 'active' : ''">红色指示灯</div>
      <div :class="immGetPub04.y17_WZPDBD4 ? 'active' : ''">文字屏点播点4</div>
      <div :class="immGetPub04.y20_DBHYYY1 ? 'active' : ''">点播和语音用1</div>
      <div :class="immGetPub04.y21_DBHYYY2 ? 'active' : ''">点播和语音用2</div>
      <div :class="immGetPub04.y22_DBHYYY3 ? 'active' : ''">点播和语音用3</div>
      <div :class="immGetPub04.y23_DBHYYY4 ? 'active' : ''">点播和语音用4</div>
    </div>
    <div class="con">
      <div :class="immGetPub04.y24_DBHYYY5 ? 'active' : ''">点播和语音用5</div>
      <div :class="immGetPub04.y25_DBHYYY6 ? 'active' : ''">点播和语音用6</div>
      <div :class="immGetPub04.y26_JLMSS ? 'active' : ''">卷帘门上升</div>
      <div :class="immGetPub04.y27_JLMXJ ? 'active' : ''">卷帘门下降</div>
      <div style="background: transparent;"></div>
      <div style="background: transparent;"></div>
    </div>
  </div>
</template>

<script>
import { getDev, controlPublic, devDetail } from '@/api/device'
var client
export default {
  name: 'Register',
  // components: { videoPlayer },
  data () {
    return {
      devId: '',
      info: {},
      immGetPub03: {},
      immGetPub04: {},
      devInfo: {},
    }
  },
  computed: {},
  created () {
    let index = window.location.href.indexOf('id=')
    // console.log(window.location.href.substring(index + 3), '大大大大大')
    this.devId = window.location.href.substring(index + 3)
    // 查询详情
    this.getDetail()
    this.getDevDetail()
  },
  beforeDestroy () {
    client.end()
  },
  methods: {
    getDetail () {
      getDev({ devId: this.devId }).then(res => {
        res = res.data
        this.immGetPub03 = res.immGetPub03
        this.immGetPub04 = res.immGetPub04
        console.log(this.immGetPub03, 'immGetPub03immGetPub03immGetPub03');
      })
    },
    getDevDetail () {
      devDetail({ id: this.devId }).then(res => {
        res = res.data
        this.devInfo = res
        // this.subscribe = res.devCode + '/immGetSub01'
        this.subscribe = [res.devCode + '/web/pub/03', res.devCode + '/web/pub/04']
        console.log(this.subscribe, 'this.subscribethis.subscribethis.subscribe');
        this.connectMqtt()
      })
    },
    /**
 * @desc mqtt连接
 * @author 刘
 * @time 2023年06月02日 18:31:19 星期五
 */
    connectMqtt () {
      const that = this
      var mqtt = require('mqtt/dist/mqtt.js')
      // client = mqtt.connect('ws://emqx.zzcyi.cn:8083/bao/bao-dev')
      // const mqttUrl = 'ws://139.196.79.66:7084/bao/bao-dev'
      const mqttUrl = 'ws://139.196.79.66:7084/mqtt'
      // client = mqtt.connect('ws://47.104.102.88:8083/bao/bao-dev')
      client = mqtt.connect(mqttUrl, {
        protocolVersion: 4,
        reconnectPeriod: 100,
        connectTimeout: 30 * 1000,
        resubscribe: true,
        keepalive: 3,
        clientId:
          'mqttjs_' +
          Math.random()
            .toString(16)
            .substr(2, 8)
      })
      client.on('connect', function () {
        console.log('on connect', new Date().getTime())
        console.log('主题>>>>', that.subscribe)
        client.subscribe(that.subscribe, function (err, granted) {
          console.log('订阅主题>>>>>err>>>>', err, that.subscribe)
          console.log('订阅主题>>>>>granted>>>>', granted)
          if (!err) {
            console.log('订阅成功>>>>')
          }
        })
      }).on('reconnect', function () {
        // uni.showLoading({
        // 	title:'加载中...',
        // 	mask:true
        // })
        // setTimeout(function() {
        // 	uni.hideLoading()
        // }, 3000);
        console.log('重新连接', new Date().getTime())
      }).on('close', function (err) {
        // that.setIsMqttConnect(false)
        console.log('链接断开>>>>', err)
      }).on('error', function (err) {
        console.log('on error', err)
      }).on('end', function () {
        // that.setIsMqttConnect(false)
        console.log('on end')
      }).on('message', function (topic, message) {
        console.log('mqtt消息', message.toString())
        // alert(message.toString())
        // that.getDetail()
        let num = JSON.parse(message.toString());
        console.log(num, 'numnumnumnum====');
        if (num.topic.indexOf('/web/pub/03') !== -1) {
          that.immGetPub03 = num
          that.$forceUpdate()
        } else if (num.topic.indexOf('/web/pub/04') !== -1) {
          that.immGetPub04 = num
          that.$forceUpdate()
          console.log(that.immGetPub02, 'that.immGetimmGetPub02immGetPub02immGetPub02Pub01that.immGetPub01');
        }
        // try {
        //   const messageObj = JSON.parse(message)
        //   console.log('接受到的消息>>>', messageObj)
        //   // logType为"sendToDev"--向设备发送信息
        //   // logType为"devReply"--设备回复
        //   // logType为"devLogin"--设备上线
        //   that.setData(messageObj)
        // } catch (e) {
        //   // TODO handle the exception
        // }
      })
    },
    // 点击控制
    handleControl (e, url) {
      console.log(e, '滴滴滴滴滴滴');
      let that = this
      console.log(that.devId, ' that.devId that.devId');
      this.$confirm({
        title: '提示',
        content: e,
        onOk () {
          controlPublic(url, { devId: that.devId }).then(res => {

          })
        },
        onCancel () { },
      });
    },
    // 点击长按事件
    handleDown (e, url) {
      // 设置一个定时器，如果用户持续按住，则执行长按操作
      if (!this.open) return this.$message.error('请检查现场情况在打开锁')
      if (this.longPressTimer) {
        clearTimeout(this.longPressTimer);
        this.longPressTimer = null
      }
      this.longPressTimer = setTimeout(() => {
        // 执行长按后的操作
        // console.log('Long press triggered');
        this[e] = true
        if (e == 's3' || e == 's4' || e == 's5' || e == 's6') {
          // s3 向前 后 正 反
          controlPublics(url, { id: this.devId, open: true }).then(res => {

          })
        } else if (e == 's1') {
          // s1 升 
          controlPublic(url, { devId: this.devId, YCMSS: true }).then(res => {

          })
        } else {
          // s2 降
          controlPublic(url, { devId: this.devId, YCMXJ: true }).then(res => {

          })
        }
        // if (e == 1) {
        //   console.log(this.s1, '对对对的多多多急急急吗');
        // }
        // clearTimeout(this.longPressTimer);

      }, 800); // 长按1秒
    },
    // 结束长按
    handleUp (e, url) {
      console.log('handleUp>>>>');
      if (!this.open) return
      // 清除定时器
      clearTimeout(this.longPressTimer);
      // if (e == 1) {
      //   this.s1 = false
      // }
      if (!this[e]) return
      this[e] = false
      if (e == 's3' || e == 's4' || e == 's5' || e == 's6') {
        // s3 向前 后 正 反
        controlPublics(url, { id: this.devId, open: false }).then(res => {
        })
      } else if (e == 's1') {
        // s1 升 
        controlPublic(url, { devId: this.devId, YCMSS: false }).then(res => {

        })
      } else {
        // s2 降
        controlPublic(url, { devId: this.devId, YCMXJ: false }).then(res => {

        })
      }
      this.longPressActive = false
    },
  }
}
</script>

<style lang="less" scoped>
.out_head {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  div {
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e6f0ff;
    border-radius: 5px;
  }

  .active {
    background: #2878ff;
    color: #fff;
  }
}
</style>
