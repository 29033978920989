/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class GooglemapPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'googlemap';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '谷歌地图';
  order=0; // 排序
  description = '谷歌地图展示信息';
  tags = ['布局容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'googlemap',
    isValidateSave: true,
    'amap_key': 'AIzaSyDqd4y_RHoRVmjbrb5r9bZh6hb5ZlIgZ0M',
    'amap_width': '100%',
    'amap_height': '500px',
    'amap_is_search': true,
    'amap_is_click': true,
    'amap_is_show_more_marker': false,
    'amap_list': 'items',
    'amap_center_lat': 4.210484,
    'amap_center_lng': 101.975766,
    'amap_longitude': 'longitude',
    'amap_latitude': 'latitude',
    'amap_name': 'deviceName',
    'amap_info': 'address',
    'amap_create_time': new Date().getTime(),
    'province': 'province',
    'city': 'city',
    'area': 'area',
    'township':'township',
    // 'amap_is_show_window': false,
    // 'amap_window_dom': '<div style="padding:5px"><b>名称：${name} </b></br><b>地址：${name} </b></div>',
    // 'amap_is_show_polyline': false,
    // 'amap_polyline_list': 'items',
    // 'amap_stroke_color': '#CB1D23',
    // 'amap_stroke_thickness': 4,
    // 'amap_is_set_Clustering': false,
    // amap_marker_color: '#aa298f',
    // amap_is_set_status: false
    // body: {
    //   amap_name: '名称',
    //   amap_value: '10'
    // }

    // body: [ // 容器类字段
    //  {
    //    'type': 'googlemap',
    //    'tpl': '自定义容器区',
    //    'inline': false
    //  }
    // ]
  };
  previewSchema = { // 组件面板预览时需要
    type: 'googlemap',
    label: 'googlemap'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title
  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '常规',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_11', 'amap_12'
              ],
              body: [
                {
                  'type': 'collapse',
                  'key': 'amap_11',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'amap_key',
                      label: '地图key',
                      value: 'AIzaSyDqd4y_RHoRVmjbrb5r9bZh6hb5ZlIgZ0M',
                      // 'mode': 'inline',
                      'required': true

                    },
                    {
                      type: 'input-number',
                      name: 'amap_center_lng',
                      label: '默认经度',
                      value: '',
                      step:0.000001,
                      precision:6
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-number',
                      name: 'amap_center_lat',
                      label: '默认纬度',
                      value: '',
                      step:0.000001,
                      precision:6
                      // 'mode': 'inline'
                    }
                  ]
                }
              ]
            }

          ]
        },
        {
          title: '外观',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_21', 'amap_22', 'amap_23'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'amap_21',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'amap_width',
                      label: '宽度',
                      value: '90%'
                    },
                    {
                      type: 'input-text',
                      name: 'amap_height',
                      label: '高度',
                      value: '500px'
                    },
                    // {
                    //   type: 'input-color',
                    //   name: 'amap_marker_color',
                    //   label: '标记点颜色',
                    //   value: '#CB1D23'
                    //   // 'mode': 'inline'
                    // },
                    // {
                    //   'type': 'switch',
                    //   'label': '是否配置不同的状态颜色',
                    //   'option': '',
                    //   'name': 'amap_is_set_status',
                    //   'falseValue': false,
                    //   'trueValue': true,
                    //   'value': false,
                    //   'onText': '启用',
                    //   'offText': '禁用'
                    // },
                    // {
                    //   'type': 'input-kvs',
                    //   'name': 'amap_status_key',
                    //   'addButtonText': '新增配置',
                    //   'keyItem': {
                    //     'label': '字段',
                    //     'mode': 'horizontal',
                    //     'type': 'input-text'
                    //   },
                    //   'valueItems': [
                    //     {
                    //       'type': 'input-kv',
                    //       'label': '配置颜色',
                    //       'name': 'color',
                    //       'keyPlaceholder': '对应的值',
                    //       'valuePlaceholder': '对应的颜色',
                    //       'valueSchema': {
                    //         type: 'input-color',
                    //         name: 'color',
                    //         label: '对应的颜色',
                    //         value: '#CB1D23'
                    //         // 'mode': 'inline'
                    //       }
                    //     }
                    //   ],
                    //   visibleOn: '${amap_is_set_status}'
                    // },
                    {
                      'type': 'switch',
                      'label': '是否支持搜索',
                      'option': '',
                      'name': 'amap_is_search',
                      'falseValue': false,
                      'trueValue': true,
                      'value': true,
                      'onText': '启用',
                      'offText': '禁用'
                    },
                    {
                      'type': 'switch',
                      'label': '是否支持点击',
                      'option': '',
                      'name': 'amap_is_click',
                      'falseValue': false,
                      'trueValue': true,
                      'value': false,
                      'onText': '启用',
                      'offText': '禁用'
                    },
                    // {
                    //   'type': 'switch',
                    //   'label': '是否支持聚合',
                    //   'option': '',
                    //   'name': 'amap_is_set_Clustering',
                    //   'falseValue': false,
                    //   'trueValue': true,
                    //   'value': false,
                    //   'onText': '启用',
                    //   'offText': '禁用'
                    // }

                  ]
                }
              ]
            }
          ]
        },
        {
          title: '数据',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_31', 'amap_32'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'amap_31',
                  'header': '绑定字段',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      'type': 'switch',
                      'label': '是否显示多个标记点',
                      'option': '',
                      'name': 'amap_is_show_more_marker',
                      'falseValue': false,
                      'trueValue': true,
                      'value': false,
                      'onText': '启用',
                      'offText': '禁用'
                    },
                    {
                      type: 'input-text',
                      name: 'amap_list',
                      label: '标记点数据源',
                      value: 'items',
                      visibleOn: '${amap_is_show_more_marker}'
                      // 'mode': 'inline'
                    },
                    // {
                    //   'type': 'switch',
                    //   'label': '是否显示标记点详情',
                    //   'option': '',
                    //   'name': 'amap_is_show_window',
                    //   'falseValue': false,
                    //   'trueValue': true,
                    //   'value': false,
                    //   'onText': '启用',
                    //   'offText': '禁用'
                    // },
                    // {
                    //   type: 'ae-textareaFormulaControl',
                    //   variableMode: 'tree',
                    //   label: '文字内容',
                    //   mode: 'normal',
                    //   // visibleOn: 'data.wrapperComponent !== undefined',
                    //   pipeIn: (value, data) => value || (data && data.html),
                    //   name: 'amap_window_dom',
                    //   tpl: '<div style="padding:5px"><b>名称：${name} </b></br><b>地址：${name} </b></div>'
                    // },
                    // {
                    //   'type': 'switch',
                    //   'label': '是否添加折线',
                    //   'option': '',
                    //   'name': 'amap_is_show_polyline',
                    //   'falseValue': false,
                    //   'trueValue': true,
                    //   'value': false,
                    //   'onText': '启用',
                    //   'offText': '禁用'
                    // },
                    // {
                    //   type: 'input-text',
                    //   name: 'amap_polyline_list',
                    //   label: '折线数据源',
                    //   value: 'items',
                    //   visibleOn: '${amap_is_show_polyline}'
                    //   // 'mode': 'inline'
                    // },
                    // {
                    //   type: 'input-color',
                    //   name: 'amap_stroke_color',
                    //   label: '设置折线颜色',
                    //   value: '#CB1D23',
                    //   visibleOn: '${amap_is_show_polyline}'
                    //   // 'mode': 'inline'
                    // },
                    // {
                    //   type: 'input-number',
                    //   name: 'amap_stroke_thickness',
                    //   label: '设置折线粗细',
                    //   value: 4,
                    //   visibleOn: '${amap_is_show_polyline}'
                    //   // 'mode': 'inline'
                    // },
                    {
                      type: 'input-text',
                      name: 'amap_name',
                      label: '名称',
                      value: 'deviceName'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'province',
                      label: '省',
                      value: 'province'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'city',
                      label: '市',
                      value: 'city'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'area',
                      label: '区',
                      value: 'area'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'township',
                      label: '街道/乡镇',
                      value: 'township'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'amap_longitude',
                      label: '经度',
                      value: 'longitude'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'amap_latitude',
                      label: '纬度',
                      value: 'latitude'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'amap_info',
                      label: '地址信息',
                      value: 'address'
                      // 'mode': 'inline'
                    }

                  ]
                }
              ]
            }
          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(GooglemapPlugin)
