<template>
  <div style="width: 100%;height: 500px;" :style="{ width: amap_width, height: amap_height }">
    <!-- <div class="table-page-search-wrapper">
      <el-form layout="inline" v-show="false">
        <el-row :gutter="48">
          <el-col :span="7">
            <el-form-item :label="$t('纬度')">
              <el-input v-model="addressInfo.lat" disabled/>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('经度')">
              <el-input v-model="addressInfo.lng" disabled/>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item :label="$t('地址')">
              <el-input v-model="addressInfo.address"/>
            </el-form-item>
          </el-col>
          <el-col :span="10">
          </el-col>
        </el-row>
      </el-form>
    </div> -->
    <div style="position: relative;width: 100%;height: 100%;">
      <input v-if="amap_is_search"  type="text" id="pac-input">
      <div id="mapSearch"></div>
      <!-- <el-button class="btn" type="primary" @click="handleOk()">确定{{ amap_width }}</el-button> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'CyGoogleMap',
  data () {
    return {
      center: { lng: 114.169355, lat: 22.319253 },
      // center: {  },
      markers: [],
      geocoder: null,
      addressInfo: {}
    }
  },
  
  computed: {
    markersList() {
      console.log('计算属性>>>>amap_list>>>>', this.amap_list)
      console.log('计算属性>>>>data>>>>', this.$data.data)
      console.log('计算属性>>>>', this.$data.data[this.amap_list])
      let markers = [
            // { lng: this.center[0], lat: this.center[1], name: label }
      ]
      if (this.amap_is_show_more_marker) {
        // 如果显示多个标记点
        // console.log('init>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
        const mapList = this.$data.data[this.amap_list]
        // console.log('init>>>>>amap_list>>>>', mapList)
        if (mapList && mapList.length > 0) {
          // this.center = []

          mapList.forEach(item => {
            const lng = item[this.amap_longitude]
            // || 116.396304
            const lat = item[this.amap_latitude]
            // || 39.909847
            const label = item[this.amap_name]
            const address = item[this.amap_info]

            if (lng && lat) {
              // if (this.center.length === 0) {
              //   this.center = [lng, lat]
              // }
              markers.push(
                { lng: lng, lat: lat, address:address, name: label, info: item }
              )
            }
          })
          // if (this.center.length === 0) {
          //   this.center = [116.396304, 39.909847]
          // }
        }
      } else {
        const lng = this.$data.data[this.amap_longitude]
        // || 116.396304
        const lat = this.$data.data[this.amap_latitude]
        // || 39.909847
        const label = this.$data.data[this.amap_name]
        const address = this.$data.data[this.amap_info]
        // console.log('lng>>>>>>', lng)

        if (lng && lat) {
          // this.center = [lng, lat]
          markers = [
            { lng: lng, lat: lat, name: label,address:address, info: this.data }
          ]
        } else {
          // this.center = [116.396304, 39.909847]
        }
      }
      return markers
    }
  },
  watch: {
    markersList: {
      deep: true,
      handler() {
        this.updatedMap()
      }
    }
  },
  mounted () {
    // this.mapInit() // 初始化实例之后调用
    // 添加script
    console.log('添加script>>>>>>');

    this.addScript()
  },
  // 方法
  methods: {
    
    updatedMap() {
      let markersArr = JSON.parse(JSON.stringify(this.markersList))
      console.log('updatedMap>>>>>>', this.markers)
      if(this.map) {
        markersArr.forEach(item => {
          this.addMarker(item.lat,item.lng,item.address,true)
        })

        this.map.panTo(this.markers[0].getPosition())
      }
      // this.getMap()
      // // 创建标注点
      // this.createMarkers()
      // // // 设置折线
      // this.setPolyline()
    },
    setValidData(key, value) {
      console.log('setValidData>>>>>>key>>>>>',key);
      console.log('setValidData>>>>>>value>>>>>',value);
      
      if (this.store.storeType === 'ServiceStore') { // 上级作用域为顶层作用域或者Service
        this.store.reInitData({ [key]: value })
      } else if (this.store.storeType === 'FormStore') { // 上级作用域为表单
        this.store.setValueByName(key, value)
      }
    },
    /**
     * @desc 添加脚本
     * @param {  } 
     * @version: 1.0.0
     */
    addScript() {
      // 添加脚本请求
      console.log('添加脚本请求>>>>>>',this.amap_center_lat);
      console.log('添加脚本请求>>>>>>',this.amap_center_lng);
      
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://maps.google.com/maps/api/js?key='+this.amap_key+'&language=zh&libraries=places'; // 替换为你的脚本URL
      document.head.appendChild(script);
      
      // 如果需要在脚本加载后执行操作，可以监听load事件
      script.onload = () => {
        console.log('Script loaded successfully.');
        // 这里可以执行依赖于该脚本的代码
        if(this.amap_center_lat&&this.amap_center_lng) {
          this.mapInit(Number(this.amap_center_lat),Number(this.amap_center_lng))
        }else{
          this.mapInit()
        }
      };
    },
    handleOk () {
      if (this.addressInfo.lat && this.addressInfo.address) {
        this.$emit('ok', this.addressInfo)
      } else {
        this.$message.warning(this.$t('请在地图选择位置'))
      }
    },
    mapInit (lat, lng, address) {
      console.log('地图初始化>>>>>>>mapInit>>>>>>lat>>>>>',lat);
      console.log('地图初始化>>>>>>>mapInit>>>>>>lng>>>>>',lng);
      
      console.log('地图初始化', 'geolocation' in navigator)
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log('getCurrentPosition>>>>>1>>>>',position);
          console.log('getCurrentPosition>>>>>1>>>>',document.getElementById('mapSearch'));
          
          this.map = new google.maps.Map(document.getElementById('mapSearch'), {
            zoom: 10,
            center: { lng: lng||position.coords.longitude, lat: lat||position.coords.latitude },
            mapTypeId: google.maps.MapTypeId.ROADMAP
          })
          this.geocoder = new google.maps.Geocoder()
          this.mapClick()
          this.mapSearch()
          if(lat && lng && address) this.addMarker(lat, lng, address)
        }, () => {
          console.log('getCurrentPosition>>>>>2>>>>',document.getElementById('mapSearch'));

          this.map = new google.maps.Map(document.getElementById('mapSearch'), {
            zoom: 10,
            center: lat&&lng?{lng,lat}:this.center,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          })
          this.geocoder = new google.maps.Geocoder()
          this.mapClick()
          this.mapSearch()
          if(lat && lng && address) this.addMarker(lat, lng, address)
        })
      } else {
        this.map = new google.maps.Map(document.getElementById('mapSearch'), {
          zoom: 10,
          center: lat&&lng?{lng,lat}:this.center,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        })
        this.geocoder = new google.maps.Geocoder()
        this.mapClick()
        this.mapSearch()
        if(lat && lng && address) this.addMarker(lat, lng, address)
      }
    },
    // 监听地图点击事件
    mapClick () {
      if(!this.amap_is_click) {
        return
      }
      this.map.addListener('click', (mapsMouseEvent) => {
        this.placeMarker(mapsMouseEvent.latLng)
      })
    },
    // 搜索输入框
    mapSearch () {
      if(!this.amap_is_search) {
        return
      }
      const input = document.getElementById('pac-input')
      const searchBox = new google.maps.places.SearchBox(input)
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds())
      })
      this.markers = []
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()

        if (places.length === 0) {
          return
        }
        // Clear out the old markers.
        this.clearOverlays()
        this.markers = []
        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds()
        // places.forEach((place) => {
        //   console.log('place', place)
        //   if (!place.geometry || !place.geometry.location) {
        //     console.log('Returned place contains no geometry')
        //     return
        //   }
        //   const icon = {
        //     url: place.icon,
        //     size: new google.maps.Size(71, 71),
        //     origin: new google.maps.Point(0, 0),
        //     anchor: new google.maps.Point(17, 34),
        //     scaledSize: new google.maps.Size(25, 25)
        //   }
        //   // Create a marker for each place.
        //   this.markers.push(
        //     new google.maps.Marker({
        //       map: this.map,
        //       icon,
        //       title: place.name,
        //       position: place.geometry.location
        //     })
        //   )
        //   if (place.geometry.viewport) {
        //     bounds.union(place.geometry.viewport)
        //   } else {
        //     bounds.extend(place.geometry.location)
        //   }
        //   if (this.geocoder) {
        //     this.geocoder.geocode({
        //       'location': place.geometry.location
        //     }, (results, status) => {
        //       console.log('results', results, status)
        //       if (status === google.maps.GeocoderStatus.OK) {
        //         if (results[0]) {
        //           // this.addressInfo = {
        //           //   address: place.formatted_address,
        //           //   lat: results[0].geometry.location.lat(),
        //           //   lng: results[0].geometry.location.lng()
        //           // }

        //           let address_components = results[0].address_components
        //           let province = ''
        //           let city = ''
        //           let district = ''
        //           let township = ''
        //           address_components.forEach((item,index) => {
        //             if(item.types.includes('route')) {
        //               // 街道/乡镇
        //               township = item.long_name
        //             }

        //             if(item.types.includes('sublocality_level_1')||(item.types.includes('locality')&&index==2)) {
        //               // 区/县
        //               district = item.long_name
        //             }

        //             if(item.types.includes('administrative_area_level_2')||(item.types.includes('locality')&&index==3)) { 
        //               // 市
        //               city = item.long_name
        //             }

        //             if(item.types.includes('administrative_area_level_1')) { 
        //               // 省/州
        //               province = item.long_name
        //             }

        //           })
        //           this.addressInfo = {
        //             address: results[0].formatted_address,
        //             lat: results[0].geometry.location.lat(),
        //             lng: results[0].geometry.location.lng(),
        //             province: province,
        //             city: city,
        //             district: district,
        //             township:township
        //           }
        //           this.setValidDataHandle(this.addressInfo)
        //           console.log(this.addressInfo)
        //         }
        //       }
        //     })
        //   }
        // })
        
        let place = places[0]
        console.log('place', place)
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry')
          return
        }
        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        }
        // Create a marker for each place.
        this.markers.push(
          new google.maps.Marker({
            map: this.map,
            // icon,
            title: place.name,
            position: place.geometry.location
          })
        )
        console.log('place.geometry.location>>>>>>>>>>>>',place.geometry.location.lat(),place.geometry.location.lng());
        
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
        if (this.geocoder) {
          this.geocoder.geocode({
            'location': place.geometry.location
          }, (results, status) => {
            console.log('results', results, status)
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                // this.addressInfo = {
                //   address: place.formatted_address,
                //   lat: results[0].geometry.location.lat(),
                //   lng: results[0].geometry.location.lng()
                // }

                let address_components = results[0].address_components
                let province = ''
                let city = ''
                let district = ''
                let township = ''
                address_components.forEach((item,index) => {
                  if(item.types.includes('route')) {
                    // 街道/乡镇
                    township = item.long_name
                  }

                  if(item.types.includes('sublocality_level_1')||(item.types.includes('locality')&&index==2)) {
                    // 区/县
                    district = item.long_name
                  }

                  if(item.types.includes('administrative_area_level_2')||(item.types.includes('locality')&&index==3)) { 
                    // 市
                    city = item.long_name
                  }

                  if(item.types.includes('administrative_area_level_1')) { 
                    // 省/州
                    province = item.long_name
                  }

                })
                this.addressInfo = {
                  address: results[0].formatted_address,
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                  province: province,
                  city: city,
                  district: district,
                  township:township
                }
                this.setValidDataHandle(this.addressInfo)
                console.log(this.addressInfo)
              }
            }
          })
        }

        this.map.fitBounds(bounds)
      })
    },
    addMarker(lat, lng, address,isInit) {
      console.log(lat, lng);
      let that = this;
      this.addressInfo = {
        lat,
        lng,
        address
      }
      if(!isInit) {
        this.clearOverlays()
      }
      this.markers.push(new google.maps.Marker({
        position: {lat, lng},
        map: this.map
      }))
    },
    // 点击地图
    placeMarker (location) {
      console.log('location', location)
      let that = this;
      this.clearOverlays()
      this.markers.push(
        new google.maps.Marker({
          position: location,
          map: this.map
        })
      )
      if (this.geocoder) {
        this.geocoder.geocode({
          'location': location
        }, (results, status) => {
          console.log('results', results, status)
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              let address_components = results[0].address_components
              let province = ''
              let city = ''
              let district = ''
              let township = ''
              address_components.forEach((item,index) => {
                if(item.types.includes('route')) {
                  // 街道/乡镇
                  township = item.long_name
                }

                if(item.types.includes('sublocality_level_1')||(item.types.includes('locality')&&index==2)) {
                  // 区/县
                  district = item.long_name
                }

                if(item.types.includes('administrative_area_level_2')||(item.types.includes('locality')&&index==3)) { 
                  // 市
                  city = item.long_name
                }

                if(item.types.includes('administrative_area_level_1')) { 
                  // 省/州
                  province = item.long_name
                }

              })
              this.addressInfo = {
                address: results[0].formatted_address,
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                province: province,
                city: city,
                district: district,
                township:township
              }
              this.setValidDataHandle(this.addressInfo)
              console.log(this.addressInfo)
            }
          } else {
            // alert('Geocoder failed due to: ' + status)
          }
        })
      }
    },

    /**
     * @desc 设置数据
     * @param {  } 
     * @version: 1.0.0
     */
    setValidDataHandle(addressInfo){
      let that = this;
      if(that.$schema.amap_info) {
        that.setValidData(that.$schema.amap_info, addressInfo.address)
      }
      
      if(that.$schema.amap_longitude) {
        that.setValidData(that.$schema.amap_longitude, addressInfo.lng)
      }

      if(that.$schema.amap_latitude) {
        that.setValidData(that.$schema.amap_latitude, addressInfo.lat)
      }


      if(that.$schema.province) {
        that.setValidData(that.$schema.province, addressInfo.province)
      }

      if(that.$schema.city) {
        that.setValidData(that.$schema.city, addressInfo.city || addressInfo.province)
      }

      if(that.$schema.area) {
        that.setValidData(that.$schema.area, addressInfo.district)
      }

      if(that.$schema.township) {
        that.setValidData(that.$schema.township, addressInfo.township)
      }

      that.setValidData('amapDetailInfo', addressInfo.addressComponent)
    },
    // 清除点
    clearOverlays () {
      if (this.markers.length > 0) {
        this.markers.forEach((marker) => {
          marker.setMap(null)
        })
      }
    }
  }
}
</script>

<style lang='less' scoped>
#mapSearch {
  width: 100%;
  height: 500px;
}

#pac-input {
  width: 70%;
	margin-top: 10px;
	border: 1px solid transparent;
	border-radius: 2px 0 0 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
  padding: 0 10px;
  font-size: 15px;
	height: 40px;
	outline: none;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.btn {
  position: absolute;
  height: 40px;
  right: 110px;
  top: 10px;
}
</style>
