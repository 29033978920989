/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-servertable';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamserverTable';
  order = 0; // 排序
  description = '自定义servertable';
  tags = ['数据容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = {
    'type': 'service',
    'body': [
      {
        'type': 'form',
        'id': 'u:acdec8941557',
        'title': '',
        'mode': 'normal',
        'dsType': 'api',
        'feat': 'View',
        'body': [
          {
            'type': 'input-text',
            'label': '名字',
            'name': 'userName',
            'id': 'u:04476788dd55',
            'themeCss': {
              'labelClassName': {
                'font:default': {
                  'font-family': '思源-Regular'
                }
              }
            }
          }
        ],
        'actions': [],
        'static': false,
        'initApi': '',
        'className': 'no-border zzcy-form',
        'panelClassName': 'no-border b-white',
        'visible': true,
        'columnCount': 5,
        'wrapWithPanel': false
      },
      {
        'type': 'button',
        'label': '查询',
        'onEvent': {
          'click': {
            'actions': [
              {
                'componentId': 'u:01e61ff5ddd7',
                'ignoreError': false,
                'actionType': 'reload'
              }
            ]
          }
        },
        'id': 'u:f764ab0da50c',
        'className': 'zzcybtn zzcybtn-primary',
        'level': 'primary'
      },
      {
        'type': 'button',
        'label': '重置',
        'onEvent': {
          'click': {
            'actions': [
              {
                'componentId': 'u:acdec8941557',
                'ignoreError': false,
                'actionType': 'reset'
              }
            ]
          }
        },
        'id': 'u:488b5a77a536',
        'className': 'zzcybtn zzcybtn-info',
        'level': 'default',
        'themeCss': {
          'className': {
            'padding-and-margin:default': {
              'marginLeft': '10px'
            }
          }
        }
      },
      {
        'type': 'table',
        'columns': [
          {
            'label': '列信息',
            'name': 'a',
            'type': 'text',
            'id': 'u:ea10d5d6c896'
          },
          {
            'type': 'text',
            'id': 'u:df66e2781ae1',
            'label': '用户名称',
            'name': 'name'
          }
        ],
        'id': 'u:af245b978d54',
        'tableClassName': 'zzcy-table',
        'className': 'm-t-sm',
        'footer': []
      }
    ],
    'id': 'u:01e61ff5ddd7',
    'dsType': 'api'
  };
  previewSchema = {
    'type': 'service',
    'body': [
      {
        'type': 'form',
        'id': 'u:acdec8941557',
        'title': '',
        'mode': 'normal',
        'dsType': 'api',
        'feat': 'View',
        'body': [
          {
            'type': 'input-text',
            'label': '名字',
            'name': 'userName',
            'id': 'u:04476788dd55',
            'themeCss': {
              'labelClassName': {
                'font:default': {
                  'font-family': '思源-Regular'
                }
              }
            }
          }
        ],
        'actions': [],
        'static': false,
        'initApi': '',
        'className': 'no-border zzcy-form',
        'panelClassName': 'no-border b-white',
        'visible': true,
        'columnCount': 5,
        'wrapWithPanel': false
      },
      {
        'type': 'button',
        'label': '查询',
        'onEvent': {
          'click': {
            'actions': [
              {
                'componentId': 'u:01e61ff5ddd7',
                'ignoreError': false,
                'actionType': 'reload'
              }
            ]
          }
        },
        'id': 'u:f764ab0da50c',
        'className': 'zzcybtn zzcybtn-primary',
        'level': 'primary'
      },
      {
        'type': 'button',
        'label': '重置',
        'onEvent': {
          'click': {
            'actions': [
              {
                'componentId': 'u:acdec8941557',
                'ignoreError': false,
                'actionType': 'reset'
              }
            ]
          }
        },
        'id': 'u:488b5a77a536',
        'className': 'zzcybtn zzcybtn-info',
        'level': 'default',
        'themeCss': {
          'className': {
            'padding-and-margin:default': {
              'marginLeft': '10px'
            }
          }
        }
      },
      {
        'type': 'table',
        'columns': [
          {
            'label': '列信息',
            'name': 'a',
            'type': 'text',
            'id': 'u:ea10d5d6c896'
          },
          {
            'type': 'text',
            'id': 'u:df66e2781ae1',
            'label': '用户名称',
            'name': 'name'
          }
        ],
        'id': 'u:af245b978d54',
        'tableClassName': 'zzcy-table',
        'className': 'm-t-sm',
        'footer': []
      }
    ],
    'id': 'u:01e61ff5ddd7',
    'dsType': 'api'

  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
