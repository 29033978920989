<template>
  <div>
    <a-popover
      v-model="visible"
      trigger="click"
      placement="bottom"
      overlayClassName="header-notice-wrapper companiesPopover"
      :getPopupContainer="() => $refs.languageRef.parentElement"
      :autoAdjustOverflow="true"
      :arrowPointAtCenter="true"
      :overlayStyle="{width:'174px' }"
    >
      <template slot="content">
        <a-spin :spinning="loading">
          <div class="companiesLi" v-for="(item,key0) in languageList" :key="key0" @click="chooseCompanie(item)">
            <a-tooltip placement="bottom">
              <template slot="title">
                {{ item.name }}
              </template>
              <span class="font16R name" :class="currentLang==item.value?'activeLang':''">{{ item.name }}</span>
              <img v-if="currentLang!=item.value" style="height: 18px;" src="@/assets/login/7.png">
              <img v-else style="height: 18px;" src="@/assets/login/8.png">

            </a-tooltip>

          </div>
        </a-spin>
      </template>
      <img v-if="place=='header'" ref="languageRef" style="height: 28px;margin-left: -12px;margin-right: -12px;" src="@/assets/topBtn/5.png" @click="visible=!visible">
      <div v-else class="lang-box" ref="languageRef" @click="visible=!visible">
        <img style="height: 18px;margin-right: 9px;" src="@/assets/login/1.png">
        <span class="font16M" style="margin-right: auto;">{{ languageName }}</span>
        <img v-if="!visible" style="height: 14px;margin-right: 9px;" src="@/assets/login/2.png">
        <img v-else style="height: 14px;margin-right: 9px;" src="@/assets/login/6.png">

      </div>
    </a-popover>

  </div>

</template>

<script>
import i18nMixin from '@/store/i18n-mixin'
export default {
  mixins: [i18nMixin],
  props: {
    place: {
      type: String,
      default: 'header'
    }
  },
  data () {
    return {
      visible: false,
      isFullscreen: false,
      companiesId: 1,
      loading: false,
      languageList: [
        {
          value: 'zh-CN',
          name: '简体中文'
        },
        {
          value: 'en-US',
          name: 'English'
        }
      ]
    }
  },
  computed: {
    languageName() {
      let text = ''
      this.languageList.forEach(item => {
        if (item.value === this.currentLang) {
          text = item.name
        }
      })
      return text
    }
  },
  mounted () {
  },
  created() {
    console.log('currentLang>>>>', this.currentLang)

    // this.chooseCompanie(this.companiesList[0])
  },
  methods: {
    chooseCompanie(item) {
      this.setLang(item.value)
      this.visible = false
      window.location.reload()
    }
  }
}
</script>

<style lang="less" scoped>

.lang-box {
  display: flex;
  align-items: center;
  width: 173px;
  height: 52px;
  padding: 0 14px 0 15px;
  background: #FFFFFF;
  border-radius: 32px 32px 32px 32px;
  opacity: 1;
  cursor: pointer;
}
.companiesLi {
  width: 100%;
  text-align: left;
  padding: 25px 15px 25px 21px;

  cursor: pointer;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  >span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .name {
    color: #09080D;
    &.activeLang {
      color: #2878FF;
    }
  }

}

/deep/.companiesPopover {
  .ant-popover-inner {
    padding: 8px 0 9px 0;
    border-radius: 25px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}

</style>
