/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '意见反馈列表';
  order=0; // 排序
  description = '意见反馈列表展示信息';
  tags = ['布局容器', '驾驶舱', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'className': 'p-1',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'id': 'u:7415db0a0953',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '待处理意见反馈',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:f6e132638397',
                    'style': {
                      'boxShadow': ' 0px 0px 0px 0px transparent'
                    }
                  },
                  {
                    'type': 'icon',
                    'icon': 'fas fa-sync-alt',
                    'vendor': '',
                    'id': 'u:c999ad519e43',
                    'themeCss': {
                      'className': {
                        'padding-and-margin:default': {
                          'marginTop': '0',
                          'marginRight': '0',
                          'marginBottom': '0',
                          'marginLeft': '10px'
                        },
                        'font': {
                          'color': '#0052c7'
                        }
                      }
                    },
                    'className': 'className-327ff415695c'
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'boxShadow': ' 0px 0px 0px 0px transparent'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false
              },
              {
                'type': 'flex',
                'className': 'p-1',
                'items': [
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'progress',
                        'mode': 'circle',
                        'value': '${8/(8*2)*100}',
                        'strokeWidth': 10,
                        'valueTpl': "<span style='font-size:20px;color:#0052c7;'>8</span><span>项</span><br/><span>待处理</span>",
                        'id': 'u:ebde7c8ca6d7',
                        'placeholder': '-',
                        'progressClassName': 'w-lg',
                        'map': [
                          {
                            'color': '#0052c7',
                            'value': 100
                          }
                        ],
                        'gapDegree': 0,
                        'gapPosition': '',
                        'showLabel': true,
                        'menuTpl': '',
                        'className': ''
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'marginTop': '10px',
                      'marginRight': '0',
                      'marginBottom': '10px',
                      'marginLeft': '0',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:8950d83e6f76'
                  },
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'button',
                        'label': '去处理',
                        'onEvent': {
                          'click': {
                            'actions': [
                            ]
                          }
                        },
                        'id': 'u:6dd5f6367538',
                        'themeCss': {
                          'className': {
                            'radius:default': {
                              'top-left-border-radius': '15px',
                              'top-right-border-radius': '15px',
                              'bottom-right-border-radius': '15px',
                              'bottom-left-border-radius': '15px'
                            },
                            'background:default': 'rgba(0, 82, 199, 0.06)',
                            'border:default': {
                              'top-border-style': 'solid',
                              'left-border-style': 'solid',
                              'right-border-style': 'solid',
                              'bottom-border-style': 'solid',
                              'border': '1px solid #0052c7'
                            },
                            'font:default': {
                              'color': '#0052c7'
                            }
                          }
                        },
                        'className': 'className-a1b0634f31d1 className-6dd5f6367538',
                        'level': 'default'
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:c4cc4f3c26df'
                  }
                ],
                'style': {
                  'position': 'relative',
                  'inset': 'auto',
                  'flexWrap': 'nowrap',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'flexDirection': 'column',
                  'justifyContent': 'space-between',
                  'alignItems': 'center',
                  'height': '100%',
                  'overflowY': 'visible'
                },
                'id': 'u:1745c8ae03a4',
                'isFixedHeight': true,
                'isFixedWidth': false
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '0 0 150px',
              'flexBasis': '369px',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexDirection': 'column',
              'overflowX': 'visible',
              'right-border-style': 'dashed'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:ec166e375ca7',
            'className': 'b-r'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'crud',
                    'syncLocation': false,
                    'api': {
                      'method': 'get',
                      'url': '/gather/device/info/page?pageNum=1&pageSize=10'
                    },
                    'columns': [
                      {
                        'name': 'createTime',
                        'label': '时间',
                        'type': 'date',
                        'id': 'u:34aad8704fd7'
                      },
                      {
                        'name': 'deviceName',
                        'label': '问题详情',
                        'type': 'text',
                        'id': 'u:737f123c7268'
                      },
                      {
                        'type': 'text',
                        'label': '反馈用户',
                        'name': 'deviceName',
                        'id': 'u:fb86a742f9ec'
                      },
                      {
                        'type': 'operation',
                        'label': '操作',
                        'id': 'u:a62615fc4843',
                        'buttons': [
                          {
                            'type': 'button',
                            'label': '去处理',
                            'onEvent': {
                              'click': {
                                'actions': [
                                ]
                              }
                            },
                            'id': 'u:69c51e692506'
                          }
                        ],
                        'name': '驾驶舱',
                        'placeholder': '-',
                        'width': 100
                      }
                    ],
                    'bulkActions': [
                    ],
                    'itemActions': [
                    ],
                    'id': 'u:9203bd137376',
                    'perPageAvailable': [
                      10
                    ],
                    'name': '驾驶舱',
                    'messages': {
                    },
                    'perPageField': 'pageSize',
                    'pageField': 'pageNum',
                    'autoFillHeight': true,
                    'filterColumnCount': 3,
                    'alwaysShowPagination': false,
                    'showFooter': true,
                    'footerToolbar': [
                      {
                        'type': 'pagination',
                        'layout': 'perPage,pager,go',
                        'align': 'left'
                      }
                    ],
                    'className': 'h-80'
                  }
                ],
                'size': 'xs',
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'flex': '1 1 auto',
                  'flexGrow': '1',
                  'flexBasis': 'auto',
                  'boxShadow': '0px 0px 0px 0px transparent',
                  'marginTop': '10px',
                  'marginRight': '0',
                  'marginBottom': '0',
                  'marginLeft': '0'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:c8ca32ded9e5'
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 6,
              'flexBasis': 'auto',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexWrap': 'nowrap',
              'flexDirection': 'column',
              'paddingTop': '0',
              'paddingRight': '0',
              'paddingBottom': '0',
              'paddingLeft': '40px'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:e4e68b4744cf'
          }
        ],
        'style': {
          'position': 'relative',
          'inset': 'auto',
          'flexWrap': 'nowrap',
          'boxShadow': ' 0px 0px 0px 0px transparent'
        },
        'id': 'u:72f2761b4271',
        'isFixedHeight': false,
        'isFixedWidth': false
      }
    ],
    'actions': [
      {
        'type': 'button',
        'label': '',
        'actionType': 'dialog',
        'dialog': {
          'title': '标题',
          'body': '内容'
        },
        'id': 'u:ef6854368100'
      }
    ],
    'visibleOn': "${permissions=='*:*:*'||ARRAYINCLUDES(permissions,'gather:device:list')}"
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'className': 'p-1',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'id': 'u:7415db0a0953',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '待处理意见反馈',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:f6e132638397',
                    'style': {
                      'boxShadow': ' 0px 0px 0px 0px transparent'
                    }
                  },
                  {
                    'type': 'icon',
                    'icon': 'fas fa-sync-alt',
                    'vendor': '',
                    'id': 'u:c999ad519e43',
                    'themeCss': {
                      'className': {
                        'padding-and-margin:default': {
                          'marginTop': '0',
                          'marginRight': '0',
                          'marginBottom': '0',
                          'marginLeft': '10px'
                        },
                        'font': {
                          'color': '#0052c7'
                        }
                      }
                    },
                    'className': 'className-327ff415695c'
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'boxShadow': ' 0px 0px 0px 0px transparent'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false
              },
              {
                'type': 'flex',
                'className': 'p-1',
                'items': [
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'progress',
                        'mode': 'circle',
                        'value': '${8/(8*2)*100}',
                        'strokeWidth': 10,
                        'valueTpl': "<span style='font-size:20px;color:#0052c7;'>8</span><span>项</span><br/><span>待处理</span>",
                        'id': 'u:ebde7c8ca6d7',
                        'placeholder': '-',
                        'progressClassName': 'w-lg',
                        'map': [
                          {
                            'color': '#0052c7',
                            'value': 100
                          }
                        ],
                        'gapDegree': 0,
                        'gapPosition': '',
                        'showLabel': true,
                        'menuTpl': '',
                        'className': ''
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'marginTop': '10px',
                      'marginRight': '0',
                      'marginBottom': '10px',
                      'marginLeft': '0',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:8950d83e6f76'
                  },
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'button',
                        'label': '去处理',
                        'onEvent': {
                          'click': {
                            'actions': [
                            ]
                          }
                        },
                        'id': 'u:6dd5f6367538',
                        'themeCss': {
                          'className': {
                            'radius:default': {
                              'top-left-border-radius': '15px',
                              'top-right-border-radius': '15px',
                              'bottom-right-border-radius': '15px',
                              'bottom-left-border-radius': '15px'
                            },
                            'background:default': 'rgba(0, 82, 199, 0.06)',
                            'border:default': {
                              'top-border-style': 'solid',
                              'left-border-style': 'solid',
                              'right-border-style': 'solid',
                              'bottom-border-style': 'solid',
                              'border': '1px solid #0052c7'
                            },
                            'font:default': {
                              'color': '#0052c7'
                            }
                          }
                        },
                        'className': 'className-a1b0634f31d1 className-6dd5f6367538',
                        'level': 'default'
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:c4cc4f3c26df'
                  }
                ],
                'style': {
                  'position': 'relative',
                  'inset': 'auto',
                  'flexWrap': 'nowrap',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'flexDirection': 'column',
                  'justifyContent': 'space-between',
                  'alignItems': 'center',
                  'height': '100%',
                  'overflowY': 'visible'
                },
                'id': 'u:1745c8ae03a4',
                'isFixedHeight': true,
                'isFixedWidth': false
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '0 0 150px',
              'flexBasis': '369px',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexDirection': 'column',
              'overflowX': 'visible',
              'right-border-style': 'dashed'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:ec166e375ca7',
            'className': 'b-r'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'crud',
                    'syncLocation': false,
                    'api': {
                      'method': 'get',
                      'url': '/gather/device/info/page?pageNum=1&pageSize=10'
                    },
                    'columns': [
                      {
                        'name': 'createTime',
                        'label': '时间',
                        'type': 'date',
                        'id': 'u:34aad8704fd7'
                      },
                      {
                        'name': 'deviceName',
                        'label': '问题详情',
                        'type': 'text',
                        'id': 'u:737f123c7268'
                      },
                      {
                        'type': 'text',
                        'label': '反馈用户',
                        'name': 'deviceName',
                        'id': 'u:fb86a742f9ec'
                      },
                      {
                        'type': 'operation',
                        'label': '操作',
                        'id': 'u:a62615fc4843',
                        'buttons': [
                          {
                            'type': 'button',
                            'label': '去处理',
                            'onEvent': {
                              'click': {
                                'actions': [
                                ]
                              }
                            },
                            'id': 'u:69c51e692506'
                          }
                        ],
                        'name': '驾驶舱',
                        'placeholder': '-',
                        'width': 100
                      }
                    ],
                    'bulkActions': [
                    ],
                    'itemActions': [
                    ],
                    'id': 'u:9203bd137376',
                    'perPageAvailable': [
                      10
                    ],
                    'name': '驾驶舱',
                    'messages': {
                    },
                    'perPageField': 'pageSize',
                    'pageField': 'pageNum',
                    'autoFillHeight': true,
                    'filterColumnCount': 3,
                    'alwaysShowPagination': false,
                    'showFooter': true,
                    'footerToolbar': [
                      {
                        'type': 'pagination',
                        'layout': 'perPage,pager,go',
                        'align': 'left'
                      }
                    ],
                    'className': 'h-80'
                  }
                ],
                'size': 'xs',
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'flex': '1 1 auto',
                  'flexGrow': '1',
                  'flexBasis': 'auto',
                  'boxShadow': '0px 0px 0px 0px transparent',
                  'marginTop': '10px',
                  'marginRight': '0',
                  'marginBottom': '0',
                  'marginLeft': '0'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:c8ca32ded9e5'
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 6,
              'flexBasis': 'auto',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexWrap': 'nowrap',
              'flexDirection': 'column',
              'paddingTop': '0',
              'paddingRight': '0',
              'paddingBottom': '0',
              'paddingLeft': '40px'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:e4e68b4744cf'
          }
        ],
        'style': {
          'position': 'relative',
          'inset': 'auto',
          'flexWrap': 'nowrap',
          'boxShadow': ' 0px 0px 0px 0px transparent'
        },
        'id': 'u:72f2761b4271',
        'isFixedHeight': false,
        'isFixedWidth': false
      }
    ],
    'actions': [
      {
        'type': 'button',
        'label': '',
        'actionType': 'dialog',
        'dialog': {
          'title': '标题',
          'body': '内容'
        },
        'id': 'u:ef6854368100'
      }
    ],
    'visibleOn': "${permissions=='*:*:*'||ARRAYINCLUDES(permissions,'gather:device:list')}"
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项
    // {
    //   type: 'tabs',
    //   tabsMode: 'line',
    //   className: 'editor-prop-config-tabs',
    //   contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
    //   linksClassName: 'editor-prop-config-tabs-links aa',
    //   tabs: [
    //     {
    //       title: '常规',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_11', 'grid_12'
    //           ],
    //           body: [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_11',
    //               'header': '基本',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'source_data',
    //                   label: '数据源',
    //                   value: 'deviceData',
    //                   // 'mode': 'inline',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_name',
    //                   label: '子设备名称',
    //                   value: 'deviceName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_12',
    //               'header': '采集项',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_data',
    //                   label: '采集项数据',
    //                   value: 'data',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_name',
    //                   label: '名称',
    //                   value: 'attrName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_value',
    //                   label: '数值',
    //                   value: 'value',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_unit',
    //                   label: '单位',
    //                   value: 'attrUnit'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_icon',
    //                   label: '图标',
    //                   value: 'icon'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }

    //       ]
    //     },
    //     {
    //       title: '外观',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_21', 'grid_22', 'grid_23'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_21',
    //               'header': '行数量',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_num',
    //                   label: '一行显示数量',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_22',
    //               'header': '显隐/排序设置',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'switch',
    //                   'label': '状态',
    //                   'option': '',
    //                   'name': 'grid_edit',
    //                   'falseValue': false,
    //                   'trueValue': true,
    //                   'value': true,
    //                   'onText': '启用',
    //                   'offText': '禁用'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '显隐key',
    //                   'name': 'grid_check_key',
    //                   'value': 'attrId',
    //                   'hiddenOn': '${AND(!grid_edit)}'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '间距',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_margin_bottom',
    //                   label: '行间距',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_top',
    //                   label: '上边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_left',
    //                   label: '左边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_bottom',
    //                   label: '下边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_right',
    //                   label: '右边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '颜色',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'input-color',
    //                   'label': '背景色',
    //                   'name': 'grid_background',
    //                   'format': 'rgba',
    //                   'value': 'rgba(255, 255, 255, 0)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置整个区域的背景色'
    //                   },
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '名称颜色',
    //                   'name': 'grid_name_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(87, 93, 108, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置名称颜色'
    //                   },
    //                   'resetValue': 'rgb(87, 93, 108, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '数值颜色',
    //                   'name': 'grid_value_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(28, 31, 39, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置数值颜色'
    //                   },
    //                   'resetValue': 'rgb(28, 31, 39, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       title: '阈值',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_31', 'grid_32'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_31',
    //               'header': '阈值数据设置',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_data',
    //                   label: '阈值数据',
    //                   value: 'threshold'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_32',
    //               'header': '阈值显示设置',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_max',
    //                   label: '对应最大值',
    //                   value: 'max'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_min',
    //                   label: '对应最小值',
    //                   value: 'min'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '对应显示颜色',
    //                   'name': 'grid_alarm_color',
    //                   value: 'color'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]

    // }

  ];
}

registerEditorPlugin(InfoGridPlugin)
