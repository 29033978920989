<template>
  <div>
    <a-popover
      v-model="visible"
      trigger="click"
      placement="bottom"
      overlayClassName="header-notice-wrapper companiesPopover"
      :getPopupContainer="() => $refs.companiesBox.parentElement"
      :autoAdjustOverflow="true"
      :arrowPointAtCenter="true"
      :overlayStyle="{width:'119px', top: '50px' }"
    >
      <template slot="content">
        <a-spin :spinning="loading">
          <div class="companiesLi" v-for="(item,key0) in companiesList" :key="key0" @click="chooseCompanie(item)">
            <a-tooltip placement="bottom">
              <template slot="title">
                {{ item.name }}
              </template>
              <span class="font14M">{{ item.name }}</span>
            </a-tooltip>

          </div>
        </a-spin>
      </template>
      <div ref="companiesBox" class="companiesBox" @click="visible=!visible">
        <span class="name font14M">{{ companiesName }}</span>
        <img class="icon" src="@/assets/topBtn/9.png" alt="" srcset="">
        <!-- <a-select v-model="companiesId" dropdownClassName="font14M">
      <a-select-option :value="item.value" v-for="(item,key0) in companiesList" :key="key0">{{ item.name }}</a-select-option>
    </a-select> -->

      </div>

    </a-popover>
  </div>

</template>

<script>

export default {
  data () {
    return {
      visible: false,
      isFullscreen: false,
      companiesId: 1,
      companiesName: '',
      loading: false,
      companiesList: [
        {
          value: 1,
          name: '诚毅物诚毅物诚毅物诚毅物'
        },
        {
          value: 2,
          name: '诚毅'
        }
      ]
    }
  },
  mounted () {
  },
  created() {
    this.chooseCompanie(this.companiesList[0])
  },
  methods: {
    chooseCompanie(item) {
      this.companiesId = item.value
      this.companiesName = item.name
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.companiesBox {
  display: flex;
  align-items: center;
  width: 119px;
  height: 34px;
  padding: 0 9px 0 10px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1.5px solid #C9C9C9;
  box-sizing: border-box;
  cursor: pointer;

  .name {
    width: 100%;
    color: #333;
    text-align: center;
    -webkit-line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
  }

  .icon {
    width: 14px;
    height: 14px;
  }
  // margin-right: 4px;
  // margin-left: 4px;
  /deep/.ant-select-selection-selected-value {
    font-size: 14px;
    font-weight: Medium;
    font-family: '思源-Medium';
  }
  /deep/.ant-select-selection {
    border-color:#C9C9C9;
  }
  /deep/.ant-select-selection:hover,/deep/.ant-select-selection:active {
    border-color:#C9C9C9;
  }
  /deep/.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border-color:#C9C9C9;
  }
  /deep/.ant-select{
    width: 119px;
  }
  /deep/ .ant-select-arrow {
    font-size: 14px;
  }
}

.companiesLi {
  width: 100%;
  line-height: 30px;
  text-align: center;

  cursor: pointer;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  &:hover {
    color: #2878FF;
  }
}

// .companiesPopover {
//   .ant-popover-inner-content {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }
</style>
