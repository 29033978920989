import request from '@/utils/request'

// 查询首页地图网点
export function qryFieldInfo (query) {
  return request({
    url: '/api/bigscreen/qryFieldInfo',
    method: 'get',
    params: query
  })
}

// 数据场地数
export function getFixationData (query) {
  return request({
    url: '/share/share-site/siteCount',
    method: 'get',
    params: query
  })
}

// 收益统计
export function getChangeData (query) {
  return request({
    url: '/share/order/profit',
    method: 'get',
    params: query
  })
}
//用户统计
export function adminUserStatistics (query) {
  return request({
    url: '/biz/user/userCount',
    method: 'get',
    params: query
  })
}

//大屏-充电桩分布图
export function pie (query) {
  return request({
    url: '/gather/device/info/pie',
    method: 'get',
    params: query
  })
}
export function pmapie (query) {
  return request({
    url: '/gather/device/info/map',
    method: 'get',
    params: query
  })
}
