<template>
  <div class="menue-left" style="background: #2878FF !important;" @mouseenter="mouseState='in'" @mouseleave="mouseState='out'">
    <MenuTop @searchChange="searchChange"></MenuTop>
    <div v-if="defaultSettings.leftMenuType=='default'" class="menue-left-box">
      <ul class="menue-left-box-ul" v-for="(item,key0) in menusList" :key="key0">
        <div class="menue-left-box-ul-title" @click="shousuoHandle(item,key0)" :title="$t(item?.meta?.title)">
          <!-- width: 16px; -->
          <img
            style="height: 21px;margin-right: 10px;"
            v-if="menusArr.includes(item?.meta?.title)"
            :src="require(`@/assets/menu/${menusArr.indexOf(item?.meta?.title)+1}.png`)"
            alt=""
            srcset="">
          <a-icon v-else-if="(typeof item.meta.icon == 'object')" slot="prefix" :component="item.meta.icon" style="font-size: 18px;color: #fff;margin-right: 10px;"/>
          <a-icon v-else slot="prefix" :type="item.meta.icon" style="font-size: 18px;color: #fff;margin-right: 10px;"/>
          <span class="font16B">{{ $t(item?.meta?.title) }}</span>
        </div>
        <div v-if="!item.shousuo" class="menue-left-box-ul-children">
          <router-link
            class="router-link"
            active-class="active-router-link"
            v-for="(itemChild,key1) in item.childrenMenu"
            :key="key1"
            :to="'/'+itemChild?.path"
            :title="$t(itemChild?.meta?.title)">
            <span class="router-link-title font14M">
              {{ $t(itemChild?.meta?.title) }}
            </span>
          </router-link>

        </div>
      </ul>
    </div>
    <div v-if="defaultSettings.leftMenuType=='unfold'" class="menue-left-box  menue-unfold">
      <ul class="menue-left-box-ul" v-for="(item,key0) in menusList" :key="key0">
        <div class="menue-left-box-ul-title" @click="shousuoHandle(item,key0)" :title="$t(item?.meta?.title)">
          <!-- width: 16px; -->
          <div style="display: flex;align-items: center;">
            <img
              style="height: 21px;margin-right: 10px;"
              v-if="menusArr.includes(item?.meta?.title)"
              :src="require(`@/assets/menu/${menusArr.indexOf(item?.meta?.title)+1}.png`)"
              alt=""
              srcset="">
            <a-icon v-else-if="(typeof item.meta.icon == 'object')" slot="prefix" :component="item.meta.icon" style="font-size: 18px;color: #fff;margin-right: 10px;"/>
            <a-icon v-else slot="prefix" :type="item.meta.icon" style="font-size: 18px;color: #fff;margin-right: 10px;"/>
            <span class="font16B">{{ $t(item?.meta?.title) }}</span>
          </div>
          <div style="display: flex;align-items: center;">
            <a-icon v-if="!item.shousuo" type="down" style="color: #fff;font-size: 12px;"/>
            <a-icon v-else type="up" style="color: #fff;font-size: 12px;" />
          </div>
        </div>
        <div v-if="!item.shousuo" class="menue-left-box-ul-children">
          <router-link
            class="router-link"
            active-class="active-router-link"
            v-for="(itemChild,key1) in item.childrenMenu"
            :key="key1"
            :to="'/'+itemChild?.path"
            :title="$t(itemChild?.meta?.title)">
            <span class="router-link-title font14M">
              {{ $t(itemChild?.meta?.title) }}
            </span>
          </router-link>

        </div>
      </ul>
    </div>

    <div v-if="defaultSettings.leftMenuShrink" class="menue-left-icon" :class="mouseState=='out'?'mouse-out':'mouse-in'" @click="setMenuStatus" @mouseenter="mouseState='in'">
      <img
       class="menue-left-icon-hidden"
        :src="hiddenImg"
      />
      <img
       class="menue-left-icon-open"
        :src="openImg"
      />
    </div>
  </div>
</template>

<script>
import MenuTop from '../MenuTop'
import Icon from 'ant-design-vue/es/icon'
import defaultSettings from '@/config/defaultSettings';
import hiddenImg from './img/hidden.png'
import openImg from './img/open.png'
export default {
  components: {
    MenuTop,
    Icon
  },
  props: {
    menus: {
      type: Array,
      require: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      searchMenu: '',
      menusArr: ['驾驶舱', '用户管理', '场地管理', '设备管理', '财务管理', '运营中心', '运维工单', '系统设置'],
      defaultSettings,
      hiddenImg,
      openImg,
      mouseState:'out'
    }
  },
  computed: {
    menusList() {
      const list = []
      this.menus.forEach(item => {
        if (!item.hidden) {
          let isSearchResult = false // 是否有搜索出结果
          if (item.children) {
            const children = item.children.filter((mapItem) => {
              if (!mapItem.hidden) {
                // 如果用户使用搜索菜单
                if (this.searchMenu) {
                  // 如果这个一级菜单下有搜索结果
                  if (mapItem?.meta?.title.indexOf(this.searchMenu) !== -1||this.$t(mapItem?.meta?.title).indexOf(this.searchMenu) !== -1) {
                    isSearchResult = true
                    return mapItem
                  }
                } else {
                  return mapItem
                }
              }
            })
            item['childrenMenu'] = children
          } else {
            item['childrenMenu'] = []
          }

          // 如果用户使用搜索菜单
          if (this.searchMenu) {
            // 如果这个一级菜单下有搜索结果
            if (isSearchResult) {
              list.push(item)
            }
          } else {
            list.push(item)
          }
        }
      })
      console.log('list>>>>', list)

      return list
    }
  },
  created() {
    console.log('菜单>>>>>', this.menus)
    console.log('菜单>>>>>menusList>>>>', this.menusList)
  },
  methods: {
    // 搜索菜单
    searchChange(name) {
      this.searchMenu = name
    },
    // 点击收缩
    shousuoHandle(item, key0) {
      // item['shousuo'] = !item?.shousuo
      if (item.shousuo) {
        this.$set(item, 'shousuo', false)
      } else {
        this.$set(item, 'shousuo', true)
      }
    },
    setMenuStatus() {
      this.$store.dispatch('setMenuHidden',!this.menuHidden)
    }
  }

}
</script>

<style lang="less" scoped>

.menue-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 210px;
  padding: 11px 0 0;
  // height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #2878FF !important;
  z-index: 1100;
  .menue-left-box {
    flex: 1;
    padding: 0 16px;
    overflow-y: auto;

    .menue-left-box-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin-bottom: 8px;
      .menue-left-box-ul-title {
        display: flex;
        align-items: center;
        padding-left: 16px;
        margin-bottom: 10px;
        cursor: pointer;

        span {
          color: #FFFFFF;
        }
      }

      .menue-left-box-ul-children {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        transition: all 3s;

        .router-link {
          width: 50%;
          // height: 30px;
          // line-height: 30px;
          // padding-top: 1px;
          padding-left: 16px;
          padding-top: 8px;
          padding-bottom: 8px;
          text-align: left;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          color: #FFFFFF;
          margin-bottom: 8px;

          .router-link-title {
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            line-height: 1;
          }

        }

        .active-router-link {
          background: #FFFFFF;

          .router-link-title {
            color: #2878FF;
          }

        }
      }

    }

    &.menue-unfold {
      padding: 0;
      .menue-left-box-ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        margin-bottom: 8px;
        .menue-left-box-ul-title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 32px;
          padding-right: 16px;
          margin-bottom: 10px;
          cursor: pointer;

          span {
            color: #FFFFFF;
          }
        }

        .menue-left-box-ul-children {
          flex-direction: column;

          .router-link {
            width: 100%;
            padding-left: 63px;
            padding-right: 16px;
            padding-top: 12px;
            padding-bottom: 12px;
          }

          .active-router-link {
            background: #2f71e1;

            .router-link-title {
              // color: #2878FF;
              color: #FFFFFF;
            }

          }

        }

      }
    }

   &::-webkit-scrollbar {
      display: none;
    }
  }

  .menue-left-icon {
    display:flex;
    justify-content:center;
    align-items:center;
    position: absolute;
    top: 40%;
    right: -22px;

    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(222, 224, 226);
    // background-color: #2878FF !important;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    width: 16px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px;
    z-index: 999999999999;
    cursor: pointer;

    img {
      width: 12px;
      &.menue-left-icon-open {
        display: none;
      }
    }

    &.mouse-in {
      visibility: visible !important;
      opacity: 1 !important;
    }

  }
}
</style>
