<template>
  <div>
    <!-- <span>菜单id：{{ info_com_menuId }}</span>
    <router-view /> -->
    <!-- <amisComponent :menuId="info_com_menuId" ></amisComponent> -->
    <!-- :permissionsProp="permissions" -->
  </div>
</template>
<script>
// import { writeDataPoint } from '@/api/amis/amis'
import amisComponent from '@/views/amis/AMisRendererComent.vue'
// import { mapGetters } from 'vuex'
export default {
  components: {
    amisComponent
  },
  data() {
    return {
    }
  },
  computed: {
    // ...mapGetters(['permissions'])
  },
  created() {
    console.log('>>>>>>6>>>>', this.$data.data)
  },
  methods: {

  }
}
</script>
