import request from '@/utils/request'
// 查询设备详情数据
export function getDev (query) {
  return request({
    url: '/bao/bao-dev/dev/one',
    method: 'get',
    params: query
  })
}
// 查询设备详情数据
export function devDetail (query) {
  return request({
    url: '/bao/bao-dev/detail',
    method: 'get',
    params: query
  })
}
// 控制模式1
export function szm1 (query) {
  return request({
    url: '/mqtt/szm1',
    method: 'get',
    params: query
  })
}
// 控制模式2
export function szm2 (query) {
  return request({
    url: '/mqtt/szm2',
    method: 'get',
    params: query
  })
}
// 复位
export function one2 (query) {
  return request({
    url: '/mqtt/one2',
    method: 'get',
    params: query
  })
}
// 停止
export function one1 (query) {
  return request({
    url: '/mqtt/one1',
    method: 'get',
    params: query
  })
}
// 一键低泡
export function ms01 (query) {
  return request({
    url: '/mqtt/ms01',
    method: 'get',
    params: query
  })
}
// 一键高泡
export function ms02 (query) {
  return request({
    url: '/mqtt/ms02',
    method: 'get',
    params: query
  })
}
// 一键水蜡
export function ms03 (query) {
  return request({
    url: '/mqtt/ms03',
    method: 'get',
    params: query
  })
}
// 一键风干
export function ms04 (query) {
  return request({
    url: '/mqtt/ms04',
    method: 'get',
    params: query
  })
}
// 一键冲水
export function ms05 (query) {
  return request({
    url: '/mqtt/ms05',
    method: 'get',
    params: query
  })
}
// 控制公共
export function controlPublic (url, query) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}
// 控制公共
export function controlPublics (url, query) {
  return request({
    url: url,
    method: 'post',
    data: query
  })
}
// 摄像头信息
export function cameraPage (query) {
  return request({
    url: '/bao/bao-dev-camera/page',
    method: 'get',
    params: query
  })
}