// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import ByMap from './byMap.vue'

registerRendererByType(ByMap, {
  type: 'bymap',
  usage: 'formitem',
  weight: 99,
  framework: 'vue',
  detectProps: ['byMap_info']
})
