<template>
  <!-- 增加修改 -->
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('用户昵称')" prop="nickName">
        <a-input v-model="form.nickName" :placeholder="$t('请输入')" :maxLength="30" />
      </a-form-model-item>
      <!-- <a-form-model-item :label="$t('项目')" prop="projectIds">
        <a-tree-select
          v-model="form.projectIds"
          tree-checkable
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          :placeholder="$t('请选择')"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item> -->
      <a-form-model-item :label="$t('手机号')" prop="phoneNumber">
        <a-input v-model="form.phoneNumber" :placeholder="$t('请输入')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('邮箱')" prop="email">
        <a-input v-model="form.email" :placeholder="$t('请输入')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户名')" prop="userName" v-if="form.id == undefined">
        <a-input v-model="form.userName" :placeholder="$t('请输入')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('密码')" prop="password" v-if="form.id == undefined">
        <a-input-password v-model="form.password" :placeholder="$t('请输入')" :maxLength="20" />
      </a-form-model-item>
      <a-form-model-item :label="$t('性别')" prop="sex">
        <a-radio-group v-model="form.sex" button-style="solid">
          <a-radio-button v-for="(d, index) in sexOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('状态')" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <!-- <a-form-model-item :label="$t('岗位')" prop="postIds">
        <a-select
          mode="multiple"
          v-model="form.postIds"
          :placeholder="$t('请选择')"
        >
          <a-select-option v-for="(d, index) in postOptions" :key="index" :value="d.id">
            {{ d.postName }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item :label="$t('角色')" prop="roleIds">
        <a-select
          v-model="form.roleIds"
          :placeholder="$t('请选择')"
        >
          <a-select-option v-for="(d, index) in roleOptions" :key="index" :value="d.id">
            {{ d.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.roleIds.includes('4')" label="所属商家" prop="fid">
        <a-select
          v-model="form.fid"
          :placeholder="$t('请选择')"
        >
          <a-select-option v-for="(d, index) in shangjiaList" :key="index" :value="d.id">
            {{ d.nickName }}-{{d.phoneNumber}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('备注')" prop="remark">
        <a-input v-model="form.remark" :placeholder="$t('请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getUser, addUser, updateUser, listUser } from '@/api/system/user'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    sexOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      replaceFields: { children: 'children', title: 'projectName', key: 'id', value: 'id' },
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 默认密码
      initPassword: undefined,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        deptId: 0,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phoneNumber: undefined,
        email: undefined,
        sex: 0,
        status: 1,
        remark: undefined,
        postIds: [],
        roleIds: [],
        projectIds: []
      },
      open: false,
      rules: {
        fid: [
          { required: true, message: this.$t('请选择'), trigger: 'blur' }
        ],
        userName: [
          { required: true, message: this.$t('用户名') + this.$t('不能为空'), trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: this.$t('用户昵称') + this.$t('不能为空'), trigger: 'blur' }
        ],
        roleIds: [
          { required: true, message: this.$t('角色') + this.$t('不能为空'), trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: this.$t('部门') + this.$t('不能为空'), trigger: 'change' }
        ],
        password: [
          { required: true, message: this.$t('密码') + this.$t('不能为空'), trigger: 'blur' },
          { min: 5, max: 20, message: this.$t('用户密码长度必须介于 5 和 20 之间'), trigger: 'blur' }
        ],
        email: [
          { required: false, message: this.$t('邮箱') + this.$t('不能为空'), trigger: 'blur' },
          {
            type: 'email',
            message: this.$t('请正确填写邮箱地址'),
            trigger: ['blur', 'change']
          }
        ],
        phoneNumber: [
          { required: true, message: this.$t('手机号') + this.$t('不能为空'), trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: this.$t('请正确填写手机号'),
            trigger: 'blur'
          }
        ]
      },
      shangjiaList: []
    }
  },
  filters: {
  },
  created () {
    this.getConfigKey('sys.user.initPassword').then(response => {
      this.initPassword = response.msg
    })
    console.log('>>>>>statusOptions>>>>', this.statusOptions)
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        deptId: undefined,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phoneNumber: undefined,
        email: undefined,
        sex: 0,
        status: 1,
        remark: undefined,
        postIds: [],
        roleIds: [],
        projectIds: []
      }
    },
    // 获取商家列表
    getShangjiaList() {
      listUser({userType: 2}).then(res => {
        this.shangjiaList = res.data
      })
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.getShangjiaList()
      this.reset()
      this.$emit('select-tree')

      getUser().then(response => {
        this.postOptions = response.data.posts
        this.roleOptions = response.data.roles
        this.open = true
        this.formTitle = this.$t('新增用户')
        this.form.password = this.initPassword
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.getShangjiaList()
      this.reset()
      this.$emit('select-tree')
      const userId = row ? row.id : ids
      getUser(userId).then(response => {
        this.form = response.data.data
        this.postOptions = response.data.posts
        this.roleOptions = response.data.roles
        this.form.postIds = response.data.postIds
        this.form.roleIds = response.data.roleIds
        this.open = true
        this.formTitle = this.$t('修改用户')
        this.form.password = ''
        
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateUser({ ...this.form, roleIds: [this.form.roleIds] }).then(response => {
              this.$message.success(
                this.$t('修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser({ ...this.form, roleIds: [this.form.roleIds] }).then(response => {
              this.$message.success(
                this.$t('新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
