// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import ChamMore from './cham-more.vue'

registerRendererByType(ChamMore, {
  type: 'cham-more',
  usage: 'renderer',
  weight: 99,
  framework: 'vue'
})
