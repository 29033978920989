export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function getQuery(name) {
  // 正则：[找寻'&' + 'url参数名字' = '值' + '&']（'&'可以不存在）
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    // 对参数值进行解码
    return decodeURIComponent(r[2])
  }
  return null
}

//
/**
 * @desc amis中替换字符串值
 * @param 字符串str,数据源对象obj
 * @version: 1.0.0
 */
export function replaceStrAmis (str, obj) {
  const regex = /\$\{([^}]+)\}/g
  let replacedStr  = ''
  if(regex.test(str)) {
    // 使用replace方法和一个函数作为替换值
    replacedStr = str.replace(regex, (match, prop) => {
      // prop 是捕获到的属性名
      return obj.hasOwnProperty(prop) ? obj[prop] : obj[match] // 如果对象有该属性，则替换；否则保留原占位符
    })
  }else{
    replacedStr = obj[str]
  }
  

  return replacedStr
}
