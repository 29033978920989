/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { registerAmisEditorPlugin } from '@/utils/amis-widget/index.esm.js'
export class InfoCardPlugin {
  // 关联渲染器名字
  rendererName = 'cham-more';
  $schema = '/schemas/UnkownSchema.json';
  // 组件名称（组件面板显示的Title）
  name = 'table展开';
  description = 'table展开收起';
  tags = ['数据容器', 'A自定义'];
  icon = 'fa fa-file-code-o';

  scaffold = {
    type: 'cham-more',
    label: 'cham-more',
    name: 'cham-more',
    'themeCss': {
      'className': {
        'font:default': {
          'color': '#2468f2'
        },
        'border:default': {
          'border': '0'
        },
        'background:hover': 'rgba(255, 44, 44, 0.06)',
        'font:hover': {
          'color': '#ff2c2c'
        },
        'border:hover': {
          'border': '0'
        },
        'background:active': 'rgba(255, 44, 44, 0.06)',
        'font:active': {
          'color': '#ff2c2c'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'className': 'className'
  };
  previewSchema = {
    type: 'custom',
    label: 'cham-more',
    body: '自定义容器区'
  };

  // 容器类组件必需字段
  regions = [
    {
      key: 'body',
      label: '内容区'
    }
  ];

  panelTitle = '配置';

  panelControls = [
    {
      type: 'tabs',
    tabs: [
      {
        title: '属性',
        controls: [
          {
            type: 'collapse',
            title: '数据',
            controls: [
              {
                type: 'textarea',
                name: 'data.title',
                label: '卡片title',
                value:
                  'amis 是一个低代码前端框架，它使用 JSON 配置来生成页面，可以减少页面开发工作量，极大提升效率。'
              },
              {
                type: 'text',
                name: 'data.backgroundImage',
                label: '展示图片',
                value:
                  'https://search-operate.cdn.bcebos.com/64c279f23794a831f9a8e7a4e0b722dd.jpg'
              },
              {
                type: 'input-number',
                name: 'data.img_count',
                label: '图片数量',
                value: 3
              },
              {
                type: 'input-number',
                name: 'data.comment_count',
                label: '评论数',
                value: 2021
              }
            ]
          }
        ]
      },
      {
        title: '外观',
        controls: [
          {
            title: '布局',
            type: 'collapse',
            controls: [
              {
                type: 'button-icon-group',
                name: 'style.display',
                label: '显示',
                value: 'block',
                options: [
                  {
                    label: '块级(block)',
                    icon: 'block',
                    value: 'block'
                  },
                  {
                    label: '行内区块(inline-block)',
                    icon: 'inlineBlock',
                    value: 'inline-block'
                  },
                  {
                    label: '行内元素(inline)',
                    icon: 'inline',
                    value: 'inline'
                  }
                ]
              },
              {
                name: 'style',
                type: 'whSet',
                label: '宽度',
                options: [
                  {
                    label: '',
                    value: 'width'
                  }
                ]
              }
            ]
          },
          {
            type: 'collapse',
            title: '边距',
            controls: {
              name: 'style.box',
              type: 'boxModel',
              label: false
            }
          }
        ]
      }
    ]
    }
  ];
}

registerAmisEditorPlugin(InfoCardPlugin)

export default InfoCardPlugin
