import request from '@/utils/request'

// 查询公告列表分页
export function pageNotice (query) {
  return request({
    url: '/system/notice/page',
    method: 'get',
    params: query
  })
}
// 查询公告列表
export function listNotice (query) {
  return request({
    url: '/system/notice/list',
    method: 'get',
    params: query
  })
}
// 查询公告详细
export function getNotice (noticeId) {
  return request({
    url: '/system/notice/' + noticeId,
    method: 'get'
  })
}

// 新增公告
export function addNotice (data) {
  return request({
    url: '/system/notice',
    method: 'post',
    data: data
  })
}

// 修改公告
export function updateNotice (data) {
  return request({
    url: '/system/notice',
    method: 'put',
    data: data
  })
}

// 删除公告
export function delNotice (noticeId) {
  return request({
    url: '/system/notice/' + noticeId,
    method: 'delete'
  })
}

// 右上角-查询消息通知分页
export function newsYsjPage (query) {
  return request({
    url: '/news/news/ysj/page',
    method: 'get',
    params: query
  })
}

// 右上角-获取消息通知详情
export function newsYsjDetail (query) {
  return request({
    url: '/news/news/ysj/detail',
    method: 'get',
    params: query
  })
}

// 右上角-获取未读数字
export function newsYsjNum (query) {
  return request({
    url: '/news/news/ysj/num',
    method: 'get',
    params: query
  })
}
