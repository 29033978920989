export default {
  '请输入邮箱smtp': 'Please enter the email smtp',
  '修改': 'modify',
  '退出登录': 'Log out and log in',
  '状态': 'state',
  '修改成功': 'Modified successfully',
  '清空数据': 'wipe data ',
  '强退': 'Forceful retreat',
  '收起': 'Put it away',
  '登录地点': 'Login location',
  '保存': 'preserve',
  '可以省略，省略后默认为邮箱账号smtp.邮箱后缀,例如：邮箱账号为：zbtech@163.com，smtp为smtp.163.com': 'It can be omitted. If omitted, it defaults to the email account smtp. Email suffix, for example: Email account is: zbtech@163.com SMTP is SMTP.163.3com',
  '用户昵称': 'User nickname',
  '清空': 'empty',
  '请求参数': 'Request parameters',
  '通知': 'notice',
  '操作信息': 'Operation information',
  '此操作将会清空所有调度日志数据项': 'This operation will clear all scheduling log data items',
  '邮箱账号不能为空': 'Email account cannot be empty',
  '添加字典类型': 'Add Dictionary Type',
  '邮件密匙ID': 'Email Key ID',
  '失败': 'fail',
  '邮件AccessKeyId': 'Email AccessionKeyId',
  '操作': 'operation',
  '系统模块': 'System modules',
  '登录信息': 'login information',
  '请输入内容': 'Please enter the content',
  '否': 'no',
  '发送邮箱账号': 'Send email account',
  '其他': 'other',
  '是': 'yes',
  '审核失败': 'Review failed',
  '列展开/排序': 'Column expansion/sorting',
  '富文本': 'Rich Text',
  '登录时间': 'login time',
  '男': 'male',
  '腾讯云': 'Tencent Cloud',
  '访问编号': 'Access number',
  '请求地址': 'Request address',
  '审核状态': 'Review status',
  '展开': 'launch',
  '刷新成功': 'Refresh successful',
  '操作日期': 'Operation date',
  '参数类型': 'Parameter type',
  '腾讯邮件': 'Tencent Email',
  '邮箱密码不能为空': 'Email password cannot be empty',
  '切为全屏': 'Switch to full screen',
  '当前选中编号为': 'The current selected number is',
  '发送邮箱smtp': 'Send email SMTP',
  '的数据': 'Data for',
  '操作人员': 'Operators',
  '删除': 'delete',
  '刷新缓存': 'Refresh cache',
  '重置': 'Reset',
  '公告': 'announcement',
  '主机': 'main engine',
  '昵称': 'nickname',
  '请输入账号': 'Please enter your account number',
  '参数主键': 'Parameter primary key',
  '此操作将会清空所有操作日志数据项': 'This operation will clear all operation log data items',
  '密钥SECRET': 'Key SECRET',
  '此操作将导出当前条件下所有数据而非选中数据': 'This operation will export all data under the current conditions instead of the selected data',
  '条': 'strip',
  '此操作将会清空所有登录日志数据项': 'This operation will clear all login log data entries',
  '审核失败原因': 'Reason for audit failure',
  '注销时间': 'Cancellation time',
  '参数名称': 'Parameter Name',
  '用户名称': 'User Name',
  '解锁': 'Unlock',
  '新增成功': 'New successfully added',
  '操作状态': 'Operation status',
  '共': 'common',
  '输入框': 'Input box',
  '女': 'female',
  '文本域': 'Text Field',
  '导出': 'export',
  '请输入': 'Please enter ',
  '取消': 'cancel',
  '不能为空': 'Cannot be empty',
  '日志编号': 'Log number',
  '系统内置': 'Built in system',
  '当前选中字典编号为': 'The currently selected dictionary number is',
  '个人设置': 'Personal settings',
  '阿里云': 'Alibaba Cloud',
  '注销原因': 'Reason for cancellation',
  '邮件RegionId': 'Email RegionId',
  '成功': 'success',
  '浏览器': 'browser',
  '字典名称': 'Dictionary Name',
  '登录地址': 'Login address',
  '审核人': 'Reviewer',
  '发送邮箱密码': 'Send email password',
  '登录状态': 'Login status',
  '添加参数': 'Add parameters',
  '待审核': 'Pending review',
  '审核时间': 'Review time',
  '查询': 'query',
  '联系电话': 'Contact phone number',
  '修改字典类型': 'Modify Dictionary Type',
  '是否确认清空?': 'Are you sure to clear it?',
  '接口信息': 'Interface information',
  '参数键值': 'Parameter key values',
  '请求方式': 'Request method',
  '操作方法': 'Operation method',
  '字典编号': 'Dictionary number',
  '生日': 'birthday',
  '参数键名': 'Parameter key name',
  '详细': 'detailed',
  '本地邮件': 'Local Mail',
  '阿里邮件': 'Alibaba Mail',
  '授权': 'authorization',
  '生成代码': 'Generate Code',
  '操作系统': 'operating system',
  '审核成功': 'Review successful',
  '使用状态': 'Usage status',
  '个人中心': 'Personal Center',
  '清除缓存': 'Clear Cache',
  '账号ID':'Account ID',
  '禁用': 'Disabled',
  '备注': 'Remarks',
  '操作模块': 'Operation module',
  '邮箱': 'mailbox',
  '启用': 'Enable',
  '操作类型': 'Operation type',
  '区域ID': 'Region ID',
  '新增': 'New addition',
  '导入': 'Import',
  '邮件AccessKeySecret': 'Email AccessiKeySecret',
  '修改参数': 'modify parameters',
  '是否确认导出?': 'Are you sure to export?',
  '查看更多': 'View more',
  '列排序需拖动名称': 'Column sorting requires dragging the name',
  '性别': 'Gender',
  '字典类型': 'Dictionary type',
  '请输入密码': 'Please enter password',
  '创建时间': 'Creation time',
  '确认删除所选中数据?': 'Are you sure to delete the selected data?',
  '操作地点': 'Operation location',
  '清空成功': 'Clear successfully',
  '删除成功': 'Delete successful',
  '提示': 'prompt',
  '确定注销并退出系统吗？': 'Are you sure to log out and exit the system?',
  '确定': 'confirm',
  '密码登录': 'Password login',
  '普朗思后台管理系统': 'T-poweress backend management system',
  '退出全屏': 'Exit Full Screen',
  '全选/不选': 'Select All/Not Select',
  '点击选择显示数据项，调整数据项顺序': 'Click to select display data items and adjust the order of data items',
  '数据写入': 'Data writing',
  '设备标识': 'Equipment identification',
  '参数': 'parameter',
  '写入值': 'Write value',
  '请选择': 'Please select ',

  '手机号码': 'Mobile phone number',
  '添加用户': 'Add user',
  '取消批量授权': 'Cancel bulk authorization',
  '返回': 'Back',
  '取消授权': 'Cancel authorization',
  '手机': 'mobile phone',
  '确认要取消该用户的角色吗?': 'Are you sure you want to cancel the role of this user?',
  '当前选中用户': 'Currently selected user',
  '取消授权成功': 'Cancel authorization successful',
  '是否取消选中用户授权数据项?': 'Do you want to uncheck the user authorization data item?',
  '角色名': 'Role Name',
  '权限字符': 'Permission Characters',
  '确认': 'Confirm',
  '停用': 'Deactivate',
  '的角色吗?': ' is it a character?',
  '更多': 'more',
  '脱敏权限': 'Desensitization permission',
  '数据权限': 'Data permissions',
  '分配用户': 'Assign Users',
  '角色编号': 'Role Number',
  '权限标识': 'Permission identification',
  '排序': 'sort',
  '确认要"': 'Confirm to"',
  '"角色吗？': '"Role?',
  '关闭': 'close',
  '异常': 'abnormal',
  '角色名称': 'Role Name',
  '角色标识': 'Role identification',
  '权限范围': 'Scope of permissions',
  '展开/折叠': 'Expand/Fold',
  '全选/全不选': 'Select All/Select None',
  '父子联动': 'Father son linkage',
  '当勾选项为空时方可切换模式，可以点击”全不选“后再次切换。': 'When the checkbox is empty, you can switch modes. You can click "uncheck all" and then switch again.',
  '全部数据权限': 'All data permissions',
  '自定义数据权限': 'Custom data permissions',
  '本部门数据权限': 'Data permissions for this department',
  '本部门及以下数据权限': 'Data permissions for this department and below',
  '仅本人数据权限': 'Only personal data permissions',
  '分配数据权限': 'Assign data permissions',
  '超级管理员角色信息 不可更改': 'Super administrator role information cannot be changed',
  "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)": "The permission characters defined in the controller, such as @ PreAuthorize (` @ ss. hasRole ('admin ') `)",
  '菜单权限': 'Menu permissions',
  '顺序': 'order',
  '添加角色': 'Add Role',
  '修改角色': 'Modify Role',
  '列名': 'Column names',
  '列名描述': 'Column Name Description',
  '是否脱敏': 'Is it desensitized',
  '脱敏方向': 'Desensitization direction',
  '从右向左': 'From right to left',
  '从左向右': 'From left to right',
  '起始位置': 'Starting position',
  '脱敏字符数': 'Desensitized characters',
  '设置脱敏权限': 'Set desensitization permissions',
  '操作成功！': 'Operation successful!',
  '选择用户': 'Select Users',
  '手机号': 'Mobile phone number',
  '请选择状态': 'Please select a status',
  '项目': 'project',
  '用户编号': 'User ID',
  '用户名': 'user name',
  '重置密码': 'reset password',
  '分配角色': 'Assign Roles',
  '的用户吗?': 'Is it a user of?',
  '密码': 'password',
  '岗位': 'post',
  '角色': 'role',
  '用户密码长度必须介于 5 和 20 之间': 'User password length must be between 5 and 20',
  '请正确填写邮箱地址': 'Please fill in your email address correctly',
  '请正确填写手机号': 'Please fill in your phone number correctly',
  '修改用户': 'Modify Users',
  '新增用户': 'New Users',
  '请输入搜索内容': 'Please enter search content',
  '新密码': 'New password',
  '确认密码': 'Confirm password',
  '请输入5-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种': 'Please enter 5-20 English letters, numbers, or symbols (excluding spaces), and at least two types of letters, numbers, and punctuation marks are required',
  '请再次输入新密码确认': 'Please enter the new password again to confirm',
  '两次输入的密码不一致': 'The passwords entered twice are inconsistent',
  '重置成功': 'Reset successful',
  '菜单名称': 'Menu Name',
  'DIY页面': 'DIY page',
  '图标': 'icon',
  '组件路径': 'Component path',
  '上级菜单': 'Superior menu',
  '菜单类型': 'Menu Type',
  '目录': 'catalogue',
  '菜单': 'menu',
  'DIY菜单': 'DIY menu',
  '按钮': 'button',
  '选择图标': 'Select icon',
  '是否外链': 'Is it an external link',
  '选择是外链则路由地址需要以`http(s)://`开头': 'If selecting an external link, the routing address needs to start with `http (s)://`',
  '路由地址': 'Routing address',
  '访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头': "The routing address to be accessed, such as 'user'. If an external network address requires internal chain access, it starts with `http (s)://`",
  '访问的组件路径，如：`system/user/index`，默认在`views`目录下': "The path to the accessed component, such as 'system/user/index', defaults to the 'views' directory",
  "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)": "The permission characters defined in the controller, such as @ PreAuthorize (` @ ss. hasPermi ('system: user: list ') `)",
  '是否显示': 'Is it displayed',
  '选择隐藏则路由将不会出现在侧边栏，但仍然可以访问': 'If you choose to hide, the route will not appear in the sidebar, but it can still be accessed',
  '选择停用则路由将不会出现在侧边栏，也不能被访问': 'If you choose to disable, the route will not appear in the sidebar and cannot be accessed',
  '是否缓存': 'Is it cached',
  '选择是则会被`keep-alive`缓存，需要匹配组件的`name`和地址保持一致，仅在多页签下有效。': "If you choose Yes, it will be cached by 'keep alive', and the 'name' and address of the matching component need to be consistent, only valid in multi page tabs.",
  '缓存': 'cache',
  '不缓存': 'Do not cache',
  '添加菜单': 'add menu',
  '修改菜单': 'Modify menu',
  '充值':'Top Up',
  '提现':'Withdraw',
  '可提现':'Can Withdraw',
  '已提现':'Withdrawn',
  '总收益':'Total Revenue',
  '操作日志':'Operation Log',
  '个人设置':'Personal Setting',
  '基本设置':'Basic Setup',
  '安全设置':'Security Settings',
  '给自己起个名字':'Give yourself a name',
  '请输入昵称':'Please enter a nickname',
  '电子邮件':'E-mail',
  '邮箱不能为空':'The mailbox cannot be empty',
  '手机号不能为空':'The phone number cannot be empty',
  '请填写手机号':'Please fill in your mobile phone number',
  '请填写手机号邮箱':'Please fill in your email address',
  '账户密码':'Account password',
  '当前密码强度':'Current password strength',
  '强':'strong',
  '修改头像':'Modify profile picture',
  '选择图片':'Select picture',
  '密码不能为空':'The password cannot be empty',
  '旧密码':'Old password',
  '请输入旧密码':'Please enter your old password',
  '请输入新密码':'Please enter your new password',
  '请确认密码':'Please confirm the password',
}
