// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import YsCamera from './ysCamera.vue'

registerRendererByType(YsCamera, {
  type: 'ys-camera',
  usage: 'renderer',
  weight: 99,
  framework: 'vue',
  detectProps: ['ys-camera_info']
})
