<template>
  <!-- <button class="antd-Button antd-Button--primary antd-Button--size-default" @click.stop="handleMore">
    {{ text }}
  </button> -->
  <a-button class="antd-Button antd-Button--primary antd-Button--size-default" v-if="domTogglerArr.length" type="primary" @click.stop="handleMore">{{ text }}</a-button>
</template>
<script>
export default {
  name: 'ChamMore',
  data() {
    return {
      domTogglerArr: [],
      text: '更多'
    }
  },
  mounted() {
    this.domTogglerArr = document.querySelectorAll('.antd-Table-SFToggler')
    if (this.domTogglerArr.length) {
      this.domTogglerArr.forEach(item => {
        item.parentNode.style.display = 'none'
      })
    }
  },
  methods: {
    handleMore() {
      console.log('点击更多事件')
      console.log(this.domTogglerArr)
      this.domTogglerArr[this.domTogglerArr.length - 1].click()
      if (this.text === '更多') {
        this.text = '收起'
      } else {
        this.text = '更多'
      }
    }
  }
}
</script>
<style lang="less" scoped>

</style>
