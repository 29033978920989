<template>
  <div
    :class="className"
    :style="{height:height,width:width}"
  />
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons') // echarts theme
// import { debounce } from '@/utils'

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '27.4vh'
    },
    title: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.initChart()
    // this.__resizeHandler = debounce(() => {
    //   if (this.chart) {
    //     this.chart.resize()
    //   }
    // }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart (val) {
      this.chart = echarts.init(this.$el)
      console.log(this.chartData, 'chartDatachartData')
      this.setOptions(this.chartData)
    },
    setOptions ({ lendData } = {}) {
      var thas = this

      this.chart.setOption({
        title: {
          text: '',
          textStyle: {
            color: '#ffffff',
            fontWeight: 'bold'
          }
        },

        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        color: ['#00FF00', '#70707B', '#1DB494', '#FF9900', '#FF0000'],
        legend: {
          left: 'left',
          top: 10,
          data: thas.chartData.timeData,
          textStyle: {
            color: '#ffffff' // 图例文字颜色
          }
        },
        // calculable: true,
        series: [
          {
            name: '充电桩',
            type: 'pie',
            radius: ['35%', '50%'],
            center: ['50%', '60%'],
            data: thas.chartData.totalMoneyData,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      })
    }
  }
}
</script>
