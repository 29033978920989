<template>
  <div>
    <a-modal
      title="输入金额"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      width="400px"
    >
      <div
        style="margin-bottom: 10px;"
        v-if="txConfing.isPayPassword==1"
      >提现密码</div>
      <a-input
        v-model="password"
        :min="0"
        style="width: 80%;"
        type="password"
        v-if="txConfing.isPayPassword==1"
      />
      <div style="margin: 10px 0;">提现金额</div>
      <a-input-number
        id="inputNumber"
        v-model="money"
        :min="0"
        style="width: 80%;"
      />
      <div style="font-size: 12px;margin-top: 10px;">
        起步提现金额{{ txConfing.leastMoney }}元,
        提现手续费{{ txConfing.fee }}元,
        每日最大提现金额{{ txConfing.dayMaxMoney }}元,</div>
    </a-modal>

    <a-dropdown
      :trigger="['click']"
      v-if="nickname"
      placement="bottomRight"
      :overlayStyle="{zIndex:9999999}"
      @visibleChange="visibleChangeHanle"
    >
      <span
        class="ant-pro-account-avatar"
        style="padding-left: 0;min-width: 152px;justify-content: flex-end;padding-right: 5px;padding-left: 5px;"
      >
        <!-- <span class="avatar_border"></span> -->
        <div
          class="nickname"
          style="width: 100%;"
        >
          <span
            class="font14M"
            style="color: #333333;line-height: 14px;margin-bottom: 6px;"
          >{{ nickname }}</span>
          <span
            class="font10M"
            style="color: #999999;line-height: 12px;"
          >{{ accountType }}</span>
        </div>
        <a-avatar
          size="small"
          style="width: 35px;height: 35px;flex-shrink: 0;"
          :src="avatar"
          class="antd-pro-global-header-index-avatar"
        />
        <!-- <img style="width: 7px;margin-left:8px" src="@/assets/homeImg/avatar_xl.png" alt="" srcset=""> -->
      </span>
      <template v-slot:overlay>

        <div class="user-info">
          <div class="user-info-top">
            <span
              class="font16M"
              style="color: #333333;"
            >{{ nickname }}</span>
            <span
              class="font14R"
              style="color: #999999;margin: 12px 0 16px;"
            >{{ $t('账号ID') }} {{ userInfo.userId }}</span>
            <div class="account-btn">
              <span class="font16R">{{ accountType }}</span>
            </div>
          </div>
          <div
            class="user-info-money"
            v-if="userInfo.account!='admin'&&balanceInfo"
          >
            <div class="user-info-money-box">
              <div class="user-info-money-box-item">
                <div class="user-info-money-box-item-price">
                  <span class="font11B">￥</span>
                  <span class="font18B">{{ balanceInfo.pendingBal||0 }}</span>
                </div>
                <span class="user-info-money-box-item-name font14R">在途余额</span>
              </div>
              <div class="user-info-money-box-item">
                <div class="user-info-money-box-item-price">
                  <span class="font11B">￥</span>
                  <span class="font18B">{{ balanceInfo.availableBal||0 }}</span>
                </div>
                <span class="user-info-money-box-item-name font14R">可提现</span>
              </div>
              <div class="user-info-money-box-item">
                <div class="user-info-money-box-item-price">
                  <span class="font11B">￥</span>
                  <span class="font18B">{{ balanceInfo.currBal||0 }}</span>
                </div>
                <span class="user-info-money-box-item-name font14R">账簿余额</span>
              </div>
            </div>
            <div class="user-info-money-btn">
              <!-- <div class="user-info-money-btn-item" @click="handleToPage('/caiwuguanli/chongzhizhongxin')">
              <span class="font14R">充值</span>
            </div> -->

              <div
                class="user-info-money-btn-item"
                @click="handleTx"
                v-if="isTx==0"
              >
                <span class="font14R">提现</span>
              </div>
            </div>
          </div>

          <!-- <div class="user-info-li" @click="handleToPage('/account/center')">
          <img src="@/assets/topBtn/10.png">
          <span class="font16R">个人中心</span>
        </div>
        <div v-hasPermi="['monitor:logininfor:list']" class="user-info-li" @click="handleToPage('/system/logininfor')">
          <img src="@/assets/topBtn/11.png">
          <span class="font16R">操作日志</span>
        </div> -->
          <div
            class="user-info-li"
            @click="handleToPage('/account/settings')"
          >
            <img src="@/assets/topBtn/12.png">
            <span class="font16R">{{$t('个人中心')}}</span>
          </div>
          <!-- <div class="user-info-li">
          <img src="@/assets/topBtn/13.png">
          <span class="font16R">平台电话</span>
        </div> -->
          <div
            class="user-info-li"
            @click="clearCachePage"
          >
            <img src="@/assets/topBtn/14.png">
            <span class="font16R">{{$t('清除缓存')}}</span>
          </div>

          <div
            class="user-info-out"
            @click="handleLogout"
          >
            <span class="font16R">{{ $t('退出登录') }}</span>
          </div>

        </div>

        <a-menu
          v-if="false"
          class="ant-pro-drop-down menu"
          :selected-keys="[]"
        >
          <a-menu-item
            v-if="menu"
            key="center"
            @click="handleToPage('/account/center')"
          >
            <a-icon type="user" />
            {{$t('个人中心')}}
          </a-menu-item>
          <a-menu-item
            v-if="menu"
            key="settings"
            @click="handleToPage('/account/settings')"
          >
            <a-icon type="setting" />

            {{$t('个人设置')}}
          </a-menu-item>
          <!-- <a-menu-item v-if="menu" key="amis" @click="handleEdit">
          <a-icon type="setting" />
          编辑器
        </a-menu-item> -->
          <a-menu-divider v-if="menu" />
          <a-menu-item
            key="logout"
            @click="handleLogout"
          >
            <a-icon type="logout" />

            {{$t('退出登录')}}
          </a-menu-item>
        </a-menu>

      </template>

    </a-dropdown>
    <span v-else>
      <a-spin
        size="small"
        :style="{ marginLeft: 8, marginRight: 8 }"
      />
    </span>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import auth from '@/plugins/auth'
import { balance, addwithdrawal, withdraw, pttxdetail } from '@/api/login'
import { EventBus } from '@/utils/event-bus.js';
export default {
  name: 'AvatarDropdown',
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      balanceInfo: null,
      visible: false,
      money: 0,
      isTx: -1,
      password: "",
      txConfing: {
        dayMaxMoney: 0,
        fee: 0,
        isPayPassword: 0,
        leastMoney: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname',
      'userInfo',
      'permissions'
    ]),
    accountType () {
      let text = '主账号'
      if (this.userInfo?.roleList?.length > 0) {
        const roleNames = []
        this.userInfo.roleList.forEach(item => {
          roleNames.push(item.roleName)
        })
        text = roleNames.join(',')
      }
      return text
    }
  },
  created () {
    console.log(this.userInfo, '用户信息》》》》》》》》》》')
    if (this.userInfo.account != 'admin') {
      this.getbalance()
      withdraw().then(res => {
        console.log(res, '提现')
        this.isTx = res.data
      })
      pttxdetail({ roleType: 1 }).then(res => {
        console.log(res, '提现配置')
        this.txConfing = res.data
      })
    }
  },
  methods: {
    getbalance () {
      balance().then(res => {
        console.log(res, '余额')
        this.balanceInfo = res.data
      })
    },
    // 清理缓存
    clearCachePage () {
      location.reload()
    },
    /**
     * @desc 跳转页面
     * @param {  }
     * @version: 1.0.0
     */
    handleToPage (path) {
      if (path === '/caiwuguanli/chongzhizhongxin') {
        if (!auth.hasPermi('caiwuguanli:chongzhizhongxin:page')) {
          return
        }
      }
      if (path === '/yonghuguanli/dailishang/detail') {
        // this.$router.push({ path: path,
        //   query: {
        //     id: this.userInfo.userId,
        //     pageTab: 5
        //   } })
      } else if (path === '/account/center') {
        if (this.userInfo.roleCodes.includes('agent_admin')) {
          // 代理商
          this.$router.push({
            path: '/yonghuguanli/dailishang/detail',
            query: {
              id: this.userInfo.userId
            }
          })
        } else if (this.userInfo.roleCodes.includes('site_admin')) {
          // 场地管理员
          this.$router.push({
            path: '/yonghuguanli/changdifang/detail',
            query: {
              id: this.userInfo.userId
            }
          })
        } else if (this.userInfo.roleCodes.includes('site_user')) {
          // 场地维护人员
          this.$router.push({
            path: '/yonghuguanli/weihurenyuan/detail',
            query: {
              id: this.userInfo.userId
            }
          })
        } else {
          this.$router.push({ path: path })
        }
      } else {
        this.$router.push({ path: path })
      }
    },
    // 菜单显示状态改变时调用，参数为 visible
    visibleChangeHanle (visible) {
      console.log('菜单显示状态改变时调用，参数为 visible>>>>>', visible)
      if (visible) {
        this.$store.dispatch('GetInfo')
        if (this.userInfo.account != 'admin') {
          this.getbalance(); // 查询余额
        }
      }
    },
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    // 退出登录
    handleLogout (e) {
      Modal.confirm({
        title: this.$t('提示'),
        content: this.$t('确定注销并退出系统吗？'),
        onOk: () => {
          return this.$store.dispatch('Logout').then(() => {
            // 连接mqtt
            console.log('关闭mqtt连接>>>>>');
            EventBus.$emit('closeMqtt');
            location.href = '/index'
          })
        },
        onCancel () { }
      })
    },
    handleTx () {
      this.visible = true
      // addwithdrawal({ withdrawalMoney: this.balanceInfo.availableBal }).then(res => {
      //   this.$notification.success({
      //     message: '操作成功',
      //   })
      //   this.getbalance()
      // })
    },
    hideModal () {

    },
    handleOk () {
      if (this.password == '') {
        this.$notification.error({
          message: '请输入密码',
        })
        return
      }
      if (this.money <= 0) {
        this.$notification.error({
          message: '请输入提现金额',
        })
        return
      }
      if (this.money > this.balanceInfo.availableBal) {
        this.$notification.error({
          message: '提现金额不能大于可用余额',
        })
        return
      }

      addwithdrawal({ withdrawalMoney: this.money, password: this.password, withdrawalRole: 'AGENT' }).then(res => {
        if (res) {
          this.$notification.success({
            message: '操作成功',
          })
          this.visible = false
          this.getbalance()
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.money = 0
      this.password = ''
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}

.nickname {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 64px;
  margin-right: 16px;

  span {
    flex-shrink: 0;
    line-height: 14px;
  }
}

.user-info {
  width: 380px;
  //height: 588px;
  padding-bottom: 18px;
  margin-right: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 26px 1px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  .user-info-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 128px;
    background: #f6f9fd;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 30px 18px;
    opacity: 1;

    .account-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 93px;
      height: 32px;
      background: rgba(40, 120, 255, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1.5px solid #2878ff;
      cursor: pointer;
      span {
        color: #2878ff;
      }
    }
  }

  .user-info-money {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-top: 22px;
    padding-right: 16px;
    padding-bottom: 19px;
    margin-bottom: 6px;
    border-bottom: 1px solid #e2e2e2;

    .user-info-money-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;

      .user-info-money-box-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-info-money-box-item-price {
          margin-bottom: 10px;
          span {
            color: #2d2d2d;
          }
        }

        .user-info-money-box-item-name {
          color: #666666;
        }
      }
    }

    .user-info-money-btn {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 21px 0 27px;
      margin-top: 19px;

      .user-info-money-btn-item {
        width: 120px;
        height: 28px;
        background: #2878ff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 2px solid #2878ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
          color: #ffffff;
        }
      }
    }
  }

  .user-info-li {
    display: flex;
    width: 100%;
    padding: 16px 31px;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      margin-right: 18px;
    }
    span {
      color: #333;
    }
  }

  .user-info-out {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 316px;
    height: 46px;
    margin: 18px auto 0;
    background: #efefef;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    cursor: pointer;
    span {
      color: #999999;
    }
  }
}
</style>
