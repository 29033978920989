const pageTem = {
  "type": "page",
  "id": "u:520c7466a550",
  "asideResizor": false,
  "style": {},
  "pullRefresh": {
    "disabled": true
  },
  "regions": [
    "body"
  ],
  "body": [
    {
      "type": "crud",
      "id": "AlarmUser:table",
      "draggable": false,
      "api": {
        "method": "get",
        "url": "/system/user/page",
        "messages": {},
        "requestAdaptor": "",
        "adaptor": "",
        "dataType": "json"
      },
      "perPage": 10,
      "keepItemSelectionOnPageChange": false,
      "maxKeepItemSelectionLength": 11,
      "labelTpl": "${id}",
      "autoGenerateFilter": {
        "columnsNum": 5,
        "showBtnToolbar": false
      },
      "quickSaveApi": "",
      "quickSaveItemApi": "",
      "filterTogglable": true,
      "headerToolbar": [
        {
          "type": "button",
          "label": "更多",
          "tpl": "内容",
          "wrapperComponent": "",
          "id": "u:54c8eb2de1d7",
          "align": "left",
          "primary": true,
          "onEvent": {
            "click": {
              "weight": 0,
              "actions": [
                {
                  "ignoreError": false,
                  "actionType": "custom",
                  "script": "/* 自定义JS使用说明：\n  * 1.动作执行函数doAction，可以执行所有类型的动作\n  * 2.通过上下文对象context可以获取当前组件实例，例如context.props可以获取该组件相关属性\n  * 3.事件对象event，在doAction之后执行event.stopPropagation();可以阻止后续动作执行\n*/\nconst myMsg = '我是自定义JS';\n\nstoreId = context.props.store.id\nconsole.log(\"CRUD Store ID----》\", storeId)\nconsole.log(\"TABLE Store\", context.props.rootStore)\n\nObject.keys(context.props.rootStore.stores).forEach(key => {\n  if (context.props.rootStore.stores[key].parentId === storeId && context.props.rootStore.stores[key].storeType=== \"TableStore\")\n  {\n    context.props.rootStore.stores[key].toggleSearchFormExpanded();\n  }\n});\n"
                }
              ]
            }
          },
          "className": "zzcybtn zzcybtn-primary",
          "wrapperCustomStyle": {
            "root": {
              "background": "rgb(40, 120, 255) !important"
            }
          }
        },
        {
          "type": "button",
          "id": "AlarmUser:search",
          "label": "查询",
          "primary": true,
          "onEvent": {
            "click": {
              "weight": 0,
              "actions": [
                {
                  "ignoreError": false,
                  "actionType": "submit",
                  "componentId": "AlarmUser:table_SearchForm",
                  "outputVar": "submitResult"
                }
              ]
            }
          },
          "className": "zzcybtn zzcybtn-primary"
        },
        {
          "type": "button",
          "label": "重置",
          "id": "AlarmUser:reset",
          "onEvent": {
            "click": {
              "weight": 0,
              "actions": [
                {
                  "componentId": "AlarmUser:table_SearchForm",
                  "ignoreError": false,
                  "actionType": "reset"
                }
              ]
            }
          },
          "className": "zzcybtn zzcybtn-info"
        },
        {
          "type": "button",
          "label": "导出",
          "id": "AlarmUser:export",
          "onEvent": {
            "click": {
              "weight": 0,
              "actions": [
                {
                  "ignoreError": false,
                  "actionType": "download",
                  "api": {
                    "url": "/share-work/share-work/export",
                    "method": "post",
                    "requestAdaptor": "",
                    "adaptor": "",
                    "messages": {},
                    "data": {
                      "&": "$$"
                    },
                    "responseType": "blob"
                  }
                }
              ]
            }
          },
          "className": "zzcybtn zzcybtn-primary",
          "level": "primary"
        },
        {
          "type": "bulkActions"
        },
        {
          "type": "columns-toggler",
          "align": "right"
        },
        {
          "type": "drag-toggler",
          "align": "right"
        }
      ],
      "footerToolbar": [
        {
          "type": "statistics"
        },
        {
          "type": "pagination",
          "layout": "perPage,pager,go"
        }
      ],
      "bulkActions": [
        {
          "type": "button",
          "label": "批量删除",
          "id": "AlarmUser:delete",
          "className": "zzcybtn zzcybtn-info",
          "confirmText": "确定要批量删除数据?",
          "onEvent": {
            "click": {
              "weight": 0,
              "actions": [
                {
                  "ignoreError": false,
                  "outputVar": "responseResult",
                  "actionType": "ajax",
                  "options": {},
                  "api": {
                    "url": "/biz/app-feedback-type/delete",
                    "method": "post",
                    "requestAdaptor": "api.data=api.data.ids.split(',');\r\nreturn api;",
                    "adaptor": "",
                    "messages": {},
                    "data": {
                      "&": "$$"
                    }
                  }
                },
                {
                  "componentId": "u:916e3176e27b",
                  "ignoreError": false,
                  "actionType": "reload"
                },
                {
                  "componentId": "AlarmUser:table",
                  "ignoreError": false,
                  "actionType": "reload",
                  "args": {
                    "resetPage": true
                  }
                }
              ]
            }
          }
        }
      ],
      "columns": [
        {
          "type": "text",
          "name": "typeName",
          "label": "类型名称",
          "id": "u:230d6326ee0d",
          "searchable": {
            "clearable": "true",
            "id": "u:92a11d8e297d",
            "name": "typeName",
            "type": "input-text",
            "placeholder": "请输入",
            "mode": "normal"
          }
        },
        {
          "label": "设备形态",
          "type": "mapping",
          "name": "userName",
          "id": "u:109d0b634464",
          "searchable": {
            "clearable": "true",
            "id": "u:d3d2f6b2faa6",
            "name": "userName",
            "type": "select",
            "options": [
              {
                "label": "直连",
                "value": 0
              },
              {
                "label": "分体柜机",
                "value": 1
              },
              {
                "label": "分体格口",
                "value": 2
              }
            ],
            "multiple": false,
            "mode": "normal"
          },
          "map": {
            "0": "直连",
            "1": "分体柜机",
            "2": "分体格口",
            "*": "直连"
          }
        },
        {
          "label": "通讯类型",
          "type": "mapping",
          "name": "nickName",
          "id": "u:d03c284f5aa5",
          "map": {
            "0": "<span class='label label-info'>蓝牙</span>",
            "1": "蓝牙+wifi",
            "2": "4g/nb/网线",
            "*": "<span class='label label-info'>蓝牙</span>"
          },
          "searchable": {
            "clearable": "true",
            "name": "nickName",
            "type": "select",
            "options": [
              {
                "value": "0",
                "label": "蓝牙"
              },
              {
                "value": "1",
                "label": "蓝牙+wifi"
              },
              {
                "value": "2",
                "label": "4g/nb/网线"
              }
            ],
            "multiple": false,
            "mode": "normal",
            "id": "u:98a2eaf271f0"
          }
        },
        {
          "label": "设备型号",
          "type": "text",
          "name": "email",
          "id": "u:768d9c6a2787",
          "searchable": {
            "clearable": "true",
            "id": "u:b259c9095e72",
            "name": "email",
            "type": "input-text",
            "placeholder": "请输入",
            "mode": "normal"
          }
        },
        {
          "type": "datetime",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value": 1707034934,
          "name": "createTime",
          "label": "创建时间",
          "id": "u:c6a060f6e882",
          "searchable": {
            "clearable": "true",
            "name": "startTime",
            "extraName": "endTime",
            "type": "input-date-range",
            "mode": "normal",
            "label": "日期范围",
            "id": "u:14d70560889f"
          }
        },
        {
          "type": "operation",
          "label": "操作",
          "fixed": "right",
          "width": 143,
          "buttons": [
            {
              "type": "button",
              "label": "详情",
              "onEvent": {
                "click": {
                  "actions": [
                    {
                      "ignoreError": false,
                      "actionType": "drawer",
                      "args": {
                        "fromCurrentDialog": true
                      },
                      "drawer": {
                        "$ref": "drawer-ref-3"
                      }
                    }
                  ]
                }
              },
              "id": "u:bb25c7f9804c",
              "className": "zzcybtn zzcybtn-primary",
              "level": "primary",
              "size": "md"
            },
            {
              "type": "button",
              "label": "删除",
              "onEvent": {
                "click": {
                  "actions": [
                    {
                      "ignoreError": false,
                      "outputVar": "responseResult",
                      "actionType": "ajax",
                      "options": {},
                      "api": {
                        "url": "/gather/device/alarm/delete",
                        "method": "get"
                      }
                    }
                  ]
                }
              },
              "id": "u:0309782d7b31",
              "level": "danger",
              "size": "md",
              "className": "zzcybtn zzcybtn-danger",
              "confirmText": "数据删除后无法恢复，请确认!"
            }
          ],
          "id": "u:f93c43d1a19b"
        }
      ],
      "messages": {},
      "filterSettingSource": [
        "id",
        "deptId",
        "userName",
        "nickName",
        "userType",
        "email",
        "phoneNumber",
        "sex",
        "avatar",
        "password",
        "loginIp",
        "loginDate",
        "status",
        "createTime",
        "remark",
        "dept",
        "roles"
      ],
      "features": [
        "filter"
      ],
      "itemActions": [],
      "onEvent": {},
      "footable": false,
      "alwaysShowPagination": true,
      "name": "",
      "autoFillHeight": true,
      "bodyClassName": "zzcy-table",
      "className": "zzcy-crud",
      "perPageAvailable": [
        10,
        20,
        50,
        100
      ],
      "perPageField": "pageSize",
      "pageField": "pageNum",
      "syncLocation": false
    }
  ],
  "data": {},
  "themeCss": {
    "baseControlClassName": {
      "boxShadow:default": " 0px 0px 0px 0px transparent"
    }
  }
}

export {
  pageTem
}
