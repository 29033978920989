/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-xuanxiangka';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '详情基础样式';
  order = 0; // 排序
  description = '详情基础样式';
  tags = ['展示', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    "type": "tabs",
    "id": "u:4f5781e87ade",
    "className": "zzcy-tabs",
    "tabs": [
      {
        "title": "基本信息",
        "body": [
          {
            "type": "service",
            "id": "u:71dac5814fbf",
            "body": [
              {
                "type": "card",
                "id": "u:dc2390ae0fb5",
                "body": [
                  {
                    "type": "flex",
                    "style": {
                      "position": "relative",
                      "flexWrap": "nowrap",
                      "inset": "auto",
                      "flexDirection": "column"
                    },
                    "isFixedHeight": false,
                    "id": "u:8a5d102f41a0",
                    "items": [
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "divider",
                            "id": "u:b6524a306132",
                            "lineStyle": "solid",
                            "direction": "vertical",
                            "rotate": 0,
                            "style": {
                              "borderWidth": "3px",
                              "height": "12px",
                              "borderRadius": "2px 2px 2px 2px",
                              "marginRight": "8px",
                              "marginTop": "0px",
                              "marginBottom": "0px"
                            },
                            "color": "#2878ff"
                          },
                          {
                            "type": "tpl",
                            "tpl": "基本信息",
                            "inline": true,
                            "wrapperComponent": "",
                            "id": "u:1016439510ba",
                            "themeCss": {
                              "baseControlClassName": {
                                "font:default": {
                                  "fontSize": "16px",
                                  "color": "#333333",
                                  "lineHeight": "1",
                                  "fontWeight": "700",
                                  "font-family": "思源-Bold"
                                }
                              }
                            }
                          }
                        ],
                        "size": "none",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap",
                          "alignItems": "center"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:e90383418e6b"
                      },
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "flex",
                            "items": [
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺名称",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:b32f8a2e66de"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${name}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:f2b794d14b2b",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:cc7a3e4971a9"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺负责人名称",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:32939299079f"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${shopUserName}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:f0297a3173e1",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:00259b50a435"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺负责人手机号",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:5d746dae8aa4"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${shopUserMobile}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:b074fb58ae62",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:379dc82e0338"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "值守状态",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:d123d9ca039c"
                                  },
                                  {
                                    "type": "mapping",
                                    "map": {
                                      "0": "值守中",
                                      "1": "营业中",
                                      "2": "未值守",
                                      "*": "--"
                                    },
                                    "itemSchema": {
                                      "type": "tag",
                                      "label": "${item}",
                                      "id": "u:e436a07e72a9"
                                    },
                                    "id": "u:a3c80f81233d",
                                    "name": "status"
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:243cbc589a7f"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺图片",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:102c8b37f74f"
                                  },
                                  {
                                    "type": "images",
                                    "imageGallaryClassName": "app-popover :AMISCSSWrapper",
                                    "name": "imgs",
                                    "id": "u:e1c5e1d3d42b",
                                    "thumbMode": "contain",
                                    "wrapperCustomStyle": {
                                      ".antd-Image-thumb": {
                                        "width": "50px",
                                        "height": "50px"
                                      }
                                    },
                                    "enlargeAble": true
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "flex",
                                  "flex": "0 0 auto",
                                  "flexWrap": "nowrap",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:051f7c994946"
                              }
                            ],
                            "style": {
                              "position": "relative",
                              "rowGap": "10px",
                              "columnGap": "10px",
                              "flex": "1 1 auto",
                              "flexGrow": 1,
                              "flexWrap": "wrap",
                              "inset": "auto",
                              "justifyContent": "flex-start"
                            },
                            "id": "u:897689e47e86",
                            "isFixedHeight": false
                          }
                        ],
                        "size": "xs",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:dc3e1d37695c",
                        "themeCss": {
                          "baseControlClassName": {
                            "padding-and-margin:default": {
                              "marginTop": "10px"
                            }
                          }
                        }
                      }
                    ],
                    "label": "",
                    "isFixedWidth": false,
                    "themeCss": {
                      "baseControlClassName": {
                        "padding-and-margin:default": {}
                      }
                    }
                  }
                ],
                "header": {
                  "title": "",
                  "subTitle": ""
                },
                "actions": [],
                "bodyClassName": "",
                "className": "zzcy-card m-none"
              },
              {
                "type": "card",
                "id": "u:b1f1eba42c6b",
                "body": [
                  {
                    "type": "flex",
                    "style": {
                      "position": "relative",
                      "flexWrap": "nowrap",
                      "inset": "auto",
                      "flexDirection": "column"
                    },
                    "isFixedHeight": false,
                    "id": "u:178cb8db8fdc",
                    "items": [
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "divider",
                            "id": "u:9c157629e76c",
                            "lineStyle": "solid",
                            "direction": "vertical",
                            "rotate": 0,
                            "style": {
                              "borderWidth": "3px",
                              "height": "12px",
                              "borderRadius": "2px 2px 2px 2px",
                              "marginRight": "8px",
                              "marginTop": "0px",
                              "marginBottom": "0px"
                            },
                            "color": "#2878ff"
                          },
                          {
                            "type": "tpl",
                            "tpl": "位置信息",
                            "inline": true,
                            "wrapperComponent": "",
                            "id": "u:c6ff3f640175",
                            "themeCss": {
                              "baseControlClassName": {
                                "font:default": {
                                  "fontSize": "16px",
                                  "color": "#333333",
                                  "lineHeight": "1",
                                  "fontWeight": "700",
                                  "font-family": "思源-Bold"
                                }
                              }
                            }
                          }
                        ],
                        "size": "none",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap",
                          "alignItems": "center"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:69c7a0a2d865"
                      },
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "flex",
                            "items": [
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺地址",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:a45a16424b46"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${address}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:10140cdaf0bf",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:54b285662e48"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "经度",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:43acf9f1fa1b"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${longitude}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:26c7ed31c011",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:62fd3821b58a"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "纬度",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:044eceafd9e6"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${latitude}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:446cbadc91af",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:2a02fa4f3876"
                              }
                            ],
                            "style": {
                              "position": "relative",
                              "rowGap": "10px",
                              "columnGap": "10px",
                              "flex": "1 1 auto",
                              "flexGrow": 1,
                              "flexWrap": "wrap",
                              "inset": "auto",
                              "justifyContent": "flex-start"
                            },
                            "id": "u:645a9f6106d9",
                            "isFixedHeight": false
                          }
                        ],
                        "size": "xs",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:f2bd97388275",
                        "themeCss": {
                          "baseControlClassName": {
                            "padding-and-margin:default": {
                              "marginTop": "10px"
                            }
                          }
                        }
                      }
                    ],
                    "label": "",
                    "isFixedWidth": false,
                    "themeCss": {
                      "baseControlClassName": {
                        "padding-and-margin:default": {}
                      }
                    }
                  }
                ],
                "header": {
                  "title": "",
                  "subTitle": ""
                },
                "actions": [],
                "bodyClassName": "",
                "className": "zzcy-card m-none"
              },
              {
                "type": "card",
                "id": "u:efe2141d370c",
                "body": [
                  {
                    "type": "flex",
                    "style": {
                      "position": "relative",
                      "flexWrap": "nowrap",
                      "inset": "auto",
                      "flexDirection": "column"
                    },
                    "isFixedHeight": false,
                    "id": "u:4c4e0df53683",
                    "items": [
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "divider",
                            "id": "u:ca95ad57211a",
                            "lineStyle": "solid",
                            "direction": "vertical",
                            "rotate": 0,
                            "style": {
                              "borderWidth": "3px",
                              "height": "12px",
                              "borderRadius": "2px 2px 2px 2px",
                              "marginRight": "8px",
                              "marginTop": "0px",
                              "marginBottom": "0px"
                            },
                            "color": "#2878ff"
                          },
                          {
                            "type": "tpl",
                            "tpl": "服务费",
                            "inline": true,
                            "wrapperComponent": "",
                            "id": "u:c31b23df93fe",
                            "themeCss": {
                              "baseControlClassName": {
                                "font:default": {
                                  "fontSize": "16px",
                                  "color": "#333333",
                                  "lineHeight": "1",
                                  "fontWeight": "700",
                                  "font-family": "思源-Bold"
                                }
                              }
                            }
                          }
                        ],
                        "size": "none",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap",
                          "alignItems": "center"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:bb34122e58b6"
                      },
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "flex",
                            "items": [
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "服务费比例",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:12daf311af98"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${bl}%",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:f1316485cc61",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:8a72118e9f92"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "服务费金额",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:95278890dac8"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${serviceMoney}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:5b3e05d74650",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:3be0909001ca"
                              }
                            ],
                            "style": {
                              "position": "relative",
                              "rowGap": "10px",
                              "columnGap": "10px",
                              "flex": "1 1 auto",
                              "flexGrow": 1,
                              "flexWrap": "wrap",
                              "inset": "auto",
                              "justifyContent": "flex-start"
                            },
                            "id": "u:551d563d0215",
                            "isFixedHeight": false
                          }
                        ],
                        "size": "xs",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:8265189ac0d9",
                        "themeCss": {
                          "baseControlClassName": {
                            "padding-and-margin:default": {
                              "marginTop": "10px"
                            }
                          }
                        }
                      }
                    ],
                    "label": "",
                    "isFixedWidth": false,
                    "themeCss": {
                      "baseControlClassName": {
                        "padding-and-margin:default": {}
                      }
                    }
                  }
                ],
                "header": {
                  "title": "",
                  "subTitle": ""
                },
                "actions": [],
                "bodyClassName": "",
                "className": "zzcy-card m-none"
              }
            ],
            "dsType": "api",
            "api": {
              "url": "/store/store-shop/detail",
              "method": "get",
              "requestAdaptor": "",
              "adaptor": "\r\nreturn response;",
              "messages": {},
              "data": {
                "id": "${shopId}",
                "&": "$$"
              },
              "dataType": "json",
              "silent": true
            },
            "className": "m-none p-none"
          }
        ],
        "id": "u:f0fbc71bc0dd",
        "className": "no-border p-none m-none"
      }
    ],
    "activeKey": 0,
    "defaultKey": 0,
    "tabsMode": "radio",
    "contentClassName": "no-border p-none zzcyi-tab-content m-none",
    "toolbar": [
      {
        "type": "button",
        "id": "u:78b65f8f3386",
        "label": "返回",
        "onEvent": {
          "click": {
            "actions": [
              {
                "ignoreError": false,
                "actionType": "goBack"
              }
            ]
          }
        },
        "level": "primary"
      }
    ],
    "onEvent": {
      "change": {
        "weight": 0,
        "actions": []
      }
    },
    "unmountOnExit": true,
    "mountOnEnter": true,
    "linksClassName": ""
  };
  previewSchema = { // 组件面板预览时需要
    "type": "tabs",
    "id": "u:4f5781e87ade",
    "className": "zzcy-tabs",
    "tabs": [
      {
        "title": "基本信息",
        "body": [
          {
            "type": "service",
            "id": "u:71dac5814fbf",
            "body": [
              {
                "type": "card",
                "id": "u:dc2390ae0fb5",
                "body": [
                  {
                    "type": "flex",
                    "style": {
                      "position": "relative",
                      "flexWrap": "nowrap",
                      "inset": "auto",
                      "flexDirection": "column"
                    },
                    "isFixedHeight": false,
                    "id": "u:8a5d102f41a0",
                    "items": [
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "divider",
                            "id": "u:b6524a306132",
                            "lineStyle": "solid",
                            "direction": "vertical",
                            "rotate": 0,
                            "style": {
                              "borderWidth": "3px",
                              "height": "12px",
                              "borderRadius": "2px 2px 2px 2px",
                              "marginRight": "8px",
                              "marginTop": "0px",
                              "marginBottom": "0px"
                            },
                            "color": "#2878ff"
                          },
                          {
                            "type": "tpl",
                            "tpl": "基本信息",
                            "inline": true,
                            "wrapperComponent": "",
                            "id": "u:1016439510ba",
                            "themeCss": {
                              "baseControlClassName": {
                                "font:default": {
                                  "fontSize": "16px",
                                  "color": "#333333",
                                  "lineHeight": "1",
                                  "fontWeight": "700",
                                  "font-family": "思源-Bold"
                                }
                              }
                            }
                          }
                        ],
                        "size": "none",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap",
                          "alignItems": "center"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:e90383418e6b"
                      },
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "flex",
                            "items": [
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺名称",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:b32f8a2e66de"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${name}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:f2b794d14b2b",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:cc7a3e4971a9"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺负责人名称",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:32939299079f"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${shopUserName}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:f0297a3173e1",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:00259b50a435"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺负责人手机号",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:5d746dae8aa4"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${shopUserMobile}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:b074fb58ae62",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:379dc82e0338"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "值守状态",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:d123d9ca039c"
                                  },
                                  {
                                    "type": "mapping",
                                    "map": {
                                      "0": "值守中",
                                      "1": "营业中",
                                      "2": "未值守",
                                      "*": "--"
                                    },
                                    "itemSchema": {
                                      "type": "tag",
                                      "label": "${item}",
                                      "id": "u:e436a07e72a9"
                                    },
                                    "id": "u:a3c80f81233d",
                                    "name": "status"
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:243cbc589a7f"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺图片",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:102c8b37f74f"
                                  },
                                  {
                                    "type": "images",
                                    "imageGallaryClassName": "app-popover :AMISCSSWrapper",
                                    "name": "imgs",
                                    "id": "u:e1c5e1d3d42b",
                                    "thumbMode": "contain",
                                    "wrapperCustomStyle": {
                                      ".antd-Image-thumb": {
                                        "width": "50px",
                                        "height": "50px"
                                      }
                                    },
                                    "enlargeAble": true
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "flex",
                                  "flex": "0 0 auto",
                                  "flexWrap": "nowrap",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:051f7c994946"
                              }
                            ],
                            "style": {
                              "position": "relative",
                              "rowGap": "10px",
                              "columnGap": "10px",
                              "flex": "1 1 auto",
                              "flexGrow": 1,
                              "flexWrap": "wrap",
                              "inset": "auto",
                              "justifyContent": "flex-start"
                            },
                            "id": "u:897689e47e86",
                            "isFixedHeight": false
                          }
                        ],
                        "size": "xs",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:dc3e1d37695c",
                        "themeCss": {
                          "baseControlClassName": {
                            "padding-and-margin:default": {
                              "marginTop": "10px"
                            }
                          }
                        }
                      }
                    ],
                    "label": "",
                    "isFixedWidth": false,
                    "themeCss": {
                      "baseControlClassName": {
                        "padding-and-margin:default": {}
                      }
                    }
                  }
                ],
                "header": {
                  "title": "",
                  "subTitle": ""
                },
                "actions": [],
                "bodyClassName": "",
                "className": "zzcy-card m-none"
              },
              {
                "type": "card",
                "id": "u:b1f1eba42c6b",
                "body": [
                  {
                    "type": "flex",
                    "style": {
                      "position": "relative",
                      "flexWrap": "nowrap",
                      "inset": "auto",
                      "flexDirection": "column"
                    },
                    "isFixedHeight": false,
                    "id": "u:178cb8db8fdc",
                    "items": [
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "divider",
                            "id": "u:9c157629e76c",
                            "lineStyle": "solid",
                            "direction": "vertical",
                            "rotate": 0,
                            "style": {
                              "borderWidth": "3px",
                              "height": "12px",
                              "borderRadius": "2px 2px 2px 2px",
                              "marginRight": "8px",
                              "marginTop": "0px",
                              "marginBottom": "0px"
                            },
                            "color": "#2878ff"
                          },
                          {
                            "type": "tpl",
                            "tpl": "位置信息",
                            "inline": true,
                            "wrapperComponent": "",
                            "id": "u:c6ff3f640175",
                            "themeCss": {
                              "baseControlClassName": {
                                "font:default": {
                                  "fontSize": "16px",
                                  "color": "#333333",
                                  "lineHeight": "1",
                                  "fontWeight": "700",
                                  "font-family": "思源-Bold"
                                }
                              }
                            }
                          }
                        ],
                        "size": "none",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap",
                          "alignItems": "center"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:69c7a0a2d865"
                      },
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "flex",
                            "items": [
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "店铺地址",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:a45a16424b46"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${address}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:10140cdaf0bf",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:54b285662e48"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "经度",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:43acf9f1fa1b"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${longitude}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:26c7ed31c011",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:62fd3821b58a"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "纬度",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:044eceafd9e6"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${latitude}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:446cbadc91af",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:2a02fa4f3876"
                              }
                            ],
                            "style": {
                              "position": "relative",
                              "rowGap": "10px",
                              "columnGap": "10px",
                              "flex": "1 1 auto",
                              "flexGrow": 1,
                              "flexWrap": "wrap",
                              "inset": "auto",
                              "justifyContent": "flex-start"
                            },
                            "id": "u:645a9f6106d9",
                            "isFixedHeight": false
                          }
                        ],
                        "size": "xs",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:f2bd97388275",
                        "themeCss": {
                          "baseControlClassName": {
                            "padding-and-margin:default": {
                              "marginTop": "10px"
                            }
                          }
                        }
                      }
                    ],
                    "label": "",
                    "isFixedWidth": false,
                    "themeCss": {
                      "baseControlClassName": {
                        "padding-and-margin:default": {}
                      }
                    }
                  }
                ],
                "header": {
                  "title": "",
                  "subTitle": ""
                },
                "actions": [],
                "bodyClassName": "",
                "className": "zzcy-card m-none"
              },
              {
                "type": "card",
                "id": "u:efe2141d370c",
                "body": [
                  {
                    "type": "flex",
                    "style": {
                      "position": "relative",
                      "flexWrap": "nowrap",
                      "inset": "auto",
                      "flexDirection": "column"
                    },
                    "isFixedHeight": false,
                    "id": "u:4c4e0df53683",
                    "items": [
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "divider",
                            "id": "u:ca95ad57211a",
                            "lineStyle": "solid",
                            "direction": "vertical",
                            "rotate": 0,
                            "style": {
                              "borderWidth": "3px",
                              "height": "12px",
                              "borderRadius": "2px 2px 2px 2px",
                              "marginRight": "8px",
                              "marginTop": "0px",
                              "marginBottom": "0px"
                            },
                            "color": "#2878ff"
                          },
                          {
                            "type": "tpl",
                            "tpl": "服务费",
                            "inline": true,
                            "wrapperComponent": "",
                            "id": "u:c31b23df93fe",
                            "themeCss": {
                              "baseControlClassName": {
                                "font:default": {
                                  "fontSize": "16px",
                                  "color": "#333333",
                                  "lineHeight": "1",
                                  "fontWeight": "700",
                                  "font-family": "思源-Bold"
                                }
                              }
                            }
                          }
                        ],
                        "size": "none",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap",
                          "alignItems": "center"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:bb34122e58b6"
                      },
                      {
                        "type": "container",
                        "body": [
                          {
                            "type": "flex",
                            "items": [
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "服务费比例",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:12daf311af98"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${bl}%",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:f1316485cc61",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:8a72118e9f92"
                              },
                              {
                                "type": "container",
                                "body": [
                                  {
                                    "type": "tpl",
                                    "tpl": "服务费金额",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#666666"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {
                                      "root": {
                                        "width": "80px"
                                      }
                                    },
                                    "id": "u:95278890dac8"
                                  },
                                  {
                                    "type": "tpl",
                                    "tpl": "${serviceMoney}",
                                    "inline": true,
                                    "wrapperComponent": "",
                                    "id": "u:5b3e05d74650",
                                    "themeCss": {
                                      "baseControlClassName": {
                                        "font:default": {
                                          "lineHeight": "1",
                                          "font-family": "思源-Regular",
                                          "color": "#333333"
                                        },
                                        "padding-and-margin:default": {
                                          "marginRight": "18px"
                                        }
                                      }
                                    },
                                    "wrapperCustomStyle": {}
                                  }
                                ],
                                "size": "none",
                                "style": {
                                  "position": "static",
                                  "display": "block",
                                  "flex": "0 0 auto",
                                  "minWidth": "250px"
                                },
                                "wrapperBody": false,
                                "isFixedHeight": false,
                                "isFixedWidth": false,
                                "id": "u:3be0909001ca"
                              }
                            ],
                            "style": {
                              "position": "relative",
                              "rowGap": "10px",
                              "columnGap": "10px",
                              "flex": "1 1 auto",
                              "flexGrow": 1,
                              "flexWrap": "wrap",
                              "inset": "auto",
                              "justifyContent": "flex-start"
                            },
                            "id": "u:551d563d0215",
                            "isFixedHeight": false
                          }
                        ],
                        "size": "xs",
                        "style": {
                          "position": "static",
                          "display": "flex",
                          "flex": "1 1 auto",
                          "flexGrow": 1,
                          "flexWrap": "nowrap"
                        },
                        "wrapperBody": false,
                        "isFixedHeight": false,
                        "isFixedWidth": false,
                        "id": "u:8265189ac0d9",
                        "themeCss": {
                          "baseControlClassName": {
                            "padding-and-margin:default": {
                              "marginTop": "10px"
                            }
                          }
                        }
                      }
                    ],
                    "label": "",
                    "isFixedWidth": false,
                    "themeCss": {
                      "baseControlClassName": {
                        "padding-and-margin:default": {}
                      }
                    }
                  }
                ],
                "header": {
                  "title": "",
                  "subTitle": ""
                },
                "actions": [],
                "bodyClassName": "",
                "className": "zzcy-card m-none"
              }
            ],
            "dsType": "api",
            "api": {
              "url": "/store/store-shop/detail",
              "method": "get",
              "requestAdaptor": "",
              "adaptor": "\r\nreturn response;",
              "messages": {},
              "data": {
                "id": "${shopId}",
                "&": "$$"
              },
              "dataType": "json",
              "silent": true
            },
            "className": "m-none p-none"
          }
        ],
        "id": "u:f0fbc71bc0dd",
        "className": "no-border p-none m-none"
      }
    ],
    "activeKey": 0,
    "defaultKey": 0,
    "tabsMode": "radio",
    "contentClassName": "no-border p-none zzcyi-tab-content m-none",
    "toolbar": [
      {
        "type": "button",
        "id": "u:78b65f8f3386",
        "label": "返回",
        "onEvent": {
          "click": {
            "actions": [
              {
                "ignoreError": false,
                "actionType": "goBack"
              }
            ]
          }
        },
        "level": "primary"
      }
    ],
    "onEvent": {
      "change": {
        "weight": 0,
        "actions": []
      }
    },
    "unmountOnExit": true,
    "mountOnEnter": true,
    "linksClassName": ""
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
