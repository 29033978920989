<template>
  <page-header-wrapper>
    <AMisRendererDiv
      v-if="schema"
      :schema="schema"
    />
  </page-header-wrapper>
  <!-- <AMisRendererDiv v-if="schema" :schema="schema" /> -->
</template>

<script>
import AMisRendererDiv from '@/components/amis/AMisRenderer.vue'

import { baseMixin } from '@/store/app-mixin'
import { getMenuContent } from '@/api/menu'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import { mapGetters } from 'vuex'
// import { itemProps } from 'ant-design-vue/es/vc-menu'
import defaultSettings from '@/config/defaultSettings'

export default {
  name: 'AMisRenderer',
  components: {
    AMisRendererDiv
  },
  mixins: [baseMixin],
  data () {
    return {
      // horizontal  inline
      mode: 'inline',
      currentKey: ['base'],
      schema: null,
      token: encodeURIComponent('Bearer ' + storage.get(ACCESS_TOKEN))

    }
  },
  computed: {
    ...mapGetters(['permissions', 'userInfo'])
  },
  mounted () {
  },
  beforeCreate () {

  },
  beforeRouteLeave (to, from, next) {
    console.log('>>>>>>>>>>>>>>>>>>>>');
    if (window.player) {
      window.player.stop()
    }
    next()
  },
  beforeRouteEnter (to, from, next) {
    console.log('enter>>>>>>>>>>>>>>>>>>>>');
    if (window.player) {
      window.player.play()
    }
    next()
  },
  created () {
    console.log(this.$route)
    if (this.$route.meta.menuId) {
      this.getMenuContent(this.$route.meta.menuId)
    }
  },
  methods: {
    // 查询菜单内容
    getMenuContent (menuId) {
      getMenuContent({ menuId }).then(async response => {
        // console.log('查询菜单内容>>>>', response)
        // console.log('当前amis菜单内容>>>>permissions>>>>', this.permissions)
        let schemaStr = response.data
        if (response.data.indexOf('info-component') !== -1) {
          // console.log('当前amis菜单内容>>>>data>>>>', response.data.indexOf('info-component'))
          const schemaArr = response.data.split('info-component')
          // console.log('当前amis菜单内容>>>>data>>>>', schemaArr)
          const schemaArrCopy = []
          for (let index = 0; index < schemaArr.length; index++) {
            const item = schemaArr[index]
            if (item.indexOf('info_com_menuId') !== -1) {
              // 获取菜单id
              const regMenuId = /"info_com_menuId":"(.*?)",/gi
              const infoComMenuId = regMenuId.exec(item)[1]

              // // 获取传参数组
              // const regComData = /"comData":(.*?)}],/gi
              // const comData = JSON.parse(regComData.exec(item)[1])
              // console.log('comData>>>>', comData)

              const childrenResponse = await getMenuContent({ menuId: infoComMenuId })
              // console.log('childrenPage>>>>', childrenResponse)
              const childrenPage = childrenResponse.data ? JSON.parse(childrenResponse.data) : {}

              if (!childrenPage['data']) {
                childrenPage['data'] = {
                  isChild: true // 设置是否是作为子页面在其他页面显示
                }

                // if (comData.length > 0) {
                //   comData.forEach(itemKye => {
                //     childrenPage.data[itemKye.comKey] = '${' + itemKye.comValue + '}'
                //   })
                // }
              } else {
                childrenPage.data['isChild'] = true // 设置是否是作为子页面在其他页面显示
                // if (comData.length > 0) {
                //   comData.forEach(itemKye => {
                //     childrenPage.data[itemKye.comKey] = '${' + itemKye.comValue + '}'
                //   })
                // }
              }
              // console.log('item>>>>>1>>>>>', item)

              const itemCopy = item.replace(']}', ']},' + JSON.stringify(childrenPage))
              // console.log('item>>>>>', itemCopy)
              schemaArrCopy.push(itemCopy)
            } else {
              schemaArrCopy.push(item)
            }
          }
          schemaStr = schemaArrCopy.join('info-component')
        } else {

        }
        // const schema = response.data ? JSON.parse(response.data) : {}
        const schema = schemaStr ? JSON.parse(schemaStr) : {}
        if (!schema['data']) {
          schema['data'] = {
            permissions: this.permissions,
            token: storage.get(ACCESS_TOKEN),
            locationUrl: window.location.origin,
            baseURL: process.env.VUE_APP_BASE_API,
            loginUserId: this.userInfo.userId,
            sysTenantName: this.userInfo?.tenantInfo?.tenantName||'',
            sysTenantId: this.userInfo?.tenantInfo?.tenantId||null,
            sysTenantUrl: this.userInfo?.tenantInfo?.sysTenantUrl||'',
            sysIsNetWork: defaultSettings?.isNetWork||false
          }
        } else {
          schema.data['permissions'] = this.permissions

          schema.data['token'] = storage.get(ACCESS_TOKEN)

          schema.data['locationUrl'] = window.location.origin

          schema.data['baseURL'] = process.env.VUE_APP_BASE_API
          schema.data['loginUserId'] = this.userInfo.userId
          schema.data['sysTenantName'] = this.userInfo?.tenantInfo?.tenantName||''
          schema.data['sysTenantId'] = this.userInfo?.tenantInfo?.tenantId||null
          schema.data['sysTenantUrl'] = this.userInfo?.tenantInfo?.tenantUrl||''
          schema.data['sysIsNetWork'] = defaultSettings?.isNetWork||false
        }
        // console.log('页面JSON内容>>>>', schema)

        this.schema = schema
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
.account-settings-info-main {
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;

  &.mobile {
    display: block;

    .account-settings-info-left {
      border-right: unset;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
      height: 50px;
      overflow-x: auto;
      overflow-y: scroll;
    }

    .account-settings-info-right {
      padding: 20px 40px;
    }
  }

  .account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width: 224px;
  }

  .account-settings-info-right {
    flex: 1 1;
    padding: 8px 40px;

    .account-settings-info-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 12px;
    }

    .account-settings-info-view {
      padding-top: 12px;
    }
  }
}
</style>
